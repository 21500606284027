import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Logo from '../../../../images/logo.svg';
import { MenuPopupsEnum } from '../../../../_enums/menuPopupsEnum';
import { FooterProps } from '../@types/footer.interface';

const FooterWrapper = styled.div`
	display: none;
	@media(min-width: 1000px) {
		position: fixed;
		bottom: 0;
		left: 0;
		-webkit-tap-highlight-color: transparent;  
		width: 100%;
		height: 50px;
		z-index: 100;
		background-color: ${({ theme }) => theme.colors.greyBackground};
		padding: 0 20px;
		justify-content: space-between;
		display: flex;
		flex-direction: row;
	}
`;
const FooterLogo = styled.img`
	height: 40px;
	margin: auto 0;
	width: 75px;
`;

const FooterButtonWrapper = styled.div`
	margin: auto 0;
`;

const FooterButton = styled.button`
	background-color: ${({ theme }) => theme.colors.greyBackground};
	border: none;
	color: ${({ theme }) => theme.colors.fontBlue};
	cursor: pointer;
	font-size: 12px;
	margin: auto 0;
	margin-left: 20px;
	outline:none;
`;

const SubFooter :FunctionComponent<FooterProps> = ({ chosePopup }) => (
	<FooterWrapper>
		<FooterLogo src={Logo} />
		<FooterButtonWrapper>
			<FooterButton onClick={() => chosePopup(MenuPopupsEnum.CONTACT)}>Kontakt</FooterButton>
			<FooterButton onClick={() => chosePopup(MenuPopupsEnum.FAQ)}>FAQ</FooterButton>
			<FooterButton onClick={() => chosePopup(MenuPopupsEnum.TERM)}>Regulamin</FooterButton>
		</FooterButtonWrapper>
	</FooterWrapper>
);
export default SubFooter;
