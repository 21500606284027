import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ScoreboardQuestionProps } from '../@types/scoreboardQuestion.interface';
import ScoreboardScoreBar from './scoreboardScoreBar';
import ScoreboardAnswer from './ScoreboardAnswer';

const Wrapper = styled.div`
	margin-bottom: 27px;
	@media(min-width: 700px) {
		margin-bottom: 60px;
	}
`;

const ScoreboardQuestion: FunctionComponent<ScoreboardQuestionProps> = ({ question, index }) => {
	const [biggestAmount, setBiggestAmount] = useState<number>();
	const [totalAnswers, setTotalAnswers] = useState<number>();

	useEffect(() => {
		setBiggestAmount(Math.max(...question.answers.map((answer) => answer.points)));
		setTotalAnswers(question.answers.reduce((previousValue, currentValue) => previousValue + currentValue.points, 0));
	}, []);

	return (
		<Wrapper>
			<ScoreboardScoreBar
				barNumber={index + 1}
				barText={question.text}
				isPoint={question.type === 'points'}
			/>
			{question.answers.map((answer) => (
				<ScoreboardAnswer
					key={answer.id}
					answer={answer.text}
					answersCount={answer.points}
					isMost={biggestAmount === answer.points}
					totalAnswers={totalAnswers}
				/>
			))}
		</Wrapper>
	);
};

export default ScoreboardQuestion;
