import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import VotingCounters from '../voting-counters/VotingCounters';
import { GlobalState } from '../../../state/@types/redux.interface';
import { VotingHeaderProps } from './@types/VotingHeader.interface';

const OverDetailsWrapper = styled.div`
	align-items: center;
  	background-color: ${({ theme }) => theme.colors.white};
  	display: flex;
  	justify-content: center;
  	margin-bottom: 30px;
  	padding: 10px;
  	position: sticky;
  	top: 84px;
  	width: 100%;
`;
const DetailsWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.blueBackground};
	border-radius: 20px;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 0.75rem;
	justify-content: space-between;
	line-height: 1.2rem;
	margin: 0 auto 0 auto;
	padding: 15px 24px;
	width: 100%;
	z-index: 100;
`;

const StyledLabelText = styled.span`
	align-items: center;
	display: flex;
	z-index: 1;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	
	@media(min-width: 700px) {
		flex-direction: row;
  	}
`;

const LeftText = styled.p`
	font-weight: bold;
  	@media(min-width: 700px) {
    	margin-left: 20px;
  	}
`;

const VotingHeader = ({
	helpText, questionIndex, questionTotal, time, weight, weightLeft, answers, isPointsOrShares, isSurvey, className,
}: VotingHeaderProps) => {
	const isEditMode = useSelector((state: GlobalState) => state.votingModule.isEditMode);
	return (
		<OverDetailsWrapper className={className}>
			<DetailsWrapper>
				<InfoWrapper>
					{helpText && <StyledLabelText>{helpText}</StyledLabelText>}
					<LeftText>
						{weight !== 1 && (isSurvey || isPointsOrShares) && (
							<>
								Pozostało:&nbsp;
								{weightLeft}
								/
								{weight}
							</>
						)}

					</LeftText>
				</InfoWrapper>
				<InfoWrapper>
					{(time && !isEditMode) && (
						<VotingCounters
							answers={answers}
							endTime={time}
						/>
					)}
					<LeftText>
						Pytanie:&nbsp;
						{questionIndex}
						/
						{questionTotal}
					</LeftText>
				</InfoWrapper>
			</DetailsWrapper>
		</OverDetailsWrapper>
	);
};

export default VotingHeader;
