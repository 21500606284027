import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { PageHeaderProps } from '../@types/pageHeader.interface';
import LogoSvg from '../../../images/logo.svg';
import { GlobalState } from '../../../state/@types/redux.interface';

const TopBarWrapper = styled.div`
  	align-items: center;
  	display: flex;
	margin-bottom: 40px;
	max-width: 1000px;
	padding: 0 10px;
	width: 100%;
  
  	@media (max-width: 600px) {
		padding: 0 4px;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 30px;
	  }
`;
const ImagesWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
const TopBarTitleAndNameWrapper = styled.div`
	margin-left: 50px;
	@media (max-width: 600px) {
		margin-left: 0;
	}
`;
const TopBarTitleText = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 28px;
	font-weight: 800;
	margin-bottom: 14px;
  	@media (max-width: 600px) {
    	font-size: 16px;
  	}
`;
const VotingName = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 16px;
	font-weight: 400;
  	margin-bottom: 14px;
`;
const AgendaImage = styled.img`
	min-height: 143px;
	min-width: 210px;
  	@media (max-width: 600px) {
    	display: none;
  	}
`;
const MobileLogo = styled.img`
	margin-bottom: 32px;
	max-height: 60px;
	max-width: 400px;
	&.wyborekLogo {
		max-height: 26px;
	}
`;

const PageHeader: FunctionComponent<PageHeaderProps> = ({
	text, title, img, className, isLogoOnMobile,
}) => {
	const { details } = useSelector((state: GlobalState) => state.event);
	const activeSubpage = useSelector((state: GlobalState) => state.menuTab.activeSubPage);

	const getMobileLogo = () => {
		if (!isLogoOnMobile) return null;
		return (
			<MobileLogo
				alt="logo"
				className={details?.logo?.url ? '' : 'wyborekLogo'}
				src={details?.logo?.url && !activeSubpage ? details.logo.url : LogoSvg}
			/>
		);
	};

	return (
		<TopBarWrapper
			className={className + (isLogoOnMobile && ' mobile-visible')}
		>
			<ImagesWrapper className={className}>
				{typeof window !== 'undefined' && window.innerWidth > 600 ? (
					<AgendaImage
						alt="Agenda"
						className={className}
						src={img}
					/>
				) : getMobileLogo()}
			</ImagesWrapper>
			<TopBarTitleAndNameWrapper>
				<TopBarTitleText>
					{title}
				</TopBarTitleText>
				<VotingName>{text}</VotingName>
			</TopBarTitleAndNameWrapper>
		</TopBarWrapper>
	);
};

export default PageHeader;
