import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../contants/theme';
import AvatarBox from './AvatarBox';
import { ReceivedMessageProps } from './@types/receivedMessage.interface';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 15px 20px;
  	width: 100%;
`;

const MessageHeader = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	@media(min-width: 1000px) {
		margin-left: -5px;
	}
`;

const Time = styled.div`
	align-self: center;
	color: ${({ theme }) => theme.colors.fontGrey};
	font-size: 13px;
`;

const UserName = styled.div`
	color: ${({ isMe }) => (isMe ? theme.colors.aqua : theme.colors.fontBlue)};
	font-size: 13px;
	font-weight: 700;
  	margin: 0 5px;
`;

const Message = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 13px;
	margin-top: 5px;
	max-width: 100%;
`;

const ReceivedMessage = ({
	name, text, time, isMe,
}: ReceivedMessageProps) => (
	<Wrapper>
		<MessageHeader>
			<AvatarBox
				className=""
				isMe={isMe}
				name={name[0]}
				size={22}
			/>
			<UserName isMe={isMe}>
				{name}
				{isMe && (' (Ty)')}
			</UserName>
			<Time>{time}</Time>
		</MessageHeader>
		<Message>{text}</Message>
	</Wrapper>
);
export default ReceivedMessage;
