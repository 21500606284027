import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import BasicCheckbox from '../../../common/basic-checkbox/BasicCheckbox';
import { FileListProps } from '../@types/fileList.interface';
import { GlobalState } from '../../../../state/@types/redux.interface';
import { FileDto } from '../../../../services/@types/event.interface';
import FileItem from './FileItem';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const CheckAll = styled.div`
	display: flex;
	padding: 20px;
`;

const AquaLabel = styled.p`
	color: ${({ theme }) => theme.colors.aqua};
	font-size: 0.75rem;
	font-weight: bold;
	margin-left: 10px;
`;

const FilesWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.greyBackground};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FilesList: FunctionComponent<FileListProps> = ({ setFiles, files }) => {
	const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
	const allFiles = useSelector((state: GlobalState) => state.files.files);

	const checkAllFiles = () => {
		setFiles(isAllChecked ? [] : allFiles);
		setIsAllChecked(!isAllChecked);
	};

	useEffect(() => {
		if (files.length === 0) {
			setIsAllChecked(false);
		}
	}, [files]);

	const onFileClick = (file: FileDto) => {
		const foundFileIndex = files.indexOf(file);
		if (foundFileIndex > -1) {
			const tempFiles = [...files];
			tempFiles.splice(foundFileIndex, 1);
			setFiles(tempFiles);
			setIsAllChecked(false);
		} else {
			const tempFiles = [...files];
			tempFiles.push(file);
			setFiles(tempFiles);
			if (allFiles.length === tempFiles.length) {
				setIsAllChecked(true);
			}
		}
	};

	return (
		<Wrapper>
			<CheckAll>
				<BasicCheckbox
					isChecked={isAllChecked}
					onClick={checkAllFiles}
				/>
				<AquaLabel>zaznacz wszystkie</AquaLabel>
			</CheckAll>
			<FilesWrapper>
				{allFiles?.map((file) => (
					<FileItem
						key={file.id}
						file={file}
						isChecked={files.findIndex((selectedFile) => selectedFile.id === file.id) > -1}
						onClick={() => onFileClick(file)}
					/>
				))}
			</FilesWrapper>
		</Wrapper>
	);
};

export default FilesList;
