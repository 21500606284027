import React, { FC } from 'react';
import styled from 'styled-components';
import LogoSvg from '../../../../images/room/files/page-icon.svg';
import BasicButton from '../../../common/basic-button/BasicButton';

const AgendaImage = styled.img`
	height: auto;
	margin-bottom: 32.5px;
	width: 155px;
`;

const FilesSendModalWrapper = styled.div`
  	align-items: center;
  	background-color: rgba(7, 6, 76, 0.22);
  	display: flex;
	height: 100vh;
	justify-content: center;
	left:0;
	position: absolute;
	top:0;
	width: 100vw;
`;
const ModalBody = styled.div`
	align-items: center;
	background-color: #FFF;
	border-radius: 14px;
	display: flex;
	flex-direction: column;
	height: 420px;
	justify-content: center;
	max-width: 420px;
	padding: 60px;
	width: 420px;
`;
const ModalTitleText = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 20px;
	text-align: center;
`;
const ModalSubText = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 42px;
  	text-align: center;
`;
const StyledBasicButton = styled(BasicButton)`
	color: ${({ theme }) => theme.colors.buttonBlue};
`;
const FilesSendModal: FC<{close: () => void}> = ({ close }) => (
	<FilesSendModalWrapper>
		<ModalBody>
			<AgendaImage src={LogoSvg} />
			<ModalTitleText>Pliki zostały wysłane</ModalTitleText>
			<ModalSubText>Zaznaczone  pliki zostały wysłane na podany przez Ciebie adres email.</ModalSubText>
			<StyledBasicButton
				onClick={close}
			>
				zamknij
			</StyledBasicButton>
		</ModalBody>
	</FilesSendModalWrapper>
);
export default FilesSendModal;
