import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import LogoSvg from '../../../images/room/files/page-icon.svg';
import Stain1 from '../../../images/stain1.svg';
import Stain2 from '../../../images/stain2.svg';
import { FileDto } from '../../../services/@types/event.interface';
import { GlobalState } from '../../../state/@types/redux.interface';
import { showFile } from '../../../state/files.reducer';
import PageHeader from '../../common/page-header/PageHeader';
import FilePopup from '../../filePopup/FilePopup';
import FilesFooter from './components/FilesFooter';
import FilesList from './components/FilesList';
import FilesSendModal from './components/FilesSendModal';

const Wrapper = styled.div`
	height: 100%;
  	overflow: hidden;
	padding-bottom: 90px;
  	padding-top: 24px;
	@media (min-width: 700px) {
		background-image: url(${({ Stain1 }) => Stain1});
		background-repeat: no-repeat;
		background-position: -350px 250px; 
	}
	@media (min-width: 1000px) {
		padding-top: 0;
	}
`;

const BgImageHolder = styled.div`
	display: flex;
  	height: 100%;
	justify-content: center;
  	margin-top: 0;
  	overflow-y: auto;
	width: 100%;
	@media (min-width: 700px) {
		background-image: url(${({ Stain2 }) => Stain2});
		background-repeat: no-repeat;
		background-position: 490px 112px;
	}
	@media (min-width: 1000px) {
		background-position: 748px 58px; 
		background-size: 253px 290px;
	}
`;

const Content = styled.div`
	max-width: 1000px;
	padding: 0 10px;
	padding-bottom: 60px;
	width: 100%;
  	@media (min-width: 1000px) {
		margin-top: 50px;
		padding-bottom: 80px;
  	}
`;

const Files = () => {
	const dispatch = useDispatch();
	const activeFile = useSelector((state: GlobalState) => state.files.shownFile);
	const [files, setFiles] = useState<FileDto[]>([]);
	const [showFilesSendModal, setShowFilesSendModal] = useState<boolean>(false);

	return (
		<Wrapper Stain1={Stain1}>
			<BgImageHolder Stain2={Stain2}>
				{activeFile && (
					<FilePopup
						closePopup={() => dispatch(showFile(null))}
						fileUrl={activeFile.url}
						mimeType={activeFile.mimeType}
						title={activeFile.name}
					/>
				)}
				<Content>
					<PageHeader
						img={LogoSvg}
						isLogoOnMobile
						text="Możesz pobrać lub podglądnąć pojedyncze pliki lub zaznaczyć wszystkie i wysłać na swojego maila."
						title="Pliki do pobrania"
					/>
					<FilesList
						files={files}
						setFiles={setFiles}
					/>
				</Content>
				<FilesFooter
					files={files}
					openFilesSendModal={setShowFilesSendModal}
					setFiles={setFiles}
				/>
			</BgImageHolder>
			{showFilesSendModal && <FilesSendModal close={() => setShowFilesSendModal(false)} />}
		</Wrapper>
	);
};

export default Files;
