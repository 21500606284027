import * as React from 'react';
import { FunctionComponent } from 'react';
/* eslint-disable */
interface IMicrophoneStateIcon {
	active?: boolean;
	size?: number;
	style?: {};
	notActiveFill?: string;
	activeFill?: string;
	width?: number;
}
const MicrophoneStateIcon: FunctionComponent<IMicrophoneStateIcon> = ({ active, size, style={}, notActiveFill, width, activeFill }) => {
	return active ? (
		<svg
			id="prefix__Warstwa_1"
			data-name="Warstwa 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511 511"
			width={size}
			height={size}
			style={style}
		>
			<path fill="none" d="M0 0h511v511H0z" />
			<path
				d="M392.217 254.574a16.14 16.14 0 00-16.124 16.12 120.593 120.593 0 11-241.186 0 16.124 16.124 0 00-32.248 0 153.046 153.046 0 00136.715 151.985v32.956h-49.13a16.124 16.124 0 100 32.248h130.512a16.124 16.124 0 000-32.248h-49.134V422.68a153.045 153.045 0 00136.719-151.985 16.14 16.14 0 00-16.124-16.12z"
				fill={!activeFill ? width < 1000 ? '#FFF' : '#34345C' : activeFill}
			/>
			<path
				d="M255.498 361.657a91.066 91.066 0 0090.963-90.963v-160.85a90.96 90.96 0 00-181.922 0v160.85a91.064 91.064 0 0090.96 90.963zm-58.715-90.963v-160.85a58.715 58.715 0 01117.43 0v160.85a58.715 58.715 0 01-117.43 0z"
				fill={!activeFill ? width < 1000 ? '#FFF' : '#34345C': activeFill}
			/>
		</svg>
	) : (
		<svg
			data-name="Warstwa 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511 511"
			width={size}
			height={size}
			style={style}
		>
			<path fill="none" d="M0 0h511v511H0z" />
			<path
				d="M256.016 361.657a91.066 91.066 0 0090.962-90.963V218.11L479.91 85.179a15 15 0 10-21.213-21.213L346.978 175.685v-65.84a90.961 90.961 0 00-181.922 0v160.849a90.63 90.63 0 0024.683 62.23l-20.955 20.956a120.177 120.177 0 01-33.36-83.186 16.124 16.124 0 00-32.248 0 152.332 152.332 0 0040.249 103.253q1.267 1.38 2.566 2.725L74.015 448.65a15 15 0 1021.212 21.213l73.66-73.66a152.011 152.011 0 0071.004 26.477v32.956h-49.13a16.124 16.124 0 000 32.248h130.511a16.124 16.124 0 000-32.248H272.14V422.68a153.045 153.045 0 00136.718-151.985 16.124 16.124 0 00-32.248 0A120.536 120.536 0 01192.148 372.94l21.68-21.68a90.392 90.392 0 0042.188 10.396zm58.714-153.724L212.544 310.12a58.486 58.486 0 01-15.243-39.426v-160.85a58.715 58.715 0 01117.43 0zm0 42.426v20.335a58.718 58.718 0 01-76.342 56.007z"
				fill={!notActiveFill ? width < 1000 ? '#34345C' : '#FFF' : notActiveFill}
			/>
		</svg>
	);
}

export default MicrophoneStateIcon;
