import React, { FunctionComponent } from 'react';
import { BasicButtonProps } from '../../../../common/@types/basicButton.interface';
import BasicButton from '../../../../common/basic-button/BasicButton';

const ModifiedButton: FunctionComponent<BasicButtonProps> = ({
	children, onClick, color, textColor, className, style,
}) => (
	<BasicButton
		className={className}
		onClick={onClick}
		style={{
			padding: '10px 15px',
			backgroundColor: color,
			color: textColor,
			fontSize: 12,
			marginRight: 9,
			...style,
		}}
	>
		{children}
	</BasicButton>
);

export default ModifiedButton;
