import * as React from 'react';
/* eslint-disable */
function HandUpIcon({ fill }) {
	return (
		<svg
			height={22.3}
			viewBox="0 0 14.534 22.3"
			width={14.534}
		>
			<path
				d="M12.499 6.742a1.944 1.944 0 00-.7.11V3.421a1.966 1.966 0 00-1.885-1.965 1.933 1.933 0 00-.775.137A1.96 1.96 0 007.321.15a1.942 1.942 0 00-1.929 1.44 1.937 1.937 0 00-.768-.134 1.959 1.959 0 00-1.809 1.423 1.94 1.94 0 00-.78-.139A1.966 1.966 0 00.15 4.705v6.845a7.914 7.914 0 002.589 5.674v4.284a.645.645 0 00.645.645h7.767a.645.645 0 00.645-.645V17.15a7.781 7.781 0 002.589-5.572V8.707A1.966 1.966 0 0012.5 6.742zm.6 4.839a6.655 6.655 0 01-2.372 4.805.645.645 0 00-.217.483v3.993H4.028v-3.923a.645.645 0 00-.216-.482 6.682 6.682 0 01-2.373-4.907V4.705a.668.668 0 01.631-.677.65.65 0 01.669.65v5.178a.645.645 0 001.289 0V3.421a.668.668 0 01.631-.677.65.65 0 01.669.65v5.168a.645.645 0 101.289 0V2.089a.65.65 0 01.669-.65.668.668 0 01.631.677v6.446a.645.645 0 101.289 0V3.394a.65.65 0 01.669-.65.668.668 0 01.631.677v7.729a.645.645 0 001.289 0V8.68a.65.65 0 01.669-.65.668.668 0 01.631.677v2.874z"
				data-name="Path 77308"
				fill={fill}
			/>
		</svg>
	);
}

export default HandUpIcon;
