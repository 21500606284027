import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Logo from '../../../../images/logo.svg';
import { GlobalState } from '../../../../state/@types/redux.interface';
import { setVotingIdAction } from '../../../../state/votingModule.reducer';
import SubpageHeader from '../../../common/subpage-header/SubpageHeader';
import VotingModule from './VotingModule';

const Wrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	height: 100%;
	width: 100%;
	z-index: 10;
@media(min-width: 1000px) {
	height: calc(100vh - 138px);
}
`;

const MobileLogoWrapper = styled.div`
	align-items: center;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	height: 54px;
	justify-content: left;
	position: fixed;
	top: 0;
  	width: 100%;
	z-index: 50;
	@media(min-width: 700px) {
		height: 63px;
	} 
	@media(min-width: 1000px) {
		display: none;
	}
`;

const MobileLogo = styled.img`
	margin-left: 24px;
	max-height: 46px;
	max-width: 200px;
	&.wyborekLogo {
		max-height: 26px;
	}
	@media(min-width: 700px) {
		width: 120px;
		height: 24px;
	} 
	@media(min-width: 1000px) {
		display: none;
	}
`;
const StyledSubHeader = styled(SubpageHeader)`
	padding-top: 2px;
	@media(max-width: 999px) {
    position: fixed;
		top: 50px;
	}
`;

const VotingMain = ({ setVotingEndPopupStatus }): React.ReactNode => {
	const votingId = useSelector((state: GlobalState) => state.votingModule.votingId);
	const { details } = useSelector((state: GlobalState) => state.event);
	const dispatch = useDispatch();

	const closeVoting = () => {
		dispatch(setVotingIdAction(null));
	};

	return (
		<Wrapper>
			<MobileLogoWrapper>
				<MobileLogo
					className={details?.logo?.url ? '' : 'wyborekLogo'}
					src={details?.logo?.url || Logo}
				/>
			</MobileLogoWrapper>
			<StyledSubHeader
				closePopup={closeVoting}
				disableBackAction
				title="Głosowanie"
			/>
			<VotingModule
				eventType={details?.eventType}
				setIsThanks={setVotingEndPopupStatus}
				votingModuleId={votingId}
			/>
		</Wrapper>
	);
};

export default VotingMain;
