import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { VoteAsButtonProps } from '../../@types/voteAsButton.interface';
import { ActiveVotingStatusEnum } from '../../../../../../_enums/activeVotingStatusEnum';
import ModifiedButton from '../ModifiedButton';
import useWindowDimensions from '../../../../../../_utils/useWindowDimensions';
import { VotingStatusEnum } from '../../../../../../_enums/votingStatus.enum';
import ScoreVotingButton from '../ScoreVotingButton';
import ProxyMemberList from './ProxyMemberList';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 74%;
	@media(max-width: 600px) {
      	width: 100%;
		margin-bottom: 15px;
	}
`;

const Counter = styled.span`
	color: white;
	margin-left: 5px;
	
	&.editable-expanded {
		color: ${({ theme }) => theme.colors.fontBlue};
	}
	
	&.expanded {
    color: ${({ theme }) => theme.colors.aqua};
	}
`;

const VoteAsButton = styled(ModifiedButton)`
	white-space: nowrap;
	&.active,&.disabled,&.editable {
		&.expanded {
     		background-color: ${({ theme }) => theme.colors.white};
    	}
	}
	
	&.active {
		background-color: ${({ theme }) => theme.colors.aqua};
		color: ${({ theme }) => theme.colors.fontBlue};

    &.expanded {
      border: 2px solid ${({ theme }) => theme.colors.aqua};
    }
	}
	
	&.disabled {
    background-color: ${({ theme }) => theme.colors.lightGreyBackground};
    color: ${({ theme }) => theme.colors.fontLightGrey};

    &.expanded {
      border: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
    }
	}
	
	&.editable {

    background-color: ${({ theme }) => theme.colors.yellow};
    color: ${({ theme }) => theme.colors.fontBlue};

    &.expanded {
      border: 2px solid ${({ theme }) => theme.colors.yellow};
    }
	}
`;
const DesktopButtonsWrapper = styled.div`
	display: flex;
`;

const VoteAsWrapper = ({
	didVoted, voting, handleScrBoardPopup, votingStatus,
}: VoteAsButtonProps) => {
	const [votingAttorneysStatus, setVotingAttorneysStatus] = useState<ActiveVotingStatusEnum>(null);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [votedCounter, setVotedCounter] = useState<number>(0);
	const { width } = useWindowDimensions();
	const countVotedAttorneys = () => {
		let sum = 0;
		didVoted.forEach((singleDidVotedInfo) => {
			if (singleDidVotedInfo.didVoted) {
				sum += 1;
			}
		});
		return sum;
	};

	useEffect(() => {
		const attorneysVotingStatus = didVoted.every((attorney) => attorney.didVoted);
		if (attorneysVotingStatus && voting.answersEditable) {
			setVotingAttorneysStatus(ActiveVotingStatusEnum.EDITABLE);
		} else if (attorneysVotingStatus) {
			setVotingAttorneysStatus(ActiveVotingStatusEnum.DISABLED);
		} else {
			setVotingAttorneysStatus(ActiveVotingStatusEnum.ACTIVE);
		}
		setVotedCounter(countVotedAttorneys());
	}, [didVoted]);

	useEffect(() => {
		setVotedCounter(countVotedAttorneys());
	}, [didVoted]);

	const handleVoteAsClick = () => {
		if (votingAttorneysStatus !== ActiveVotingStatusEnum.DISABLED) {
			setIsExpanded(!isExpanded);
		}
	};

	return (
		<Wrapper>
			<DesktopButtonsWrapper>
				<VoteAsButton
					className={`${votingAttorneysStatus?.toLowerCase()} ${isExpanded ? 'expanded' : ''}`}
					onClick={handleVoteAsClick}
				>
					<span>głosuj jako pełnomocnik</span>
					<Counter className={(isExpanded) && (voting.answersEditable ? 'editable-expanded' : 'expanded')}>
						{votedCounter}
						/
						{didVoted.length}
					</Counter>
				</VoteAsButton>
				{width >= 600 && (
					<ScoreVotingButton
						className={VotingStatusEnum.DONE ? '' : 'disabled'}
						handleScrBoardPopup={handleScrBoardPopup}
						votingStatus={votingStatus}
					/>
				)}
			</DesktopButtonsWrapper>
			{isExpanded && (
				<ProxyMemberList
					didVoted={didVoted}
					setIsExpanded={setIsExpanded}
					voting={voting}
				/>
			)}
		</Wrapper>
	);
};

export default VoteAsWrapper;
