import React from 'react';
import styled from 'styled-components';
import TickSvg from '../../../images/room/TickSvg';

const Wrapper = styled.div`
	align-items: center;
	background-color: transparent;
	border: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
	border-color: ${({ theme }) => theme.colors.aqua};
	border-radius: 50%;
	border-style: solid;
	border-width: 2px;
	display: flex;
	height: 18px;
	justify-content: center;
	margin-right: 10px;
	width: 18px;
	
	&.checked {
		border-color: ${({ theme }) => theme.colors.aqua};
	}
	&.completed {
		background-color: ${({ theme }) => theme.colors.white};
		border-color: ${({ theme }) => theme.colors.fontBlue};
	}
`;

const RadioButton = ({ isChecked, isCompleted }: {isChecked: boolean, isCompleted: boolean }) => (
	<Wrapper
		className={`${isChecked ? 'checked' : ''} ${isCompleted ? 'completed' : ''}`}
		isChecked={isChecked}
		isCompleted={isCompleted}
	>
		<input
			hidden
			name="members"
			type="radio"
		/>
		{ (isCompleted || isChecked) && <TickSvg color={isCompleted && '#060708'} /> }
	</Wrapper>
);

export default RadioButton;
