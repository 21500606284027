/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GlobalState } from '../../../state/@types/redux.interface';
import { changeActiveMenuSubpage } from '../../../state/menuTab.reducer';
import { ITemplateGraphqlData } from '../../../templates/@types/ITemplateGraphqlData.interface';
import { MenuPopupsEnum } from '../../../_enums/menuPopupsEnum';
import SubpageHeader from '../../common/subpage-header/SubpageHeader';
import MenuContent from './components/MenuContent';
import MenuHeader from './components/MenuHeader';
import ContactPage from './menuSubPages/contactSubPage';
import FaqTemplate from './menuSubPages/faqSubPage';
import MarkdownTemplate from './menuSubPages/markdownTemplate';

const MenuWrapper = styled.div`
	-webkit-tap-highlight-color: transparent;
`;
const Popup = styled.div`
	background-color: #fff;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
`;
const StyledSubpage = styled(SubpageHeader)`
	position: fixed;
`;

const Menu :FunctionComponent<ITemplateGraphqlData> = ({ data, setPageIndex }) => {
	const dispatch = useDispatch();
	const activeSubpage = useSelector((state: GlobalState) => state.menuTab.activeSubPage);
	const refContainer = useRef<HTMLDivElement>(null);
	const navigateToRoom = () => {
		dispatch(changeActiveMenuSubpage(null));
		if (typeof window !== 'undefined') {
			if (window.innerWidth > 1000) {
				setPageIndex(0);
			}
		}
	};

	useEffect(() => {
		refContainer.current?.scrollTo(0, 0);
	}, [activeSubpage]);

	const choosePopup = () => {
		if (activeSubpage === MenuPopupsEnum.CONTACT) {
			return (
				<>
					<StyledSubpage
						closePopup={navigateToRoom}
						title="Kontakt"
					/>
					<Popup ref={refContainer}>

						<ContactPage
							data={data}
						/>
					</Popup>
				</>
			);
		}
		if (activeSubpage === MenuPopupsEnum.FAQ) {
			return (
				<Popup ref={refContainer}>
					<StyledSubpage
						closePopup={navigateToRoom}
						title="FAQ"
					/>
					<FaqTemplate
						data={data}
					/>
				</Popup>
			);
		}
		if (activeSubpage === MenuPopupsEnum.TERM) {
			return (
				<Popup ref={refContainer}>
					<StyledSubpage
						closePopup={navigateToRoom}
						title="Regulamin"
					/>
					<MarkdownTemplate
						data={data}
					/>
				</Popup>
			);
		}
	};

	return (
		<MenuWrapper>

			{choosePopup()}

			<MenuHeader />
			<MenuContent
				contactText="kontakt"
				faqText="FAQ"
				regulaminText="regulamin"
			/>
		</MenuWrapper>
	);
};
export default Menu;
