import moment from 'moment';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import EventActiveIcon from '../../../../images/room/EventActiveIcon';
import useWindowDimensions from '../../../../_utils/useWindowDimensions';
import { EventDateProps } from './@types/eventDate.interface';

const Wrapper = styled.div`
  	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.fontBlue};
	border-radius: 20px;
	display: flex;
	height: 67px;
	margin-bottom: 20px;
	max-width: 1000px;
	padding: 0 37px;
	position: sticky;
	top: 63px;
	width: 100%;
	z-index: 100;
  	@media (max-width: 1000px) {
    	flex-direction: column;
		align-items: flex-start;
		justify-content: center;
    	top: 10px;
		height: auto;
    	padding: 20px 37px;
  	}
  	@media (max-width: 600px) {
    	padding: 12px 14px;
  	}
`;
const EventStatusText = styled.div`
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 20px;
	font-weight: 800;
	margin-right: 30px;
  	@media (max-width: 1000px) {
    	margin-bottom: 13px;
  	}
  	@media (max-width: 600px) {
    	margin-bottom: 6px;
    	font-size: 16px;
  	}
`;
const DateText = styled.div`
  color: ${({ theme }) => theme.colors.fontBlue};
  font-size: 14px;
  font-weight: 700;
  margin-right: 20px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
const FromToText = styled.div`
  color: ${({ theme }) => theme.colors.fontBlue};
  font-size: 14px;
  margin-right: 8px;
`;
const DatesWrapper = styled.div`
	display: flex;
  flex-wrap: wrap;
`;
const SingleDateWrapper = styled.div`
	align-items: center;
  display: flex;
  flex-wrap: wrap;
`;
const EventDate: FunctionComponent<EventDateProps> = ({ startAt, endAt, eventHeader }) => {
	const getDateString = (date: moment.Moment): string => date.format('DD MMMM YYYY');
	const getTimeString = (date: moment.Moment): string => date.format('HH[:]mm');
	const { width } = useWindowDimensions();

	return (
		<Wrapper>
			<EventStatusText>
				{eventHeader}
				<EventActiveIcon
					size={width >= 600 ? 24 : 18}
					style={{ marginLeft: 16 }}
				/>
			</EventStatusText>
			<DatesWrapper>
				<SingleDateWrapper>
					<FromToText>
						od:
					</FromToText>
					<DateText>
						{`${getDateString(startAt)} godz. ${getTimeString(startAt)}`}
					</DateText>
				</SingleDateWrapper>
				<SingleDateWrapper>
					<FromToText>
						do:
					</FromToText>
					<DateText>
						{`${getDateString(endAt)} godz. ${getTimeString(endAt)}`}
					</DateText>
				</SingleDateWrapper>
			</DatesWrapper>
		</Wrapper>
	);
};

export default EventDate;
