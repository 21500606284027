import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../../state/@types/redux.interface';
import { theme } from '../../../../contants/theme';
import MemberItem from './MemberItem';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.anotherGrey};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
const UserListGroupText = styled.div`
  color: #B4B4D6;
  font-size: 13px;
  font-weight: bold;
  margin: 10px 0;
  padding: 0 20px;
`;
const UserListGroupLine = styled.div`
  background-color: #EFEFF4;
  height: 3px;
  width: 100%;
`;
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.anotherGrey};
  }
`;

const ListOfMembers = () => {
	const users = useSelector((state: GlobalState) => state.chat.users);
	const roomConfig = useSelector((state: GlobalState) => state.roomConfig);
	const raisedHandUsers = users.filter((value) => value.handRaised && value.isInCall);
	const notRaiseHandUsers = users.filter((value) => !value.handRaised && value.isInCall);
	const notInCallUsers = users.filter((value) => !value.isInCall);
	return (
		<Wrapper>
			<GlobalStyle theme={theme} />
			{raisedHandUsers.length > 0
			&& (
				<>
					<UserListGroupText>Podniesione ręce</UserListGroupText>
					<UserListGroupLine />
					{raisedHandUsers.map((user) => (
						<MemberItem
							key={user.voterEventAccessId}
							firstName={user.firstName}
							isHandUp={user.handRaised}
							isInCall={user.isInCall}
							isMuted={user.isMuted}
							lastName={user.lastName}
							userId={user.voterEventAccessId}
							voterConferenceRole={user.voterConferenceRole}
						/>
					))}
				</>
			)}
			{
				notRaiseHandUsers.length > 0 && (
					<>
						<UserListGroupText>Uczestnicy w wideokonferencji</UserListGroupText>
						<UserListGroupLine />
						{
							notRaiseHandUsers.map((user) => (
								<MemberItem
									key={user.voterEventAccessId}
									firstName={user.firstName}
									isHandUp={user.handRaised}
									isInCall={user.isInCall}
									isMuted={user.isMuted}
									lastName={user.lastName}
									userId={user.voterEventAccessId}
									voterConferenceRole={user.voterConferenceRole}
								/>
							))
						}
					</>
				)
			}
			{notInCallUsers.length > 0 && (
				<>
					<UserListGroupText>{roomConfig.video ? 'Pozostali uczestnicy wydarzenia' : 'Uczestnicy czatu'}</UserListGroupText>
					<UserListGroupLine />
					{
						notInCallUsers.map((user) => (
							<MemberItem
								key={user.voterEventAccessId}
								firstName={user.firstName}
								isHandUp={user.handRaised}
								isInCall={user.isInCall}
								isMuted={user.isMuted}
								lastName={user.lastName}
								userId={user.voterEventAccessId}
								voterConferenceRole={user.voterConferenceRole}
							/>
						))
					}
				</>
			)}
		</Wrapper>
	);
};
export default ListOfMembers;
