import React from 'react';
import styled from 'styled-components';
import RadioButton from '../../../../../common/radio-button/RadioButton';
import { ProxyMemberProps } from '../../../../../voting-module/voting-header/@types/ProxyMember.interface';

const Wrapper = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 50px;
	justify-content: space-between;
  	width: 100%;

	&:not(:last-of-type) {
		border-bottom: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
	}
`;

const VotedText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 12px;
	font-weight: bold;
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const ChangeVote = styled.p`
	color: ${({ theme }) => theme.colors.orange};
	font-size: 12px;
	font-weight: bold;
`;

const ProxyMember = ({
	member, isActive, onClick, setEditVoting, didVoted, isEditable, index, activeProxyIndex,
}: ProxyMemberProps) => (
	<Wrapper onClick={member && !didVoted ? onClick : () => {}}>
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<RadioButton
				isChecked={index === activeProxyIndex}
				isCompleted={didVoted}
			/>
			<Details>
				<p
					style={{
						fontWeight: isActive ? 'bold' : 'normal',
					}}
				>
					{`${member?.firstName} ${member?.lastName}`}
				</p>
				{didVoted && <VotedText>Głos oddany</VotedText>}
			</Details>
		</div>
		{(didVoted && isEditable) && <ChangeVote onClick={setEditVoting}>zmień głos</ChangeVote>}
	</Wrapper>
);

export default ProxyMember;
