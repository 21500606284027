import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { GlobalState } from '../../../../state/@types/redux.interface';

const StyledYesOrNoButton = styled.button`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 15px;
	color: ${({ theme }) => theme.colors.fontBlue};
	cursor: pointer;
	display: flex;
	font-size: .75rem;
	font-weight: 700;
	justify-content: space-between;
	margin-bottom: 0.75rem;
	min-height: 48px;
	outline: none;
	padding: 0.85rem 24px;
	
	img {
		visibility: hidden;
	}
	
	@media (min-width: 1000px) {
		font-size: 14px;
	}
	
	&.active {
		background-color: ${({ theme }) => theme.colors.dirtyAqua};
		border-color: ${({ theme }) => theme.colors.aqua};
		img {
			visibility: visible;
		}
		
		&.edited {
			background-color: transparent;
			border-color: ${({ theme }) => theme.colors.orange};
			img {
				visibility: visible;
			}
			
			&.old-answer {
				background-color: ${({ theme }) => theme.colors.dirtyAqua};
			}
		}
	}
`;
const StyledYesOrNoText = styled.span`
	max-width: 90%;
	text-align: left;
`;
const StyledBackButton = styled.img`
	height: 1.1rem;
	width: 1.3rem;
`;

const YesOrNoButton = ({
	onClick, text, active, img, isOldAnswer,
}) => {
	const isEditMode = useSelector((state: GlobalState) => state.votingModule.isEditMode);
	return (
		<StyledYesOrNoButton
			className={`${active || isOldAnswer ? 'active' : ''} ${isEditMode && active ? 'edited' : ''} ${isOldAnswer && 'old-answer'}`}
			isEditMode={isEditMode}
			onClick={onClick}
		>
			<StyledYesOrNoText>{text}</StyledYesOrNoText>
			<StyledBackButton src={img} />
		</StyledYesOrNoButton>
	);
};

export default YesOrNoButton;
