import * as React from 'react';
/* eslint-disable */
function EventActiveIcon({ size, style = {} }) {
	return (
		<svg
			height={size}
			viewBox="0 0 24 24"
			width={size}
			xmlns="http://www.w3.org/2000/svg"
			style={style}
		>
			<g data-name="Group 6951">
				<g data-name="Group 6950">
					<g data-name="Group 6949">
						<g
							data-name="Ellipse 7069"
							fill="#fff"
							stroke="#2bf5ba"
							strokeWidth={2}
							transform="translate(2.001 2.001)"
						>
							<circle
								cx={10}
								cy={10}
								r={10}
								stroke="none"
							/>
							<circle
								cx={10}
								cy={10}
								fill="none"
								r={11}
							/>
						</g>
					</g>
					<path
						d="M10.612 17.072a1.141 1.141 0 01-.96-.48l-2.99-3.841a1.216 1.216 0 011.92-1.494l2.027 2.614 4.481-5.814a1.216 1.216 0 011.92 1.494l-5.438 7.041a1.4 1.4 0 01-.96.48z"
						data-name="Path 558"
						fill="#07064e"
					/>
				</g>
			</g>
		</svg>
	);
}

export default EventActiveIcon;
