import React, { FC } from 'react';
import styled from 'styled-components';
import Arrow from '../../../../../images/room/rightArrow.svg';
import useWindowDimensions from '../../../../../_utils/useWindowDimensions';

export enum AgendaDateHeaderArrowsType {
	LEFT = 'left',
	RIGHT = 'right',
}
const Wrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.fontBlue};
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	position: absolute;
	width: 34px;
	${({ type }: IAgendaDateHeaderArrows) => (
		() => {
			if (type === AgendaDateHeaderArrowsType.RIGHT) return 'right: 0;border-top-right-radius: 20px;';
			if (type === AgendaDateHeaderArrowsType.LEFT) return 'left: 0;border-top-left-radius: 20px;';
		})};
  @media (max-width: 600px) {
		width: 19px;
	}
`;
const ArrowImage = styled.img`
	transform: ${({ type }: IAgendaDateHeaderArrows) => (type === AgendaDateHeaderArrowsType.LEFT ? 'rotate(180deg)' : 'rotate(0deg)')};
  @media (max-width: 600px) {
    width: 11px;
  }
`;
interface IAgendaDateHeaderArrows {
	onClick: (amount: number) => void;
	type: AgendaDateHeaderArrowsType;
}
const AgendaDateHeaderArrows: FC<IAgendaDateHeaderArrows> = ({ type, onClick }) => {
	const { width } = useWindowDimensions();
	return (
		<Wrapper
			onClick={() => (type === AgendaDateHeaderArrowsType.RIGHT ? onClick(width / 3) : onClick(-width / 3))}
			type={type}
		>
			<ArrowImage
				src={Arrow}
				type={type}
			/>
		</Wrapper>
	);
};
export default AgendaDateHeaderArrows;
