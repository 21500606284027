import * as React from 'react';

const ObserverTypeIcon = ({
	size, type: present,
}) => (
	<svg
		height={size}
		style={{ float: 'right' }}
		type={present}
		viewBox="0 0 461.312 461.312"
		width={size}
		xmlns="http://www.w3.org/2000/svg"
	>

		<path
			/* eslint-disable-next-line max-len */
			d="M230.656 156.416c-40.96 0-74.24 33.28-74.24 74.24s33.28 74.24 74.24 74.24 74.24-33.28 74.24-74.24-33.28-74.24-74.24-74.24zm-5.632 52.224c-9.216 0-16.896 7.68-16.896 16.896h-24.576c.512-23.04 18.944-41.472 41.472-41.472v24.576z"
			fill={(() => {
				if (present === 'present') {
					return (
						'#494981'
					);
				}
				if (present === 'absent') {
					return (
						'#EBEBEB'
					);
				}
				return (
					'#C7C7D9'
				);
			})()}
		/>

		<path
			/* eslint-disable-next-line max-len */
			d="M455.936 215.296c-25.088-31.232-114.688-133.12-225.28-133.12S30.464 184.064 5.376 215.296c-7.168 8.704-7.168 21.504 0 30.72 25.088 31.232 114.688 133.12 225.28 133.12s200.192-101.888 225.28-133.12c7.168-8.704 7.168-21.504 0-30.72zm-225.28 122.88c-59.392 0-107.52-48.128-107.52-107.52s48.128-107.52 107.52-107.52 107.52 48.128 107.52 107.52-48.128 107.52-107.52 107.52z"
			fill={(() => {
				if (present === 'present') {
					return (
						'#494981'
					);
				}
				if (present === 'absent') {
					return (
						'#EBEBEB'
					);
				}
				return (
					'#C7C7D9'
				);
			})()}
		/>
	</svg>
);

export default ObserverTypeIcon;
