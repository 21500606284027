import React, {
	FunctionComponent, useState,
} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import ContactIcon from '../../../../images/voting/voting-drawing.svg';
import Stain3 from '../../../../images/menu-subpage/contactStain.svg';
import FacebookImg from '../../../../images/menu-subpage/facebook.svg';
import LinkedinImg from '../../../../images/menu-subpage/linkedin.svg';
import { ITemplateGraphqlData } from '../../../../templates/@types/ITemplateGraphqlData.interface';
import { sendSupportEmail } from '../../../../services/services';
import Popup from '../../../common/popup/Popup';
import SupportImage from '../../../../images/faqIcon.svg';
import TopWrapper from './components/TopWrapper';

const ContactWrapper = styled.div` 
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	flex-direction: column;
  	min-height: ${({ theme }) => theme.colors.scrollContainerSize};
	overflow-y: auto;
`;
const ContactBottomWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	font-size: 12px;
	font-weight: 400;
	padding: 40px 30px 0 24px;
	width: 100%;
	@media(min-width: 700px) {
		width: 90%;
		margin: auto;
		font-size: 14px;
	}
	@media(min-width: 1000px) {
		padding: 40px 30px 0 30px;
		width: 1000px;
		align-self: center;
	}
`;
const InputTitle = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 4px;
`;
const GreenDot = styled.div`
	align-self: center;
	background-color: ${({ theme }) => theme.colors.aqua};
	border-radius: 100%;
	height: 4px;
	margin-right: 7px;
	width: 4px;
`;
const InputTitleText = styled.span`
	font-weight: 600;
`;
const ContactInput = styled.input`
	-webkit-appearance: none;
	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 18px;
	color: ${({ theme }) => theme.colors.agendaGrey};
	font-weight: 700;
  	min-height: 48px;
	outline: none;
	padding-left: 22px;
	width: 100%;
	&:focus {
		background-color: ${({ theme }) => theme.colors.dirtyAqua};
		border: 2px solid ${({ theme }) => theme.colors.aqua};
		color: ${({ theme }) => theme.colors.fontBlue};  
	}
  &.error {
    background-color: ${({ theme }) => theme.colors.lightErrorOrange};
    border: 2px solid ${({ theme }) => theme.colors.errorOrange};
  }
`;

const MessageArea = styled(TextareaAutosize)`
	-webkit-appearance: none;
	//text-align: left top;
	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 18px;
	color: ${({ theme }) => theme.colors.agendaGrey};
	font-weight: 700;
	min-height: 82px;
	outline: none;
	overflow: scroll;
	padding: 15px 20px;
	width: 100%;
	::-webkit-scrollbar {
		background: transparent;
		width: 0;
	}

	&:focus {
		background-color: ${({ theme }) => theme.colors.dirtyAqua};
		border: 2px solid ${({ theme }) => theme.colors.aqua};
		color: ${({ theme }) => theme.colors.fontBlue};  
	}
  &.error {
    background-color: ${({ theme }) => theme.colors.lightErrorOrange};
    border: 2px solid ${({ theme }) => theme.colors.errorOrange};
  }
`;
const SendMessage = styled.button`
	align-self: center;
	background-color: ${({ theme }) => theme.colors.aqua};
	border: none;
	border-radius: 18px;
	color: ${({ theme }) => theme.colors.fontBlue};
	cursor: pointer;
	font-size: 12px;
	font-weight: 700;
	outline: none;
	padding: 9px 22px;
	text-align: center;
`;
const SupportWrapper = styled.div`
	background-image: url(${({ Stain3 }) => Stain3});
	background-position: -274px 19px; 
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	padding: 70px 35px 80px 35px;
	width: 100%;
	@media (min-width: 550px) {
		background-position: -173px 19px; 
	}
	@media (min-width: 650px) {
		background-position: -116px 37px; 
	}
	@media (min-width: 700px) {
		background-size: 1300px 1300px;
		background-position: -270px -173px; 
	}
	@media (min-width: 850px) {
		background-position: 50%  -173px; 
	}
	@media (min-width: 900px) {
		background-position: -173px -173px; 
	}
	@media (min-width: 1000px) {
		background-position: 50%  -173px; 
	}
`;
const StpportTitle = styled.span`
	font-weight: 700;
	margin: 0 auto;
	margin-bottom: 20px;
`;
const StpportSubTitle = styled.span`
	font-size: 12px;
	font-weight: 500;
	margin: 0 auto;
	margin-bottom: 10px;
	
`;
const ContactBar = styled.div`
	border-bottom: 4px solid ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 34px 0;
	@media (min-width: 700px) {
		width: 90%;
		margin: auto;
	}
	@media(min-width: 1000px) {
		width: 700px;
		margin: auto;
	}
`;
const ContactBarDetails = styled.span`
	align-self: center;
	font-weight: 700;
`;
const TechSupportButton = styled.a`
	align-self: center;
	background-color: ${({ theme }) => theme.colors.aqua};
	border: none;
	border-radius: 18px;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: block;
	font-size: 12px;
	font-weight: 700;
	height: 35px;
	outline: none;
	padding: 9px 0;
	text-align: center;
	text-decoration: none;
	width: 116px;
`;
const SocialMediaTitle = styled.span`
	font-size: 14px;
	font-weight: 700;
	margin: 0 auto;
	margin-bottom: 22px;
	margin-top: 60px;
`;
const SocialMediaSubTitle = styled.span`
	font-size: 12px;
	font-weight: 500;
	margin: 0 auto;
	margin-bottom: 30px;
`;
const SocialMediaIconsWrapper = styled.div`
	margin: 0 auto;
`;
const MediaLink = styled.a`
	margin: 0 10px;
`;
const StyledLabelText = styled.span`
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 0.75rem;
	margin-bottom: 0.35rem;
	text-transform: lowercase;
	&::before {
		background-color: ${({ theme }) => theme.colors.aqua};
		border-radius: 100%;
		content: '';
		display: block;
		height: 4px;
		margin-right: 0.5rem;
		width: 4px;
	}
`;
const StyledErrorText = styled(StyledLabelText)`
	color: ${({ theme }) => theme.colors.errorOrange};
	margin-bottom: 0;
	margin-top: 0.35rem;
	visibility: hidden;
	&.error {
		margin-bottom: 1.875rem;
		visibility: visible;
	}
	&::before {
		background-color: ${({ theme }) => theme.colors.errorOrange};
	}
`;
const StyledErrorEmail = styled(StyledLabelText)`
	color: ${({ theme }) => theme.colors.errorOrange};
	margin-bottom: 0;
	margin-top: 0.35rem;
	visibility: hidden;
	&.error {
		margin-bottom: 1.875rem;
		visibility: visible;
	}
	&::before {
		background-color: ${({ theme }) => theme.colors.errorOrange};
	}
`;
const StyedContactTopWrapper = styled(TopWrapper)`
	align-self: center;
`;

const ContactPage: FunctionComponent<ITemplateGraphqlData> = () => {
	const [email, setEmail] = useState<string>('');
	const [text, setText] = useState<string>('');
	const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
	const [popupContent, setPopupContent] = useState<string | React.ReactNode>();
	const [dirtyText, setDirtyText] = useState(false);
	const [dirtyEmail, setDirtyEmail] = useState(false);
	const popupMessage = 'Twoje zgłoszenie zostało przesłane do Administratora wydarzenia';
	const emptyMessage = text === '';
	const emptyEmail = email === '';

	// eslint-disable-next-line max-len
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const invalid = !re.test(String(email).toLowerCase());

	const handleSendBtn = (content: string | React.ReactNode) => {
		if (invalid) {
			setDirtyEmail(true);
		} else {
			setDirtyEmail(false);
		}
		if (!dirtyEmail && emptyMessage) {
			setDirtyText(true);
		} else {
			setDirtyText(false);
		}
		if (!emptyMessage && !emptyEmail && !invalid) {
			sendSupportEmail(email, text)
				.then(() => {
					setEmail('');
					setText('');
				});
			setPopupContent(content);
			setPopupOpen(true);
		}
	};

	return (
		<ContactWrapper>
			{(isPopupOpen && popupContent) && (
				<Popup
					content={popupContent}
					img={SupportImage}
					setPopupStatus={setPopupOpen}
					title="Zgłoszenie do supportu"
				/>
			)}
			<StyedContactTopWrapper
				img={ContactIcon}
				text="Zostaw nam swój email i napisz wiadomość a my skontaktujemy się z Tobą w przeciągu 1h"
				title="Zostaw nam wiadomość!"
			/>
			<ContactBottomWrapper>
				<InputTitle>
					<GreenDot />
					<InputTitleText>Twój adres email</InputTitleText>
				</InputTitle>
				<ContactInput
					className={dirtyEmail ? 'error' : ''}
					onChange={(e) => setEmail(e.target.value)}
					placeholder="wpisz swój email"
					type="email"
					value={email}
				/>
				<StyledErrorEmail className={dirtyEmail ? 'error' : ''}>Błędny adres email</StyledErrorEmail>
				<InputTitle>
					<GreenDot />
					<InputTitleText>Twoja wiadomość</InputTitleText>
				</InputTitle>
				<MessageArea
					className={dirtyText ? 'error' : ''}
					onChange={(e) => setText(e.target.value)}
					placeholder="Napisz wiadomość"
					value={text}
				/>
				<StyledErrorText className={dirtyText ? 'error' : ''}>Wiadomość nie może być pusta</StyledErrorText>
				<SendMessage
					onClick={() => {
						// eslint-disable-next-line no-unused-expressions
						(!emptyEmail || !emptyMessage) ? handleSendBtn(popupMessage) : null;
					}}
				>
					Wyślij wiadomość
				</SendMessage>

			</ContactBottomWrapper>
			<SupportWrapper Stain3={Stain3}>
				<StpportTitle>Support techniczny</StpportTitle>
				<StpportSubTitle>zadzwon do nas lub napisz email</StpportSubTitle>
				<ContactBar>
					<ContactBarDetails>+46 535 150 221</ContactBarDetails>
					<TechSupportButton href="tel: +48 535 150 221">Zadzwoń</TechSupportButton>
				</ContactBar>
				<ContactBar>
					<ContactBarDetails>support@wyborek.pl</ContactBarDetails>
					<TechSupportButton href="mailto: support@wyborek.pl">Wyślij email</TechSupportButton>
				</ContactBar>
				<SocialMediaTitle>Znajdź nas!</SocialMediaTitle>
				<SocialMediaSubTitle>Jesteśmy w social mediach! Śledź nasze profile</SocialMediaSubTitle>
				<SocialMediaIconsWrapper>
					<MediaLink href="https://pl.linkedin.com/company/wyborek">
						<img
							alt="Linkedin"
							src={LinkedinImg}
						/>
					</MediaLink>
					<MediaLink href="https://www.facebook.com/wyborek">
						<img
							alt="facebook"
							src={FacebookImg}
						/>
					</MediaLink>
				</SocialMediaIconsWrapper>
			</SupportWrapper>
		</ContactWrapper>
	);
};
export default ContactPage;
