import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getStatus } from '../../../../_utils/date';
import BasicButton from '../../../common/basic-button/BasicButton';
import { MultipleVotesItemProps } from './@types/multipleVotesItem.interface';
import VotingItem from './VotingItem';

const Wrapper = styled.div`
	-webkit-tap-highlight-color: transparent;
	font-size: 12px;
`;

const Info = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 15px 25px;
  width: 100%;
`;

const Expand = styled.div`
	padding: 0 20px;
`;

const VotingIndex = styled.span`
	font-weight: 700;
	margin-right: 3px;
`;

const VotingTitleWrapper = styled.div`
	display: flex;
	width: 100%;
	
	p {
		width: 100%;
	}
`;
const StyledBasicButton = styled(BasicButton)`
  	color: ${({ theme }) => theme.colors.buttonBlue};
  	font-size: 12px;
  	height: 35px;
  	padding: 0 17px;
`;
const StyledBasicButtonBlue = styled(StyledBasicButton)`
	background-color: ${({ theme }) => theme.colors.fontBlue};
  	color: ${({ theme }) => theme.colors.aqua};
`;

const MultipleVotesItem = ({
	voting, index, openPopup, openedAgendaPanel, setOpenedAgendaPanel, id,
}: MultipleVotesItemProps) => {
	const [isExpanded, expand] = useState<boolean>(openedAgendaPanel === id);
	const status = getStatus(moment(voting.startAt), moment(voting.endAt));

	useEffect(() => {
		if (index === 1 && openedAgendaPanel === '') setOpenedAgendaPanel(id);
	}, []);

	useEffect(() => {
		expand(openedAgendaPanel === id);
	}, [openedAgendaPanel]);
	return (
		<Wrapper
			className="borderBottom"
			status={status}
		>
			<Info
				onClick={() => (id !== openedAgendaPanel ? setOpenedAgendaPanel(id) : setOpenedAgendaPanel(''))}
				status={status}
			>
				<VotingTitleWrapper>
					<VotingIndex>
						{index}
						.
					</VotingIndex>
					<p>{voting.name}</p>
				</VotingTitleWrapper>
				{!isExpanded ? (
					<StyledBasicButton
						onClick={() => null}
					>
						rozwiń
					</StyledBasicButton>
				) : (
					<StyledBasicButtonBlue
						onClick={() => null}
					>
						zwiń
					</StyledBasicButtonBlue>
				)}
			</Info>
			{isExpanded && (
				<Expand>
					<VotingItem
						isSingle
						openPopup={openPopup}
						voting={voting}
					/>
				</Expand>
			)}
		</Wrapper>
	);
};

export default MultipleVotesItem;
