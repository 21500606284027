import { graphql, navigate } from 'gatsby';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import styled from 'styled-components';
import loadable from '@loadable/component';
import moment from 'moment';
import Layout from '../components/layout';
import { AgendaDetails } from '../components/room/agenda/agendaDetails';
import Files from '../components/room/files/Files';
import SubFooter from '../components/room/menu/components/footer';
import Menu from '../components/room/menu/Menu';
import NavigationBar from '../components/room/NavigationBar';
import VideoChat from '../components/room/videoChat/VideoChat';
import config from '../config';
import { MessageDto } from '../services/@types/chat.interface';
import {
	fetchChatHistory, fetchChatMembers, getUserDetails, getWsToken, refreshToken,
} from '../services/services';
import { GlobalState } from '../state/@types/redux.interface';
import {
	addMessageAction,
	addUserAction,
	fetchAllEventUsers,
	loadMessagesAction,
	lowerHandAction,
	raiseHandAction,
	removeUserAction,
	setSocketAction,
	setUsersAction,
} from '../state/chat.reducer';
import { fetchEventDetailsAction, setEventLoadingStatusAction } from '../state/event.reducer';
import { fetchEventFilesAction } from '../state/files.reducer';
import { changeActiveMenuSubpage } from '../state/menuTab.reducer';
import { fetchEventRoomsAction } from '../state/roomConfig.reducer';
import { fetchStreamingDataAction, showNewAlertAction } from '../state/streaming.reducer';
import { fetchUserPermissions } from '../state/userPermissions.reducer';
import { setAttorneysAction } from '../state/votingModule.reducer';
import { ITemplateGraphqlData } from '../templates/@types/ITemplateGraphqlData.interface';
import { MenuPopupsEnum } from '../_enums/menuPopupsEnum';
import useWindowDimensions from '../_utils/useWindowDimensions';
import { VideoConferenceAlertsEnum } from '../_enums/videoConferenceAlertsEnum';
import { setGlobalScrollHidden } from '../_utils/globalScrollHidden';
import checkIfWindowExist from '../_utils/windowUndefined';
import AuthorizeLoader from '../components/common/authorize-loader/AuthorizeLoader';
import PreviewBorder from '../components/common/preview/Preview';
import AttendanceMain from '../components/room/attendanceList/AttendanceMain';

const ContentWrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column-reverse;
  height: calc(100vh - ${({ isFooter }) => (isFooter ? '58px' : '108px')});
  margin-top: 58px;
  position: relative;
  width: 100%;
  //&.isAttendanceList {
	//  height: 100%;
	//  margin-bottom: 50px;
	//  min-height: 100vh;
  //}

  @media (max-width: 1000px) {
    margin-top: 0;
    height: auto;
    flex-direction: column;
  }
`;

const StreamingComponent = loadable(() => import('../components/room/videoChat/streaming/StreamingComponent'));
const Room: FunctionComponent<ITemplateGraphqlData> = ({ data }) => {
	let interval;
	const files = useSelector((state: GlobalState) => state.files.files);
	const dispatch = useDispatch();
	const {
		details,
		data: eventData,
	} = useSelector((state: GlobalState) => state.event);
	const roomConfig = useSelector((state: GlobalState) => state.roomConfig);
	const socket = useSelector((state: GlobalState) => state.chat.socket);
	const streaming = useSelector((state: GlobalState) => state.streaming);
	const isAttendanceList = roomConfig.attendanceList;
	const isVideoChatTab = roomConfig.video || roomConfig.chat;
	const isOnlyChat = !roomConfig.video && roomConfig.chat;
	const { width } = useWindowDimensions();
	const [pageIndex, setPageIndex] = useState(0);
	const [videoChatIndex, setVideoChatIndex] = useState<number>(0);
	const isPreviewReally = details?.isPreview;

	useEffect(() => {
		dispatch(setEventLoadingStatusAction(true));
		refreshToken();
		dispatch(fetchEventFilesAction());
		dispatch(fetchEventRoomsAction());
		dispatch(fetchAllEventUsers());
	}, []);
	const {
		appId,
		uid,
		channel,
		rtcToken,
	} = { ...streaming?.data };
	const streamingDataDefined = appId && uid && channel && rtcToken;
	const pages = [];

	useEffect(() => {
		dispatch(fetchStreamingDataAction());
		dispatch(fetchUserPermissions());
	}, []);

	useEffect(() => {
		if (roomConfig.chat && !roomConfig.video) setVideoChatIndex(1);
	}, [roomConfig.chat]);

	useEffect(() => {
		if (eventData) {
			dispatch(setEventLoadingStatusAction(false));
		}
	}, [eventData]);

	useEffect(() => {
		if (details && moment(moment()
			.utc()
			.format())
			.isAfter(details?.accessEndAt)) {
			navigate('/eventEnded');
		}
	}, [details]);

	useEffect(() => {
		if (width >= 1000 && videoChatIndex === 2) {
			setVideoChatIndex(0);
		}
		if (width < 1000 && roomConfig.chat) {
			if (roomConfig.video) {
				setVideoChatIndex(2);
			} else {
				setVideoChatIndex(1);
			}
		}
	}, [width]);

	if (details) {
		pages.push(<AgendaDetails />);
	}

	if (isVideoChatTab) {
		pages.push(
			<VideoChat
				roomConfig={roomConfig}
				setVideoChatIndex={setVideoChatIndex}
				streamingDataDefined={streamingDataDefined}
				videoChatIndex={videoChatIndex}
				width={width}
			/>,
		);
	}

	if (files?.length !== 0) {
		pages.push(<Files />);
	}
	if (!isPreviewReally && isAttendanceList) {
		pages.push(<AttendanceMain />);
	}

	pages.push(
		<Menu
			data={data}
			setPageIndex={setPageIndex}
		/>,
	);

	const initWebSockets = () => {
		getWsToken()
			.then(() => {
				const socket = io(config.WEBSOCKET_URL, {
					path: `${config.WEBSOCKET_PATH}/socket.io`,
					withCredentials: true,
				});
				dispatch(setSocketAction(socket));
				// eslint-disable-next-line
				checkIfWindowExist(() => (window as any).socketio = socket);
			});
	};

	useEffect(() => {
		if (socket) return;
		initWebSockets();
	}, []);

	const changePage = (index) => {
		setPageIndex(index);
		checkIfWindowExist(() => window.scrollTo(0, 0));
	};

	useEffect(() => {
		setGlobalScrollHidden(isVideoChatTab && pageIndex === 1);
	}, [pageIndex]);

	const fetchEventDetails = () => {
		dispatch(fetchEventDetailsAction());
	};

	const setProxyMembers = () => {
		getUserDetails()
			.then(({ data }) => {
				dispatch(setAttorneysAction(data.hasAttorney));
			});
	};

	const navigateToMenuSubpage = (subpage: MenuPopupsEnum) => {
		dispatch(changeActiveMenuSubpage(subpage));
		setPageIndex(pages.length - 1);
		checkIfWindowExist(() => window.scrollTo(0, 0));
	};

	useEffect(() => {
		fetchEventDetails();
		setProxyMembers();
		if (!interval) {
			interval = setInterval(() => {
				fetchEventDetails();
			}, 5000);
		}
		return () => {
			clearInterval(interval);
			interval = null;
		};
	}, []);

	useEffect(() => {
		console.log('SOCKET', socket);
		if (!socket) {
			return;
		}
		socket.on('connect', () => {
			fetchChatHistory(0, 20)
				.then(({ data }) => {
					dispatch(loadMessagesAction(data));
				});
			setTimeout(() => {
				fetchChatMembers()
					.then(({ data }) => {
						dispatch(setUsersAction(data));
					});
			}, 2000);
		});

		socket.on('disconnect', () => {
			console.log('Socket disconnect!');
			setTimeout(() => {
				fetchChatMembers()
					.then(({ data }) => {
						dispatch(setUsersAction(data));
					});
			}, 1000);
		});

		socket.on('generalChatMessage', (data: MessageDto) => {
			const {
				firstName,
				lastName,
			} = data;
			const displayName = [firstName, lastName].join(' ');
			dispatch(addMessageAction(data.voterEventAccessId, displayName, data.message));
		});

		socket.on('clientJoin', (data) => {
			dispatch(addUserAction(data));
		});

		socket.on('clientLeave', (data) => {
			dispatch(removeUserAction(data));
		});

		socket.on('clientRaiseHand', (data) => {
			dispatch(raiseHandAction(data.userId));
			dispatch(showNewAlertAction({
				alertType: VideoConferenceAlertsEnum.USER_RAISE_HAND,
				userUid: data.userId,
			}));
		});

		socket.on('clientLowerHand', (data) => {
			dispatch(lowerHandAction(data.userId));
			dispatch(showNewAlertAction({
				alertType: VideoConferenceAlertsEnum.USER_LOWER_HAND,
				userUid: data.userId,
			}));
		});
	}, [socket]);

	if (!eventData && !details) {
		return <Layout><AuthorizeLoader /></Layout>;
	}

	return (
		<Layout>
			{(!isVideoChatTab || (isVideoChatTab && pageIndex !== 1) || isOnlyChat)
			&& <SubFooter chosePopup={navigateToMenuSubpage} />}
			<ContentWrapper
				// className={isAttendanceList ? 'isAttendanceList' : ''}
				isFooter={(isVideoChatTab && pageIndex === 1) && !isOnlyChat}
				setPageIndex={setPageIndex}
			>
				{pages[pageIndex]}
			</ContentWrapper>
			{roomConfig.video && streamingDataDefined && (
				<StreamingComponent
					pageIndex={pageIndex}
					setVideoChatIndex={setVideoChatIndex}
					videoChatIndex={videoChatIndex}
					width={width}
				/>
			)}
			<NavigationBar
				changePage={changePage}
				pageIndex={pageIndex}
			/>
			{details?.isPreview
			&& <PreviewBorder />}
		</Layout>
	);
};

export default Room;
export const pageQuery = graphql`
    query {
        markdownRemark(frontmatter: { slug: { eq: "terms" } }) {
            html
            frontmatter {
                slug
                title
                subtitle
            }
        }
    }
`;
