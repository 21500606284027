import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { theme } from '../../../../../contants/theme';
import { ActiveVotingButtonProps } from '../@types/activeVotingButtton.interface';
import { GlobalState } from '../../../../../state/@types/redux.interface';
import ModifiedButton from './ModifiedButton';
import VoteAsWrapper from './voteAs/VoteAsWrapper';

const Wrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: row;
  	width: auto;
	@media(max-width: 600px) {
		display: flex;
		flex-direction: column;
	}
`;

const StyledModifiedButton = styled(ModifiedButton)`
	white-space: nowrap;
	@media(max-width: 600px) {
		margin-bottom: 15px;
	}
	&.disabled {
		background-color: ${({ theme }) => theme.colors.lightGreyBackground};
		color: ${({ theme }) => theme.colors.fontLightGrey};
		cursor: pointer;
		:active {
			background-color: ${({ theme }) => theme.colors.mediumWhite};
			color: ${({ theme }) => theme.colors.lightPurple};
		}
	}
`;

const ActiveVotingButton: FunctionComponent<ActiveVotingButtonProps> = ({
	voting, handleVoteChangeClick, handleVoteClick,
}) => {
	const { members } = useSelector((state: GlobalState) => state.votingModule.proxy);
	const [attorneysDidVoted, setAttorneysDidVoted] = useState([]);
	const hasPrincip = useSelector((state: GlobalState) => state.votingModule.proxy.members);

	const getVoteAsActiveUserButton = () => {
		const didActiveUserVoted = voting?.didVoted[0]?.didVoted;
		if (voting.answersEditable && didActiveUserVoted) {
			return (
				<StyledModifiedButton
					color={theme.colors.yellow}
					onClick={handleVoteChangeClick}
					textColor={theme.colors.fontBlue}
				>
					{ members.length > 0 ? 'zmień swój głos' : 'zmień głos'}
				</StyledModifiedButton>
			);
		}

		return (
			<StyledModifiedButton
				className={(didActiveUserVoted || voting.isExcluded) ? 'disabled' : ''}
				color={(didActiveUserVoted || voting.isExcluded) ? '' : theme.colors.aqua}
				onClick={(!didActiveUserVoted && !voting.isExcluded) ? handleVoteClick : null}
				textColor={(didActiveUserVoted || voting.isExcluded) ? '' : theme.colors.fontBlue}
			>
				{ members.length > 0 ? 'oddaj głos jako Ty' : 'oddaj głos'}
			</StyledModifiedButton>
		);
	};

	useEffect(() => {
		if (voting.didVoted) {
			setAttorneysDidVoted([...voting.didVoted].splice(1, voting.didVoted.length - 1));
		}
	}, [voting.didVoted]);

	return (
		<Wrapper
			className={hasPrincip.length > 0 ? 'princip' : 'noPrincip'}
		>
			{getVoteAsActiveUserButton()}
			{(members.length > 0 && attorneysDidVoted.length > 0) && (
				<VoteAsWrapper
					didVoted={attorneysDidVoted}
					voting={voting}
				/>
			)}
		</Wrapper>
	);
};

export default ActiveVotingButton;
