import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Logo from '../../images/logo.svg';
import AgendaIcon from '../../images/room/agenda-icon.svg';
import FilesIcon from '../../images/room/directory-icon.svg';
import MenuIcon from '../../images/room/menu-icon.svg';
import VideoChatIcon from '../../images/room/videochat-icon.svg';
import AttendaceIcon from '../../images/room/attendace-icon.svg';
import { GlobalState } from '../../state/@types/redux.interface';
import { changeActiveMenuSubpage } from '../../state/menuTab.reducer';
import { NavigationBarProps } from './@types/navigationBar.interface';

const Wrapper = styled.div`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background-color: white;
  border-bottom: none;
  border-top: 3px solid rgba(73, 73, 131, 0.08);
  bottom: 0;
  display: flex;
  height: 58px;
  left: 0;
  position: fixed;
  transition: 1s all;
  width: 100vw;
  z-index: 99;
  @media (min-width: 1000px) {
    top: 0;
    border-bottom: 3px solid rgba(73, 73, 131, 0.08);
    border-top: none;
    z-index: 99999999;
  }
`;

const NavigationLogo = styled.img`
  display: none;
  @media (min-width: 1000px) {
    display: block;
    position: absolute;
    max-height: 50px;
    max-width: 120px;
    cursor: pointer;
    left: 40px;
  }
`;

const NavigationItemWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
  @media (max-width: 1000px) {
    justify-content: space-around;
  }
`;

const NavigationIcon = styled.img`
  @media (min-width: 1000px) {
    display: none;
  }
`;

const NavigationText = styled.div`
  margin-left: 10px;
  white-space: nowrap;
  @media (min-width: 1000px) {
    margin-left: 0;
    cursor: pointer;
  }
`;

const NavigationTextContent = styled.div`
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  word-wrap: break-word;
`;

const NavigationItem = styled.div`
  align-items: center;
  border: none;
  color: ${({ theme }) => theme.colors.fontBlue};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  position: relative;
  transition: 1s all;
  @media (min-width: 1000px) {
    margin: 0 30px;
  }
  @keyframes scaleAnimation {
    0% {
      transform: scale(0, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }

  &.border:after {
    background-color: ${({ theme }) => theme.colors.aqua};
    border-bottom: none;
    content: "";
    height: 3px;
    position: absolute;
    top: 55px;
    width: 140%;
    @media (max-width: 1000px) {
      top: unset;
      bottom: 55px;
    }
  }
`;

const MessageBuble = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.aqua};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.fontBlue};
  display: flex;
  font-size: 12px;
  height: 20px;
  justify-content: center;
  left: 15px;
  padding: 1px 0;
  position: absolute;
  text-align: center;
  top: 7px;
  width: 20px;
  &.wide {
	border-radius: 20px;
	width: 35px;
  }
  @media(min-width: 1000px) {
    top: 7px;
    left: 67px;
  }
`;

const NavigationBar: FunctionComponent<NavigationBarProps> = ({
	changePage,
	pageIndex,
	className,
}) => {
	const dispatch = useDispatch();
	const files = useSelector((state: GlobalState) => state.files.files);
	const roomConfig = useSelector((state: GlobalState) => state.roomConfig);
	const details = useSelector((state: GlobalState) => state.event.details);
	const customLogo = useSelector((state: GlobalState) => state.event.details?.logo);
	const unreadMessages = useSelector((state: GlobalState) => state.chat.unreadMessages);
	const isPreviewReally = details?.isPreview;
	const pages = [];

	if (details) {
		pages.push({
			src: AgendaIcon,
			name: details?.agenda?.length > 0 ? 'Porządek obrad' : 'Głosowania',
		});
	}
	if (roomConfig.video || roomConfig.chat) {
		pages.push({
			src: VideoChatIcon,
			name: roomConfig.video ? 'Videochat' : 'Chat',
		});
	}
	if (files?.length !== 0) {
		pages.push({
			src: FilesIcon,
			name: 'Pliki',
		});
	}
	if (!isPreviewReally && roomConfig.attendanceList) {
		pages.push({
			src: AttendaceIcon,
			name: 'Lista obecności',
		});
	}

	if (typeof window !== 'undefined') {
		if (window.innerWidth < 1000) {
			pages.push({
				src: MenuIcon,
				name: 'Menu',
			});
		}
	}
	const checkDesktop = () => {
		if (typeof window !== 'undefined') {
			return window.innerWidth > 1000;
		}
		return false;
	};
	const navigateToMenu = (i) => {
		dispatch(changeActiveMenuSubpage(null));
		changePage(i);
	};

	return (
		<Wrapper className={className}>
			<NavigationLogo
				onClick={() => changePage(0)}
				src={customLogo ? customLogo.url : Logo}
			/>
			<NavigationItemWrapper>
				{pages.map((page, i) => (
					<NavigationItem
						key={page.name}
						className={i === pageIndex ? 'border' : 'noBorder'}
						onClick={() => {
							navigateToMenu(i);
						}}
					>
						<NavigationIcon
							alt={page.name}
							src={page.src}
						/>
						{(roomConfig.chat && pageIndex !== i && i === 1 && unreadMessages > 0) && (
							<MessageBuble className={unreadMessages > 99 ? 'wide' : ''}>
								<p>
									{unreadMessages > 99 ? '99+' : unreadMessages}
								</p>
							</MessageBuble>
						)}
						{(i === pageIndex || checkDesktop()) && (
							<NavigationText>
								<NavigationTextContent>
									{page.name}
								</NavigationTextContent>
							</NavigationText>
						)}
					</NavigationItem>
				))}
			</NavigationItemWrapper>
		</Wrapper>
	);
};

export default NavigationBar;
