import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { checkIsAfter, getCountdown } from '../../../../_utils/date';
import { fetchEventDetailsAction } from '../../../../state/event.reducer';
import { NotStartedProps } from './@types/notStarted.interface';

const StyledText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 1rem;
	line-height: 1.4rem;
	text-align: center;
`;

const StyledTimerWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
	@media(min-width: 1000px) {
		margin-top: 50px;
	}
`;

const StyledTimer = styled.div`
	border: 2px solid ${({ theme }) => theme.colors.aqua};
	border-radius: 32px;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: inline-block;
	font-size: 17px;
	font-weight: bold;
	line-height: 31px;
	padding: 0 16px;
`;
const HeaderNotStarted = styled.span`
  color: ${({ theme }) => theme.colors.fontBlue};
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 20px;
`;
const NotStartedContainer = styled.div`
	align-items: center;
  	background-color: ${({ theme }) => theme.colors.white};
  	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
  	border-radius: 20px;
  	display: flex;
  	flex-direction: column;
  	justify-content: center;
  	padding: 100px 0;
  	text-align: center;
  	@media(max-width: 999px) {
	  padding: 60px 14px;
	}
`;

let interval = null;

const NotStarted: FunctionComponent<NotStartedProps> = ({ name, startAt }) => {
	const dispatch = useDispatch();
	const [countdown, setCountdown] = useState(null);
	const text = name ? name[0].toUpperCase() + name.slice(1) : '';

	useEffect(() => {
		interval = setInterval(() => {
			if (checkIsAfter(moment(startAt))) {
				dispatch(fetchEventDetailsAction());
			}
			setCountdown(getCountdown(startAt));
		}, 500);
		return () => {
			clearInterval(interval);
			interval = null;
		};
	}, []);

	if (startAt === null) {
		return (
			<StyledText>
				{text}
				{' '}
				jest obecnie w trybie oczekiwania na uruchomienie
			</StyledText>
		);
	}

	return (

		<NotStartedContainer>
			<HeaderNotStarted>
				Wydarzenie jest w trakcie oczekiwania na uruchomienie.
			</HeaderNotStarted>
			{' '}
			Czas pozostały do rozpoczęcia wydarzenia:
			<StyledTimerWrapper>
				<StyledTimer>
					{countdown}
				</StyledTimer>
			</StyledTimerWrapper>
		</NotStartedContainer>
	);
};

export default NotStarted;
