import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../state/@types/redux.interface';
import PageHeader from '../../common/page-header/PageHeader';
import AttendanceImg from '../../../images/list aobecności.svg';
import ObserverTypeIcon from '../../../images/attendence-list/observerTypeIcon';
import CommonUserIcon from '../../../images/attendence-list/commonUserIcon';
import ProxyTypeIcon from '../../../images/attendence-list/ProxyIconType';
import Stain1 from '../../../images/stain1.svg';
import Stain2 from '../../../images/stain2.svg';
import { StyledAgendaDetails, BgImageHolder, Wrapper } from '../agenda/agendaDetails';
import PresentAbsentUser from './PresentAbsentUser';

const HeadingList = styled.h2`
	color: #07064E;
	font-size: 13px;
	font-weight: bold;
	height: 18px;
	margin-bottom: 8px;
	margin-left: 13px;
	margin-top: 24px; 
	
	@media (min-width: 400px) {
		margin-bottom: 15px;
		margin-top: 12px;
	}
	@media (max-width: 400px) {
		margin-bottom: 20px;
	}
	
	@media (max-width: 800px) {
		margin-top: 40px;
	}
`;
const ListGroupLine = styled.div`
  background-color: #EFEFF4;
  height: 2px;
  padding: 1px;
  width: 101%;
  @media (max-width: 700px) {
		max-width: 98%;
		margin-left: 1%;
	}
  
`;

const UsersAmountIcon = styled.span`
	font-size: 13px;
	margin-left: 5px;
`;
const ContentAttendance = styled.div`
    background-color: #FFF;
	border: 2px solid #EBEBEB;
	border-radius: 20px;
    max-width: 1000px;
    padding: 25px;
    position: relative;
    width: 100%;
	@media(max-width: 599px) {
		margin: 0;
		padding: 26px 15px 30px;
	}
	@media (min-width: 600px) and (max-width: 1000px) {
		margin: 0 5% 22%;
	}
`;
const StyledPageHeader = styled(PageHeader)`
	max-width: 100%; 
	
`;
const LegendType = styled.div`
	color: #07064E;
	display: flex;
	flex-direction: row;
	float: right;
	font-size: 11px;
`;

const StyledType = styled.div`
	align-items: center;
	display: flex; 
	justify-content: center;
	margin: 0;
	width: 110px;
	&.observer {
		margin-left: -10px;
	}
	@media (max-width: 400px) {
		margin-right: 0;
		width: 75px;
		position: relative;
		justify-content: center;
		&.ordinary {
			margin-right: 5px;
			width: 85px;
		}
		&.observer {
			margin-left: 0px;
		}
	}
	
`;
const TextTypeIcon = styled.div`
	padding-right: 5%;
	@media (max-width: 400px) {
		position: absolute;
		top: 15px;
		padding-right: 0;
	}
`;

const AttendanceMain = () => {
	const usersList = useSelector((state: GlobalState) => state.chat.voters);
	const usersAmount = useSelector((state: GlobalState) => state.chat.users).length;
	const { details } = useSelector((state: GlobalState) => state.event);
	const activeUsers = useSelector((state: GlobalState) => state.chat.users);

	const Absent = Array.from(usersList);
	for (let i = Absent.length - 1; i >= 0; i--) {
		for (let j = 0; j < activeUsers.length; j++) {
			if (Absent[i] && (Absent[i].id === activeUsers[j].voterEventAccessId)) {
				Absent.splice(i, 1);
			}
		}
	}

	const showAmountUsers = Absent.length;
	const Present = activeUsers.map((user) => usersList.find((activeUser) => activeUser.id === user.voterEventAccessId));

	return (
		<StyledAgendaDetails Stain1={Stain1}>
			<BgImageHolder Stain2={Stain2}>
				<Wrapper>
					<StyledPageHeader
						img={AttendanceImg}
						text={details?.name}
						title="Lista obecności"
					/>

					<ContentAttendance>
						<LegendType>
							<StyledType className="ordinary">
								<TextTypeIcon> zwykły uczestnik </TextTypeIcon>
								<CommonUserIcon
									size="14"
									type="present"
								/>

							</StyledType>
							<StyledType>
								<TextTypeIcon> pełnomocnik </TextTypeIcon>
								<ProxyTypeIcon
									size="16"
									type="present"
								/>

							</StyledType>
							<StyledType className="observer">
								<TextTypeIcon> obserwator </TextTypeIcon>
								<ObserverTypeIcon
									size="16"
									type="present"
								/>

							</StyledType>
						</LegendType>

						<HeadingList>
							Uczestnicy, którzy dołączyli do wydarzenia
							<UsersAmountIcon>
								(
								{usersAmount}
								)
							</UsersAmountIcon>
						</HeadingList>
						<ListGroupLine />
						{Present.length ? Present.map((user) =>
							(
								<PresentAbsentUser
									key={user.id}
									firstName={user.voter.firstName}
									hasAttorney={user.hasAttorney}
									isPresent
									lastName={user.voter.lastName}
									role={user.role.canVote}
									userId={user.id}
								/>
							)) : null}
						<HeadingList>
							Uczestnicy, którzy nie dołączyli do wydarzenia
							(
							{showAmountUsers}
							)
						</HeadingList>
						<ListGroupLine />

						{
							Absent.length ? Absent.map((user) => (
								<PresentAbsentUser
									key={user.id}
									firstName={user.voter.firstName}
									hasAttorney={user.hasAttorney}
									isPresent={false}
									lastName={user.voter.lastName}
									role={user.role.canVote}
									userId={user.id}
								/>
							)) : null
						}
					</ContentAttendance>
				</Wrapper>
			</BgImageHolder>
		</StyledAgendaDetails>
	);
};

export default AttendanceMain;
