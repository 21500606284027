import * as React from 'react';
import { FunctionComponent } from 'react';

/* eslint-disable */
interface IVideoMobileMenuIcon {
	size: number;
	fill: string;
}
const VideoMobileMenuIcon: FunctionComponent<IVideoMobileMenuIcon> = ({size, fill}) => (
	<svg
		height={size}
		viewBox="0 0 28 28"
		width={size}
		xmlns="http://www.w3.org/2000/svg"
	>
		<g data-name="Group 6902">
			<path
				d="M0 0h28v28H0z"
				data-name="Rectangle 3764"
				fill="none"
			/>
			<path
				d="M25.314 7.093h-.032a.8.8 0 00-.605.238l-4.688 4.688V8.027a3.169 3.169 0 00-3.165-3.165H4.558a3.17 3.17 0 00-3.165 3.165v12.272a3.17 3.17 0 003.165 3.165h12.271a3.169 3.169 0 003.165-3.165v-4.478l4.688 4.688a.814.814 0 00.573.238h.065a.809.809 0 00.81-.81V7.908a.818.818 0 00-.816-.815zm-4.926 6.827l4.121-4.122v8.243zm-2.015 6.379a1.545 1.545 0 01-1.545 1.545H4.557a1.545 1.545 0 01-1.545-1.545V8.027a1.544 1.544 0 011.545-1.544h12.271a1.544 1.544 0 011.545 1.544v12.272z"
				data-name="Path 77447"
				fill={fill}
			/>
		</g>
	</svg>
);

export default VideoMobileMenuIcon;
