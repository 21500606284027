import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ScoreboardProps } from '../@types/scoreboard.interface';

const BarWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.greyBar};
	display: flex;
	flex-direction: row;
	font-size: 12px;
	padding: 20px;
	@media(min-width: 700px) {
		margin: 0 25px;
		border-radius: 10px;
		font-size: 14px;
	}
`;
const BarHeader = styled.div``;
const QuestionNumber = styled.span`
	font-weight: bold;
	margin-right: 5px;
`;
const AnswerDetailsWrapper = styled.div`
  padding: 0 20px;
  @media(min-width: 1000px) {
		padding: 0 40px;
  }
`;
const AnswerCountInfo = styled.span`
	@media(max-width: 600px) {
	  text-align: end;
	}
`;
const AnswerDetails = styled.div`
	border-bottom: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
	color: ${({ theme }) => theme.colors.agendaDarkGrey};
	display: flex;
	flex-direction: row;
	font-size: 12px;
	font-weight: 600;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 6px 20px;
	width: 100%;
`;
const ScoreboardScoreBar: FunctionComponent<ScoreboardProps> = ({ barText, barNumber, isPoint }) => {
	const handleInfoByScreenSize = () => {
		if (typeof window !== 'undefined' && window?.screen?.width < 1000) {
			return ' głosów (w %)';
		}
		return ' głosów (% oddanych)';
	};

	return (
		<>
			<BarWrapper>
				<QuestionNumber>
					{barNumber}
					.
				</QuestionNumber>
				<BarHeader>{barText}</BarHeader>
			</BarWrapper>
			<AnswerDetailsWrapper>
				<AnswerDetails>
					<span>
						Odpowiedź
					</span>
					<AnswerCountInfo>
						Liczba
						{isPoint ? ' punktów' : handleInfoByScreenSize()}
					</AnswerCountInfo>
				</AnswerDetails>
			</AnswerDetailsWrapper>
		</>
	);
};
export default ScoreboardScoreBar;
