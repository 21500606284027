import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ScrBoardIcon from '../../../../images/resultBorderIcon.svg';
import { ScoreboardAnswerProps } from '../@types/scoreboardAnswer.interface';

const AnswerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 6px 20px;
	@media(min-width: 700px) {
		padding: 20px 40px;
	}
`;
const AnswerBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	&.border {
		background-color: ${({ theme }) => theme.colors.dirtyAqua};
		border: 2px solid ${({ theme }) => theme.colors.aqua};
		border-radius: 7px;
		padding: 13px 20px;
	}
	&.noBorder {
		padding: 0 20px;
	}
`;
const AnswerText = styled.span`
	max-width: 80%;
`;
const AnswerCount = styled.span`
	font-size: 12px;
	font-weight: bold;
`;
const BorderIcon = styled.img`
	margin-top: -22px;
	position: absolute;
	&.noBorder {
		display: none;
	}
`;
const ScoreboardAnswer: FunctionComponent<ScoreboardAnswerProps> = ({
	answer, answersCount, isMost, totalAnswers,
}) => {
	const percentAnswerPoints = (answersCount / totalAnswers) * 100;
	const roundedNum = Math.round(percentAnswerPoints * 100) / 100;
	return (
		<AnswerWrapper>
			<AnswerBar className={isMost ? 'border' : 'noBorder'}>
				<AnswerText>
					<BorderIcon
						className={isMost ? 'border' : 'noBorder'}
						src={ScrBoardIcon}
					/>
					{answer}
					:
				</AnswerText>
				<AnswerCount>
					{answersCount}
					{' '}
					{percentAnswerPoints > 0 && (
						<span>
							(
							{roundedNum}
							%)
						</span>
					)}
				</AnswerCount>
			</AnswerBar>
		</AnswerWrapper>
	);
};
export default ScoreboardAnswer;
