import * as React from 'react';

/* eslint-disable */
function SpeakingIcon(member) {
	return (
		<svg
			style={{marginRight: member ? 5 : 0}}
			height={8.9}
			viewBox="0 0 8.418 8.9"
			width={8.418}
			xmlns="http://www.w3.org/2000/svg"
		>
			<g fill="#2bf5ba">
				<path
					d="M7.406 1.535a.993.993 0 00-1.013.972v3.887a1.013 1.013 0 002.025 0V2.503a.993.993 0 00-1.012-.968zm0 0"
					data-name="Path 77301"
				/>
				<path
					d="M4.209 0a.993.993 0 00-1.013.972v6.956a1.013 1.013 0 002.025 0V.968A.993.993 0 004.209 0zm0 0"
					data-name="Path 77428"
				/>
				<path
					d="M1.012 1.535a.993.993 0 00-1.013.972v3.887a1.013 1.013 0 002.025 0V2.503a.993.993 0 00-1.012-.968zm0 0"
					data-name="Path 77429"
				/>
			</g>
		</svg>
	);
}
export default SpeakingIcon;
