import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../contants/theme';
import { AvatarBoxProps } from './@types/avatarBox.interface';

const StyledCircle = styled.div`
	align-items: center;
	background-color: ${({ isMe }) => (isMe ? theme.colors.aqua : theme.colors.paleBlue)};
	border-radius: 50%;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 16px;
	font-weight: bolder;
	height: ${({ size }) => (size ? `${size}px` : '35px')};
	justify-content: center;
	margin-right: 10px;
	position: relative;
  	user-select: none;
  	width: ${({ size }) => (size ? `${size}px` : '35px')};
	@media(min-width: 1000px) {
		display: none;
	}
	&.initials {
		@media(min-width: 1000px) {
		display: block;
		background: ${({ isMe }) => (isMe ? theme.colors.aqua : theme.colors.paleBlue)};
		float: left;
		height: 29px;
		line-height: 29px;
		margin-right: 13px;
		margin-top: -7px;
		text-align: center;
		width: 30px;
		}
	}
	&.initials-absent {
	    background: #EBEBEB;
	    color: #B4B4B4;
		@media(min-width: 1000px) {
		float: left;
		height: 29px;
		line-height: 29px;
		margin-right: 13px;
		margin-top: -7px;
		text-align: center;
		width: 30px;
		display: block;
	}
	}
`;

const AvatarBox: FunctionComponent<AvatarBoxProps> = ({
	name, size, isMe, className,
}) => (
	<StyledCircle
		className={className}
		isMe={isMe}
		size={size}
	>
		<p>{name}</p>
	</StyledCircle>
);

export default AvatarBox;
