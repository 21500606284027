import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Logo from '../../images/logo.svg';
import SubpageHeader from '../common/subpage-header/SubpageHeader';
import { ObjectionFormProps } from './@types/objectionForm.interface';

const ObjectionWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	color: ${({ theme }) => theme.colors.fontBlue};
	height: 100%;
	left: 0;
	overflow-y: auto;
	position: absolute;
	top: 0;
	width: 100%;
`;
const MobileLogoWrapper = styled.div`
	align-items: center;
	display: flex;
	height: 54px;
	justify-content: center;
	position: sticky;
	width: 100%;
	@media(min-width: 700px) {
		height: 63px;
	} 
	@media(min-width: 1000px) {
		height: 70px;
	}
`;
const MobileLogo = styled.img`
	height: 19px;
	width: 80px;
	@media(min-width: 700px) {
		width: 120px;
		height: 24px;
	} 
	@media(min-width: 1000px) {
		display: none;
	}
`;
const ObjectionBottom = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	z-index: 99999;
	@media(min-width: 1000px) {
		width: 75%;
		margin: auto;
	}
`;
const ObjectionTitle = styled.span`
	font-size: 0.875rem;
	font-weight: 600;
	margin-top: 3.125rem;
	text-align: center;
`;
const ObjectionSubTitle = styled.span`
	font-size: 0.875rem;
	margin-bottom: 1.875rem;
	margin-top: 10px;
	text-align: center;
	@media(min-width: 700px) {
		margin-bottom: 60px;
	}
`;
const ObjectionMessage = styled.textarea`
	background-color: ${({ theme }) => theme.colors.white};
	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 18px;
	color: ${({ theme }) => theme.colors.agendaGrey};
	display: flex;
	font-size: 0.75rem;
	font-weight: 700;
	height: 220px;
	margin-bottom: 28px;
	outline: none;
	padding: 15px 20px;
	resize: none;
	width: 100%;
	z-index: 99999;
	@media(min-width: 700px) {
		height: 114px;
		width: 100%;
	}
	@media(min-width: 1000px) {
		height: 120px;
		align-self: center;
		margin-bottom: 28px;
		width: 800px;
	}
	&:focus {
		background-color: ${({ theme }) => theme.colors.dirtyAqua};
		border: 2px solid ${({ theme }) => theme.colors.aqua};
		color: ${({ theme }) => theme.colors.fontBlue};  
	}
`;
const TextAreaWrapper = styled.div`
	margin: 0 auto;
	width: 96%;
	@media(min-width: 700px) {
		width: 96%;
	}
	@media(min-width: 1000px) {
		width: 800px;
		margin: initial;
		align-self: center;
	}
`;
const ButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;
const ObjectionButton = styled.button`
	background-color: ${({ theme }) => theme.colors.lightRed};
	border: none;
	border-radius: 18px;
	color:  ${({ theme }) => theme.colors.darkRed};
	font-size: 0.75rem;
	font-weight: 700;
	margin-right: 20px;
	outline: none;
	padding: 9px 22px;
	text-align: center;
`;
const BackButton = styled.button`
	background-color: ${({ theme }) => theme.colors.fontBlue};
	border: none;
	border-radius: 18px;
	color: ${({ theme }) => theme.colors.aqua};
	font-size: 0.75rem;
	font-weight: 700;
	outline: none;
	padding: 9px 22px;
	text-align: center;
`;

const ObjectionForm: FunctionComponent<ObjectionFormProps> = ({ setObjectionPopupOpen }) => {
	const navigateToAgenda = () => {
		setObjectionPopupOpen(false);
	};
	return (
		<ObjectionWrapper>
			<MobileLogoWrapper>
				<MobileLogo src={Logo} />
			</MobileLogoWrapper>
			<SubpageHeader
				closePopup={() => setObjectionPopupOpen(false)}
				title="Zgłoś sprzeciw"
			/>
			<ObjectionBottom>
				<ObjectionTitle>Zgłaszasz sprzeciw</ObjectionTitle>
				<ObjectionSubTitle> w Głosowaniu 999 punktu 999 wydarzenia</ObjectionSubTitle>
				<TextAreaWrapper>
					<ObjectionMessage placeholder="Uzasadnienie sprzeciwu" />
					<ButtonsWrapper>
						<ObjectionButton>zgłos sprzeciw</ObjectionButton>
						<BackButton onClick={navigateToAgenda}>anuluj</BackButton>
					</ButtonsWrapper>
				</TextAreaWrapper>
			</ObjectionBottom>
		</ObjectionWrapper>
	);
};
export default ObjectionForm;
