import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import ChatSvg from '../../../icons/ChatIcon.svg';
import ListSvg from '../../../icons/ListIcon.svg';
import { theme } from '../../../contants/theme';
import { GlobalState } from '../../../state/@types/redux.interface';
import VideoMobileMenuIcon from '../../../images/room/streaming/VideoMobileMenuIcon';
import checkIfWindowExist from '../../../_utils/windowUndefined';
import ChatComponent from './chat/ChatComponent';
import ListOfMembers from './listOfMembers/ListOfMembers';

const VideoChatNavigationWrapper = styled.div`
	background-color: #F8F8F8;
 	display: flex;
  	flex-direction: column;
  	width: 320px;
  	@media (max-width: 999px) {
    	width: 100%;
  	}
	@media(min-width: 1000px) {
		overflow: hidden;
	}
	&.onlyChat {
		width: 100%;
	}
`;
const NavigationWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	border-bottom: 2px solid ${({ theme }) => theme.colors.greyBackground};
	display: flex;
	font-weight: bold;
	justify-content: space-evenly;
	min-height: 52px;
	width: 100%;
	z-index: 5;
`;

const NavigationChatTabWrapper = styled.div`
	cursor: pointer;
	position: relative;
`;

const ChatCircle = styled.div`
	align-items: center;
	background-color: ${({ isActive }) => (isActive ? theme.colors.blueBackground : theme.colors.aqua)};
	border-radius: 50%;
	color: ${({ isActive }) => (isActive ? theme.colors.aqua : theme.colors.fontBlue)};
	display: flex;
	font-size: 14px;
	height: 18px;
	justify-content: center;
	position: absolute;
	right: -11px;
	top: -2px;
	width: 18px;
	&.wide {
		border-radius: 20px;
		left: 12px;
        top: -6px;
        width: 35px;
	}
`;

const ContentWrapper = styled.div`
	height: 100%;
	overflow: hidden;
	width: 100%;
`;
interface IVideoChatNavigation {
	width: number;
	videoChatIndex: number;
	className: string;
	setVideoChatIndex: React.Dispatch<React.SetStateAction<number>>
}

const StreamingVideoChatWrapper = loadable(() => import('./streaming/StreamingVideoChatWrapper'));

const VideoChatNavigation: FunctionComponent<IVideoChatNavigation> = ({
	width, videoChatIndex, setVideoChatIndex, className,
}) => {
	const roomConfig = useSelector((state: GlobalState) => state.roomConfig);
	const unreadMessages = useSelector((state: GlobalState) => state.chat.unreadMessages);
	const usersAmount = useSelector((state: GlobalState) => state.chat.users).length;
	const pages = [];

	const openChat = () => {
		setVideoChatIndex(1);
	};
	const openListOfMembers = () => {
		setVideoChatIndex(0);
		checkIfWindowExist(() => window.scrollTo(0, 0));
	};

	if (roomConfig.video || roomConfig.chat) {
		pages.push(<ListOfMembers />);
	}
	if (roomConfig.chat) {
		pages.push(<ChatComponent className={className} />);
	}
	if (width < 1000 && roomConfig.video) {
		pages.push(
			<StreamingVideoChatWrapper />,
		);
	}
	return (
		<VideoChatNavigationWrapper
			className={className}
		>
			<NavigationWrapper className={className}>
				{width < 1000 && roomConfig.video && (
					<NavigationChatTabWrapper onClick={() => setVideoChatIndex(2)}>
						<VideoMobileMenuIcon
							fill={videoChatIndex === 2 ? '#2BF5BA' : '#07064E'}
							size={28}
						/>
					</NavigationChatTabWrapper>
				)}
				<NavigationChatTabWrapper
					onClick={() => openListOfMembers()}
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ListSvg fill={videoChatIndex === 0 ? theme.colors.aqua : theme.colors.blueBackground} />
					<span
						style={{
							marginLeft: 5,
							fontSize: 15,
							color: videoChatIndex === 0 ? theme.colors.aqua : theme.colors.blueBackground,
						}}
					>
						(
						{usersAmount}
						)
					</span>
				</NavigationChatTabWrapper>
				{roomConfig.chat ? (
					<NavigationChatTabWrapper onClick={() => openChat()}>
						<ChatSvg fill={videoChatIndex === 1 ? theme.colors.aqua : theme.colors.blueBackground} />
						{(unreadMessages > 0 && videoChatIndex !== 1) && (
							<ChatCircle
								className={unreadMessages > 99 ? 'wide' : ''}
								isActive={videoChatIndex === 1}
							>
								<p>
									{unreadMessages > 99 ? '99+' : unreadMessages}
								</p>
							</ChatCircle>
						)}
					</NavigationChatTabWrapper>
				) : null}
			</NavigationWrapper>
			<ContentWrapper>{pages[videoChatIndex]}</ContentWrapper>
		</VideoChatNavigationWrapper>
	);
};

export default VideoChatNavigation;
