import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import PageHeader from '../../../../common/page-header/PageHeader';

const Wrapper = styled.div`
	margin: 100px 20px 0 20px;
	
	@media (max-width: 999px) {
		margin: 100px 20px 0 24px;
		display: flex;
		align-self: start;
	}
  	@media(min-width: 1000px) {
		align-self: baseline;
	}
`;

type TopWrapperProps = {
	text: string;
	title: string;
	img: string;
	className: string;
}

const TopWrapper: FunctionComponent<TopWrapperProps> = ({
	title, text, img, className,
}) => (
	<Wrapper className={className}>
		<PageHeader
			img={img}
			isLogoOnMobile
			text={text}
			title={title}
		/>
	</Wrapper>
);

export default TopWrapper;
