import Moment from 'moment';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../contants/theme';
import { AgendaDateProps } from './@types/agendaDate.interface';

const Wrapper = styled.div`
  	align-items: center;
  	background-color: ${({ isActive }) => (isActive ? theme.colors.aqua : '#F8F8F8')};
  	border-bottom: ${({ isActive }) => (isActive ? 'none' : `2px solid ${theme.colors.lightGreyBackground}`)};
  	color: ${({ isActive }) => (isActive ? theme.colors.fontBlue : '#05CBA6')};
	cursor: pointer;
	display: flex;
	flex-direction: column;
	font-size: 12px;
	height: 50px;
	justify-content: center;
	padding: 10px 10px;
	text-align: center;
  	${({ numberOfItems }) => (
		() => {
			if (numberOfItems === 1) return 'width: 100%;';
			if (numberOfItems === 2) return 'width: 50%;';
			return 'width: 33.333333333333333%;';
		})};
	@media(min-width: 1000px) {
		display: flex;
		flex-direction: row;
		height: 60px;
		padding: 20px 30px;
		justify-content: center;
		font-size: 14px;
	}
`;

const Date = styled.p`
	font-weight: 800;
  	@media (min-width: 1000px) {
    	margin-right: 10px;
  	}
`;

const TimeAgenda = styled.p`
	font-weight: 500;
`;

const AgendaDate: FunctionComponent<AgendaDateProps> = ({
	date, startAt, endAt, isActive, setActive, numberOfItems,
}) => (
	<Wrapper
		className="agendaDateBorderRadius"
		isActive={isActive && numberOfItems > 1}
		numberOfItems={numberOfItems}
		onClick={() => setActive(date)}
	>
		<Date>{Moment(date).format('DD MMM YYYY')}</Date>
		<TimeAgenda>
			{startAt}
			-
			{endAt}
		</TimeAgenda>
	</Wrapper>
);

export default AgendaDate;
