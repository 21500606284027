import styled from 'styled-components';
import React, { FunctionComponent } from 'react';
import closeIcon from '../../../../../images/voting/closeIcon.svg';

const PopupWrapper = styled.div`
	align-items: center;
  	background-color: #FF7B7B;
  	border-radius: 0 0 20px 20px;
  	color: #FFF;
  	display: flex;
  	justify-content: center;
  	padding: 10px 20px 20px;
  	position: fixed;
  	top: -100px;
  	transition: all .5s;
  	width: 100%;
  	z-index: 9999999999999;
    &.isOpen {
	  top: 0;
	}
`;
const PopupText = styled.span`
	font-size: 12px;
  	font-weight: 500;
  	margin-right: 10px;
`;
const CloseButton = styled.img`
	font-size: 19px;
  	padding-right: 20px;
  	@media(min-width: 1000px) {
	  margin-left: 50px;
	}
`;
interface VotingPopupProps {
	onClick: () => void;
	isPopupOpen: boolean;
}

const VotingPopupMain: FunctionComponent<VotingPopupProps> = ({
	isPopupOpen,
	onClick,
}) => (
	<PopupWrapper
		className={isPopupOpen && 'isOpen'}
		onClick={onClick}
	>
		<PopupText>
			Wszystkie dostępne punkty zostały wykorzystane.
			Możesz rozdysponować punkty inaczej odejmując je z poszczególnych odpowiedzi.
		</PopupText>
		<CloseButton
			src={closeIcon}
		/>
	</PopupWrapper>
);
export default VotingPopupMain;
