import { saveAs } from 'file-saver';
import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import styled from 'styled-components';
import DirectorySvg from '../../../../images/room/directory-icon.svg';
import FileSvg from '../../../../images/room/files/file.svg';
import MagnifierSvg from '../../../../images/room/files/magnifier-icon.svg';
import { showFile } from '../../../../state/files.reducer';
import BasicCheckbox from '../../../common/basic-checkbox/BasicCheckbox';
import { FileItemProps } from '../@types/fileItem.interface';

const Wrapper = styled.div`
	display: flex;
	padding: 20px;
	@media(min-width: 700px) {
		padding: 40px 20px;
	}
	&:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	}
`;

const DetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	& div {
		display: flex;
	}
	@media(min-width: 700px) {
		position: relative;
		flex-direction: row;
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
`;

export const AquaLabel = styled.p`
	color: ${({ theme }) => theme.colors.aqua};
	font-size: 0.75rem;
	font-weight: bold;
`;
const FileName = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	font-weight: bold;
	margin-left: 10px;
	max-width: ${({ isMobileOnly }) => (isMobileOnly ? '190px' : '')};
	overflow-wrap: ${({ isMobileOnly }) => (isMobileOnly ? 'break-word' : '')};
`;

const ButtonsWrapper = styled.div`
	margin-top: 15px;
	padding-left: 10px;
	@media(min-width: 700px) {
		margin-top: 0;
	}
`;

export const DownloadButton = styled.button`
	background-color: ${({ theme }) => theme.colors.white};
	border: none;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	margin-left: 20px;
	outline: none;
`;

export const DownloadIcon = styled.img`
	margin-right: 10px;
`;

const LookButton = styled.button`
	background-color: ${({ theme }) => theme.colors.white};
	border: none;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	margin-left: 20px;
	outline: none;
`;

const LookIcon = styled.img`
	margin-right: 10px;
`;

const FileIcon = styled.img`
	margin-left: 10px;
`;

const FileItem: FunctionComponent<FileItemProps> = ({ file, isChecked, onClick }) => {
	const dispatch = useDispatch();

	const onDownload = () => {
		saveAs(file.url, file.name);
	};

	return (
		<Wrapper>
			<BasicCheckbox
				isChecked={isChecked}
				onClick={onClick}
			/>
			<DetailsWrapper>
				<div>
					<FileIcon
						alt="file"
						height={15}
						src={DirectorySvg}
					/>
					<FileName isMobileOnly={isMobileOnly}>{file.name}</FileName>
				</div>
				<ButtonsWrapper>
					<DownloadButton
						onClick={onDownload}
						type="button"
					>
						<DownloadIcon
							alt="file"
							src={FileSvg}
						/>
						<AquaLabel>
							pobierz
						</AquaLabel>
					</DownloadButton>
					{(file.mimeType.includes('pdf') || file.mimeType.includes('image')) && (
						<LookButton
							onClick={() => dispatch(showFile(file))}
							type="button"
						>
							<LookIcon
								alt="magnifier"
								src={MagnifierSvg}
							/>
							<AquaLabel>zobacz</AquaLabel>
						</LookButton>
					)}
				</ButtonsWrapper>
			</DetailsWrapper>
		</Wrapper>
	);
};

export default FileItem;
