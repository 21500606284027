import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { VotingStatusEnum } from '../../../../_enums/votingStatus.enum';
import { GlobalState } from '../../../../state/@types/redux.interface';
import {
	chooseAttorneyIndexAction,
	setEditModeStatusAction,
	setVotingIdAction,
} from '../../../../state/votingModule.reducer';
import { checkIsAfter, getCountdown, getStatus } from '../../../../_utils/date';
import BasicTimer from '../../../common/basic-timer/BasicTimer';
import ObjectionForm from '../../../objection-form/objectionForm';
import Scoreboard from '../../scoreboard/scoreboardMain';
import Popup from '../../../common/popup/Popup';
import AbcLogo from '../../../../images/voting/abc-logo.svg';
import useWindowDimensions from '../../../../_utils/useWindowDimensions';
import checkIfWindowExist from '../../../../_utils/windowUndefined';
import ModalImage from '../../../../images/waittingForStart.svg';
import { setGlobalScrollHidden } from '../../../../_utils/globalScrollHidden';
import PopImg from '../../../../images/voting/voting-drawing.svg';
import { VotingItemProps } from './@types/votingItem.interface';
import ActiveVotingButton from './buttons/ActiveVotingButton';
import ObjectionVotingButton from './buttons/ObjectionVotingButton';
import ScoreVotingButton from './buttons/ScoreVotingButton';
import WaitingVotingButton from './buttons/WaitingVotingButton';

const VotingButtonsWrapper = styled.div`
	display: flex;
  	@media(max-width: 600px) {
    	display: flex;
    	&.princip {
			flex-direction: column;
			align-items: flex-start;
    	}
    	&.noPrincip {
			flex-direction: row;
    	}
  	}
	margin-top: 20px;
  	white-space: nowrap;
`;

const Wrapper = styled.div`
	border-top: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
	margin-left: 5px;
	margin-right: 15px;
	padding: 19px 0 30px;
`;

const PopupWrapper = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	flex-direction: column; 
	font-size: 14px;
	justify-content: center;
	text-align: center;
	width: 100%;
`;

const PopupLabel = styled.p`
	font-weight: bold;
	margin-bottom: 20px;
`;

const VotingStatus = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-weight: 700;
`;

const TimeLeft = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
`;
const StyledWaittingButton = styled(WaitingVotingButton)`
	@media(max-width: 600px) {
		margin-bottom: 15px;
	}
`;
const CenteredDiv = styled.div`
	align-items: center;
  	display: flex;
  	flex-direction: column;
  	justify-content: center;
`;
const ModalImg = styled.img`
	margin: 0 auto;
    margin-bottom: 15px;
  	width: 70%;
`;

let interval;

const VotingItem = ({ voting, isSingle, openPopup }: VotingItemProps) => {
	const [status, setStatus] = useState<VotingStatusEnum>();
	const [isManual, setIsManual] = useState<boolean>(false);
	const [time, setTime] = useState(null);
	const [isObjectionPopupOpen, setObjectionPopupOpen] = useState<boolean>(false);
	const [isScrBoardPopupOpen, setScrBoardPopupOpen] = useState<boolean>(false);
	const [votingPopupStatus, setVotingPopupStatus] = useState<boolean>(false);
	const { votingId } = useSelector((state: GlobalState) => state.votingModule);
	const hasPrincip = useSelector((state: GlobalState) => state.votingModule.proxy.members);
	const { isPreview } = useSelector((state: GlobalState) => state.event.details);
	const { canVote } = useSelector((state: GlobalState) => state.userPermissions);
	const dispatch = useDispatch();
	const { width } = useWindowDimensions();
	const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
	const handlePopupOpen = () => {
		setPopupOpen(true);
	};

	useEffect(() => {
		setGlobalScrollHidden(isPopupOpen);
	}, [isPopupOpen]);

	const setVotingStatus = () => {
		if (isPreview) {
			setStatus(VotingStatusEnum.IN_PROGRESS);
			setIsManual(true);
		} else if (!voting.startAt && !voting.endAt) {
			setStatus(VotingStatusEnum.WAITING);
			setIsManual(true);
		} else if (!!voting.startAt && !voting.endAt) {
			setStatus(VotingStatusEnum.IN_PROGRESS);
			setIsManual(true);
		} else {
			setIsManual(false);
			setStatus(getStatus(moment(voting.startAt), moment(voting.endAt)));
		}
	};
	useEffect(() => {
		interval = setInterval(() => {
			setTime(new Date().toString());
			if (checkIsAfter(moment(voting.endAt))) {
				setVotingStatus();
				clearInterval(interval);
			}
		}, 1000);
	}, [votingId, isPreview]);

	useEffect(() => {
		setVotingStatus();
	}, [time]);

	const getVotingStatus = () => {
		if (status === VotingStatusEnum.WAITING) {
			return 'Oczekujące';
		}
		if (status === VotingStatusEnum.DONE) {
			return 'Zakończone';
		}
		if (status === VotingStatusEnum.IN_PROGRESS) {
			return 'Aktywne';
		}
	};

	const getRemainingTime = () => {
		if (!isManual) {
			if (status === VotingStatusEnum.WAITING) {
				return (
					<span>
						{' '}
						- Pozostały czas:&nbsp;
						{getCountdown(voting.startAt)}
					</span>
				);
			}
			if (status === VotingStatusEnum.IN_PROGRESS) {
				return (
					<span>
						{' '}
						- Pozostały czas:&nbsp;
						{getCountdown(voting.endAt)}
					</span>
				);
			}
		}
	};

	const handleVoteClick = () => {
		checkIfWindowExist(() => window.scrollTo(0, 0));
		if (!voting.isExcluded) {
			if (status === VotingStatusEnum.IN_PROGRESS) {
				dispatch(chooseAttorneyIndexAction(-1));
				dispatch(setVotingIdAction(voting.id));
				dispatch(setEditModeStatusAction(false));
			}
		}
	};

	const handleWaitBtnClick = () => {
		openPopup(
			<PopupWrapper>
				<ModalImg src={ModalImage} />
				<PopupLabel>Głosowanie w oczekiwaniu na uruchomienie</PopupLabel>
				{isManual ? (
					<p>Głosowanie uruchamia administrator wydarzenia</p>
				) : (
					<CenteredDiv>
						<p style={{ marginBottom: 10 }}>pozostało:</p>
						<BasicTimer date={voting.startAt} />
					</CenteredDiv>
				)}
			</PopupWrapper>,
		);
	};

	const handleObjectionPopup = () => {
		setObjectionPopupOpen(isObjectionPopupOpen);
	};
	const handleScrBoardPopup = () => {
		setScrBoardPopupOpen(!isScrBoardPopupOpen);
	};

	const handleVoteChangeClick = () => {
		checkIfWindowExist(() => window.scrollTo(0, 0));
		if (status === VotingStatusEnum.IN_PROGRESS) {
			dispatch(chooseAttorneyIndexAction(-1));
			dispatch(setVotingIdAction(voting.id));
			dispatch(setEditModeStatusAction(true));
		}
	};

	return (
		<Wrapper>
			{(status === VotingStatusEnum.IN_PROGRESS || status === VotingStatusEnum.WAITING) && isPopupOpen && (
				<Popup
					content="Podgląd wyników jest niemożliwy aż do zakończenia głosowania."
					img={PopImg}
					setPopupStatus={setPopupOpen}
					title=""
				/>
			)}
			<VotingStatus>
				{isSingle ? 'Głosowanie' : `${voting.name} - `}
				{!isSingle && getVotingStatus()}
			</VotingStatus>
			{!isSingle ? (
				<TimeLeft>{getRemainingTime()}</TimeLeft>
			) : (
				<TimeLeft>
					{getVotingStatus()}
					{getRemainingTime()}
				</TimeLeft>
			)}
			{ canVote && (
				<VotingButtonsWrapper className={hasPrincip.length > 0 ? 'princip' : 'noPrincip'}>
					{status === VotingStatusEnum.IN_PROGRESS
				&& (
					<ActiveVotingButton
						className={voting.isExcluded ? 'disabled' : ''}
						handleScrBoardPopup={handleScrBoardPopup}
						handleVoteChangeClick={handleVoteChangeClick}
						handleVoteClick={() => setVotingPopupStatus(true)}
						voting={voting}
						votingStatus={status}
					/>
				)}
					{status === VotingStatusEnum.WAITING && (
						<StyledWaittingButton handleWaitBtnClick={handleWaitBtnClick} />
					)}
					{ voting.scoreboard && width < 601 && (
						<ScoreVotingButton
							className={VotingStatusEnum.DONE ? '' : 'disabled'}
							handlePopupOpen={handlePopupOpen}
							handleScrBoardPopup={handleScrBoardPopup}
							votingStatus={status}
						/>
					)}
					{ (voting.scoreboard && width >= 601 && hasPrincip.length === 0) && (
						<ScoreVotingButton
							className={VotingStatusEnum.DONE ? '' : 'disabled'}
							handlePopupOpen={handlePopupOpen}
							handleScrBoardPopup={handleScrBoardPopup}
							votingStatus={status}
						/>
					)}
					{/* TODO: OBJECTION ON TRUE */}
					{(status === VotingStatusEnum.IN_PROGRESS && false) && (
						<ObjectionVotingButton
							handleObjectionPopup={handleObjectionPopup}
							votingStatus={status}
						/>
					)}
				</VotingButtonsWrapper>
			)}

			{isObjectionPopupOpen && (
				<ObjectionForm setObjectionPopupOpen={setObjectionPopupOpen} />
			)}
			{isScrBoardPopupOpen && (
				<Scoreboard
					setScrBoardPopupOpen={setScrBoardPopupOpen}
					votingId={voting.id}
				/>
			)}

			{votingPopupStatus && (
				<Popup
					content={!voting.answersEditable ? 'Oznacza to oddanie przez Ciebie '
						+ 'przysługujących Ci głosów, które zostaną '
						+ 'zapisane w wynikach. \n'
						+ 'Nie będziesz miał możliwości powrotu '
						// eslint-disable-next-line max-len
						+ 'do głosowania' : 'Masz możliwość wielokrotnej edycji oddanych przez siebie głosów do czasu zakończenia głosowania.'}
					img={AbcLogo}
					secondButton={{
						secondButtonAction: handleVoteClick,
						secondButtonText: 'głosuj',
					}}
					setPopupStatus={() => setVotingPopupStatus(false)}
					title="Przechodzisz do głosowania"
				/>
			)}
		</Wrapper>
	);
};

export default VotingItem;
