import * as React from 'react';

// eslint-disable-next-line react/require-default-props
const SvgComponent = ({ color }: { color?: string }) => (
	<svg
		height={8.044}
		viewBox="0 0 9.217 8.044"
		width={9.217}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.569 8.045a.967.967 0 01-.814-.407L.223 4.383a1.033
			1.033 0 011.628-1.271l1.718 2.215 3.8-4.928a1.031 1.031 0
			 011.628 1.266L4.386 7.633a1.182 1.182 0 01-.817.412z"
			data-name="Path 558"
			fill={color || '#07064e'}
		/>
	</svg>
);

export default SvgComponent;
