import React from 'react';
import styled from 'styled-components';
import { AttorneyDto } from '../../../../services/@types/attorney.interface';

const Wrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.aqua};
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
  	flex-direction: row;
  	font-size: 14px;
  	font-weight: 800;
  	font-weight: bold;
  	height: 38px;
	justify-content: center;
	padding-left: 20px;
  	position: fixed;
  	text-align: center;
	top: 85px;
	width: 100%;
	z-index: 20;
`;
const VoteAsText = styled.p`
	color: ${({ theme }) => theme.colors.white};
	margin-right: 10px;
`;

const ChosenAttorney = ({ voter }: {voter: AttorneyDto}) => (
	<Wrapper>
		<VoteAsText>
			Głosujesz jako:
		</VoteAsText>
		<p>
			{`${voter?.firstName} ${voter?.lastName}`}
		</p>
	</Wrapper>
);

export default ChosenAttorney;
