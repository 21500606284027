import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BasicTimerProps } from '../@types/basicTimer.interface';
import { getCountdown } from '../../../_utils/date';

const StyledTimer = styled.div`
	border: 2px solid ${({ theme }) => theme.colors.aqua};
	border-radius: 32px;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: inline-block;
	font-size: 17px;
	font-weight: bold;
	line-height: 31px;
	padding: 0 16px;
`;

const BasicTimer: FunctionComponent<BasicTimerProps> = ({ date }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [time, setTime] = useState(null);

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(new Date().toString());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<StyledTimer>{getCountdown(date)}</StyledTimer>
	);
};

export default BasicTimer;
