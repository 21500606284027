import React from 'react';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import animationData from '../../../images/loader.json';

const Wrapper = styled.div`
	align-items: center;
	background-color: rgba(255,255,255,.80);
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed; 
	top: 0;
	width: 100vw;
	z-index: 1000000;
`;

const Loader = () => (
	<Wrapper>
		<Lottie
			height={150}
			options={{
				animationData,
				loop: true,
				autoplay: true,
			}}
			width={150}
		/>
	</Wrapper>
);

export default Loader;
