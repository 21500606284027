import moment from 'moment';
import { VotingStatusEnum } from '../_enums/votingStatus.enum';

export function convertUTCDateToLocalDate(utcDate: moment.Moment) {
	return utcDate.utc(true);
}

export const getTimeFromDateString = (stringDate: string): string => {
	const date = convertUTCDateToLocalDate(moment(stringDate));
	return date.format('HH:mm');
};

export const getCountdown = (date: string): string => {
	const diff = convertUTCDateToLocalDate(moment(date)).diff(moment().utc(true), 'seconds');
	const days = Math.floor(diff / (60 * 60 * 24));
	const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
	const minutes = Math.floor((diff % (60 * 60)) / 60);
	const seconds = Math.floor(diff % 60);
	return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''} ${seconds}s`;
};

export const checkIsAfter = (startDate: moment.Moment): boolean => convertUTCDateToLocalDate(startDate).diff(moment().utc(true)) < 0;

export const checkIsActive = (startDate: moment.Moment, endDate: moment.Moment) => checkIsAfter(startDate) && !checkIsAfter(endDate);

export const getStatus = (startDate: moment.Moment, endDate: moment.Moment) => {
	if (checkIsActive(startDate, endDate)) {
		return VotingStatusEnum.IN_PROGRESS;
	}
	if (checkIsAfter(endDate)) {
		return VotingStatusEnum.DONE;
	}
	return VotingStatusEnum.WAITING;
};
