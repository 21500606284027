import moment from 'moment';
import React, {
	createRef, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import Stain1 from '../../../images/stain1.svg';
import Stain2 from '../../../images/stain2.svg';
import { AgendaDto } from '../../../services/@types/event.interface';
import { GlobalState } from '../../../state/@types/redux.interface';
import { setVotingIdAction } from '../../../state/votingModule.reducer';
import PageHeader from '../../common/page-header/PageHeader';
import Popup from '../../common/popup/Popup';
import SEO from '../../seo';
import AgendaTopImg from '../../../images/room/agendaTop.png';
import ResultsInfoIcon from '../../../images/room/results-info.svg';
import { theme } from '../../../contants/theme';
import { checkIsAfter, getTimeFromDateString } from '../../../_utils/date';
import useWindowDimensions from '../../../_utils/useWindowDimensions';
import AgendaDate from './components/AgendaDate';
import AgendaDateHeaderArrows, { AgendaDateHeaderArrowsType } from './components/AgendaDateHeaderArrows/AgendaDateHeaderArrows';
import DayPart from './components/DayPart';
import EventDate from './components/EventDate';
import MultipleVotesStarted from './components/MultipleVotesStarted';
import NotStarted from './content/NotStarted';
import VotingMain from './voting/VotingMain';

export const StyledAgendaDetails = styled.div`
  	background-color: ${({ theme }) => theme.colors.white};
  	background-image: url(${({ Stain1 }) => Stain1});
 	background-position: 210px 110px;
	height: 100%;
	position: relative;
  	@media (max-width: 1000px) {
    	padding-top: 0;
		height: auto;
  	}
	@media (min-width: 700px) {
		background-image: url(${({ Stain1 }) => Stain1});
		background-repeat: no-repeat;
    	background-position: 210px 110px;
	}
	@media (min-width: 1000px) {
		background-position: -100px 150px; 
		background-size: 500px 500px;
	}
	@media (min-width: 1400px) {
		background-position: 40px 220px; 
		background-size: 600px 600px;
	}
	@media (min-width: 2500px) {
		background-position: 500px 150px; 
		background-size: 800px 800px;
	}
`;

export const BgImageHolder = styled.div`
  margin-bottom: 50px;
  min-height: ${({ theme }) => theme.colors.scrollContainerSize};
	width: 100%;
	@media (min-width: 700px) {
		background-image: url(${({ Stain2 }) => Stain2});
		background-repeat: no-repeat;
		background-position: 100px 380px;
	}
	@media (min-width: 1000px) {
		background-position: 770px 114px;
		background-size: 253px 290px;
		margin: 0;
		padding-bottom: 80px;
	}
	@media (min-width: 1400px) {
		background-position: 967px 104px;
		background-size: 300px 300px;
	}
	@media (min-width: 2500px) {
		background-position: 1474px 99px;
		background-size: 400px 400px;
	}
`;

const StyledAgendaContent = styled.div`
	max-width: 1000px;
	width: 100%;
	.dayPartWrapper {
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
    border-left: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
    border-right: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
	}
	.dayPart:last-child {
		.dayPartWrapper{
			border-bottom: unset;
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 20px;
		}
	}
`;
const DateList = styled.div`
	-webkit-overflow-scrolling: touch !important;
	border-left: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
  	border-right: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
  	border-top: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
  	border-top-left-radius: 20px;
  	border-top-right-radius: 20px;
  	display: flex;
  	height: 100%;
	overflow: hidden;
	${({ numberOfItems }) => (numberOfItems > 3 ? 'padding: 0 34px;' : 'padding: 0;')}
  	position: relative;
  	width: 100%;
  	@media(max-width: 600px) {
    	${({ numberOfItems }) => (numberOfItems > 3 ? 'padding: 0 19px;' : 'padding: 0;')}
  	}
`;
const DateListScroll = styled.div`
  -ms-overflow-style: none;
  display: -moz-box;
  display: -webkit-box;
  height: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  width: 100%;
	&::-webkit-scrollbar {
    display: none;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const Wrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px 26px 30px;
  	width: 100%;
  	@media (max-width: 600px) {
    	padding: 24px 10px 20px;
  	}
`;

const withScoreboardPopupText = 'Głosy zostały oddane. \n'
	+ 'Wyniki głosowania pojawią się w tabeli wyników po jego zakończeniu';
const withoutScoreboardPopupText = 'Twoje odpowiedzi zostały zapisane';

export const AgendaDetails = () => {
	const dispatch = useDispatch();
	const { details } = useSelector((state: GlobalState) => state.event);
	const { votingId, votingModule } = useSelector((state: GlobalState) => state.votingModule);
	const eventEnded = checkIsAfter(moment(details.accessEndAt));
	const eventStarted = checkIsAfter(moment(details.accessStartAt));
	const [agenda, setAgenda] = useState<Array<unknown>>([]);
	const [activeDay, setActiveDay] = useState<string>('');
	const [dayParts, setDayParts] = useState(null);
	const [votingEndPopupStatus, setVotingEndPopupStatus] = useState<boolean>(false);
	const [openedAgendaPanel, setOpenedAgendaPanel] = useState<string>('');
	const [openedAgendaPanelIndex, setOpenedAgendaPanelIndex] = useState<number>(1);

	const { width } = useWindowDimensions();

	const DateListScrollRef = useRef<HTMLDivElement>(null);

	const arrLength = dayParts && dayParts.length;
	const [elRefs, setElRefs] = React.useState([]);

	React.useEffect(() => {
		// add or remove refs
		setElRefs((elRefs) => (
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			Array(arrLength).fill().map((_, i) => elRefs[i] || createRef())
		));
	}, [arrLength]);
	const refreshAgenda = () => {
		const agendaItems = [];
		details.agenda.forEach((item: AgendaDto) => {
			if (agendaItems[item.date]) {
				agendaItems[item.date].push(item);
			} else {
				agendaItems[item.date] = [item];
			}
		});
		return agendaItems;
	};
	useEffect(() => {
		if (votingId === null) {
			if (openedAgendaPanel) {
				if (elRefs && elRefs.length > 0) {
					const elementPosition = elRefs[openedAgendaPanelIndex]?.current?.getBoundingClientRect()?.top;
					const topOffest = width >= 600 ? 50 : 75;
					const offsetPosition = elementPosition - topOffest;
					if (typeof window !== 'undefined') {
						window.scrollTo({
							top: offsetPosition,
							behavior: 'smooth',
						});
					}
				}
			}
		}
	}, [votingId]);
	useEffect(() => {
		const agendaItems = refreshAgenda();
		setActiveDay(Object.keys(agendaItems).sort((a, b) => (a > b ? 1 : -1))[0]);
	}, []);

	useEffect(() => {
		setAgenda(refreshAgenda());
	}, [details]);

	const findFirstPoint = (agenda) => {
		const filteredAgenda = agenda.filter((point) => !!point.startAt);
		if (filteredAgenda.length === 0) {
			return '';
		}
		const first = filteredAgenda.reduce((prev, curr) => (moment(prev.startAt).diff(moment(curr.startAt)) < 0 ? prev : curr));
		return getTimeFromDateString(first.startAt);
	};

	const findLastPoint = (agenda) => {
		const filteredAgenda = agenda.filter((point) => !!point.endAt);
		if (filteredAgenda.length === 0) {
			return '';
		}
		const first = filteredAgenda.reduce((prev, curr) => (moment(prev.endAt).diff(moment(curr.endAt)) > 0 ? prev : curr));
		return getTimeFromDateString(first.endAt);
	};

	const getDayLabels = (): React.ReactNode =>
		Object.keys(agenda)
			.sort((a, b) => (a > b ? 1 : -1))
			.map((key) => (
				<AgendaDate
					key={agenda[key].id}
					date={key}
					endAt={findLastPoint(agenda[key])}
					isActive={key === activeDay}
					numberOfItems={Object.keys(agenda).length}
					setActive={setActiveDay}
					startAt={findFirstPoint(agenda[key])}
				/>
			));

	const getDayParts = (): React.ReactNode => {
		if (!Object.keys(agenda).length || !activeDay) {
			return null;
		}
		return agenda[activeDay].sort((a, b) => (a.order > b.order ? 1 : -1));
	};
	useEffect(() => {
		const todayDayParts = getDayParts();
		if (todayDayParts) {
			setOpenedAgendaPanel(todayDayParts[0]?.id);
		}
	}, [activeDay]);
	useEffect(() => {
		setDayParts(getDayParts());
	}, [agenda, activeDay]);

	const scrollDateList = (amount: number) => {
		DateListScrollRef.current.scrollBy({
			left: amount,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		if (votingEndPopupStatus && votingId) {
			dispatch(setVotingIdAction(null));
		}
	}, [votingEndPopupStatus]);

	useEffect(() => {
		if (votingId) {
			setVotingEndPopupStatus(false);
		}
	}, [votingId]);

	return (
		<StyledAgendaDetails
			Stain1={Stain1}
		>
			<BgImageHolder Stain2={Stain2}>
				<SEO title="Porządek obrad" />
				<GlobalStyle theme={theme} />
				{votingId ? (
					<VotingMain setVotingEndPopupStatus={setVotingEndPopupStatus} />
				) : (
					<>
						{
							votingEndPopupStatus
							&& (
								<Popup
									content={votingModule.scoreboard ? withScoreboardPopupText : withoutScoreboardPopupText}
									img={ResultsInfoIcon}
									setPopupStatus={setVotingEndPopupStatus}
									title="Dziękujemy!"
								/>
							)
						}
						<Wrapper>
							<PageHeader
								img={AgendaTopImg}
								isLogoOnMobile
								text={details.name}
								title="Wydarzenie:"
							/>
							<EventDate
								endAt={moment(details.accessEndAt)}
								eventHeader={checkIsAfter(moment(details.accessStartAt)) ? 'Wydarzenie aktywne:' : 'Wydarzenie nieaktywne'}
								startAt={moment(details.accessStartAt)}
							/>
							<StyledAgendaContent>
								{details?.agenda?.length > 0 ? (
									<>
										<DateList numberOfItems={Object.keys(agenda).length}>
											{Object.keys(agenda).length > 3 && (
												<>
													<AgendaDateHeaderArrows
														onClick={scrollDateList}
														type={AgendaDateHeaderArrowsType.LEFT}
													/>
													<AgendaDateHeaderArrows
														onClick={scrollDateList}
														type={AgendaDateHeaderArrowsType.RIGHT}
													/>
												</>
											)}
											{!!agenda && <DateListScroll ref={DateListScrollRef}>{getDayLabels()}</DateListScroll>}
										</DateList>
										{dayParts?.map((item, index) => (
											<DayPart
												key={item.id}
												ref={elRefs[index]}
												id={item.id}
												index={index + 1}
												info={item}
												openedAgendaPanel={openedAgendaPanel}
												setOpenedAgendaPanel={setOpenedAgendaPanel}
												setOpenedAgendaPanelIndex={setOpenedAgendaPanelIndex}
											/>
										))}
									</>
								) : (
									<>
										{!eventStarted && (
											<NotStarted
												name="wydarzenie"
												startAt={details.accessStartAt}
											/>
										)}
										{(eventStarted || eventEnded) && (
											<MultipleVotesStarted
												openedAgendaPanel={openedAgendaPanel}
												setOpenedAgendaPanel={setOpenedAgendaPanel}
												votingModules={details.votingModules}
											/>
										)}
									</>
								)}
							</StyledAgendaContent>
						</Wrapper>
					</>
				)}
			</BgImageHolder>
		</StyledAgendaDetails>
	);
};
