import * as React from 'react';

const CommonUserIcon = ({
	size, type,
}) => (
	<svg
		height={size}
		style={{ float: 'right' }}
		type={type}
		viewBox="0 0 285.5 285.5"
		width={size}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			/* eslint-disable-next-line max-len */
			d="M79.999 62.75c0 34.601 28.149 62.75 62.751 62.75s62.751-28.149 62.751-62.75S177.352 0 142.75 0 79.999 28.149 79.999 62.75zM42.75 285.5h200c8.284 0 15-6.716 15-15 0-63.411-51.589-115-115-115s-115 51.589-115 115c0 8.284 6.716 15 15 15z"
			fill={(() => {
				if (type === 'present') {
					return (
						'#2BF5BA'
					);
				}
				if (type === 'absent') {
					return (
						'#EBEBEB'
					);
				}
				return (
					'#C7C7D9'
				);
			})()}
		/>
	</svg>
);

export default CommonUserIcon;
