import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import FaqIcon from '../../../../images/voting/voting-drawing.svg';
import { FaqProps } from '../@types/faq.interface';
import FaqQuestion from './components/faqQuesstion';
import TopWrapper from './components/TopWrapper';

const FaqWrapper = styled.div` 
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	flex-direction: column;
	width: 100%;
  	@media(min-width: 1000px) {
		width: 1000px;
		margin: auto;
		justify-content: left;
  	}
`;
const FaqBottomWrapper = styled.div`
	font-size: 12px;
	font-weight: 400;
	padding: 40px 30px 70px 30px;
	width: 100%;
	@media(max-width: 600px) {
		padding: 0 24px 70px 24px;
	}
`;
const questions = [{
	question: 'Co zrobic kiedy zapomne hasła?',
	answer: 'Skorzystaj z opcji "nie pamietam hasła" dostepnej w ekranie logowania.',
}, {
	question: 'Do kogo zwrócić się w razie problemów?',
	// eslint-disable-next-line max-len
	answer: 'Wszelkie pytania proszę kierować do administratora wydarzenia badź do naszego wsparcia technicznego poprzez formularz kontaktowy, dostepny w aplikacji.\n',
}, {
	question: 'Czy moje dane są odpowiednio chronione?',
	// eslint-disable-next-line max-len
	answer: 'Państwa dane osobowe sa przetwarzane jedynie w celach zgodnych z regulaminem wyborek.pl, dowiedz sie więcej na naszej stronie.',
}, {
	question: 'Dlaczego nie mogę oddać głosu?',
	// eslint-disable-next-line max-len
	answer: 'Upewnij się ze Administrator wudarzenia nie wykluczył Cię z danego głosowania, w razie dalszych problemów prosimy o kontakt, przez formularz w aplikacji, ze wsparciem technicznym.\n',
}, {
	question: 'Czy jeżeli zmienię głos to administrator będzie widział poprzednio zaznaczoną odpowiedz?',
	answer: 'Administrator zobaczy otatnio zmienianą odpowiedź.',
}, {
	question: 'Co zrobić jeżeli podczas ładowania wydarzenia pojawi sie błąd?',
	// eslint-disable-next-line max-len
	answer: 'W takiej sytuacji uprasza się o ponowne zaladowanie aplikacji, bez obaw, twoje dane nadal beda bespieczne a wszystkie procesy powinny zostac wznowione.',
}, {
	question: 'Na moim urzadzeniu aplikacja zle sie wyswietla.',
	answer: 'W takiej sytuacji prosimy w pierwszej kolejnosci o aktualizacje systemu oraz przeglądarki, jezeli\n'
		// eslint-disable-next-line max-len
		+ 'problem nadal występuje skontaktuj się z naszym wsparciem technicznym w razie możliwości dołączając model urządzenia i krótki opis problemu.',
}, {
	question: 'Czy aplikacja wspiera wszytskie przeglądarki?',
	// eslint-disable-next-line max-len
	answer: 'Owszem, przeprowadziliśmy szeorko zakrojone działania w celu wsparcia wybranych przez państwa przeglądarek, jednakże polecamy używać przeglądrki Google Chrome.',
}, {
	question: 'Na jakich urządzeniach moge korzystać z aplikacji Wyborek?',
	// eslint-disable-next-line max-len
	answer: 'Korzystać z aplikacji mogą Panstwo na kazdym urządzeniu posiadajcym przegladarke internetową oraz stabilny dostep do internetu.',
}, {
	question: 'Nie mogę kliknąć opcji "wyniki" w danym głosowaniu.',
	answer: 'Może to być powodem tego iż administrator nie zakonczył jeszcze głosowania, toteż podglad wyników jest jeszcze nie dostępny.',
}, {
	question: 'Nie otrzymałem na skrzynkę email zaproszenia do wydarzenia lub kodu autoryzacyjnego.',
	// eslint-disable-next-line max-len
	answer: 'Może to być przyczyna obciążenia serwisu odpowiadajacego za tą funkcjonalność, w takiej sytuacji uprasza się o cierpliwość lub prośbę do administratora o ponowną wysyłke wiadomości.\n',
},
];
const FaqTemplate: FunctionComponent<FaqProps> = () => {
	const [questionIndex, setQuestionIndex] = useState<number>();

	return (
		<FaqWrapper>
			<TopWrapper
				img={FaqIcon}
				text="Poniżej znajdziesz odpowiedzi na najczęściej zadawane pytania."
				title="FAQ"
			/>
			<FaqBottomWrapper>
				{questions.map(({ answer, question }, index) => (
					<FaqQuestion
						key={question}
						answer={answer}
						isActive={questionIndex === index}
						onClick={() => setQuestionIndex(index === questionIndex ? -1 : index)}
						question={question}
					/>
				))}
			</FaqBottomWrapper>
		</FaqWrapper>
	);
};
export default FaqTemplate;
