import React, { useState } from 'react';
import styled from 'styled-components';
import { VotingDto } from '../../../../services/@types/event.interface';
import Popup from '../../../common/popup/Popup';
import MultipleVotesItem from './MultipleVotesItem';

const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.fontBlue};
  width: 100%;
  .borderBottom:not(:last-child) {
		border-bottom: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
	}
	@media(min-width: 700px) {
		margin: 0 auto;
		z-index: 100;
	}
	@media(min-width: 1000px) {
		margin: 0 auto;
		z-index: 100;
	}
`;

const MultipleVotesStarted = ({ votingModules, openedAgendaPanel, setOpenedAgendaPanel }:
		{ votingModules: Array<VotingDto>, openedAgendaPanel: string, setOpenedAgendaPanel: (panelId) => void}) => {
	const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
	const [popupContent, setPopupContent] = useState<string | React.ReactNode>();

	const handlePopupOpen = (content: string | React.ReactNode) => {
		setPopupOpen(true);
		setPopupContent(content);
	};

	return (
		<StyledWrapper>
			{isPopupOpen && popupContent && (
				<Popup
					content={popupContent}
					setPopupStatus={setPopupOpen}
				/>
			)}
			{votingModules.map((votingModule, key) => (
				<MultipleVotesItem
					key={votingModule.id}
					id={votingModule.id}
					index={key + 1}
					openedAgendaPanel={openedAgendaPanel}
					openPopup={handlePopupOpen}
					setOpenedAgendaPanel={setOpenedAgendaPanel}
					voting={votingModule}
				/>
			))}
		</StyledWrapper>
	);
};

export default MultipleVotesStarted;
