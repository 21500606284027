import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { sendEmailWithFiles } from '../../../../services/services';
import BasicButton from '../../../common/basic-button/BasicButton';
import { FileDto } from '../../../../services/@types/event.interface';
import Popup from '../../../common/popup/Popup';
import FilesIcon from '../../../../images/room/files/page-icon.svg';
import InvalidEmail from '../../../../images/room/files/invalid-email.svg';

const Wrapper = styled.div`
	-webkit-appearance: none;
	align-items: center;
	background-color: #FFF;
	bottom: ${({ theme }) => theme.sizes.navigationBarHeight};
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
	@media(min-width: 1000px) {
		bottom: 50px;
		border-top: 3px solid ${({ theme }) => theme.colors.lightGreyBackground};
	}
	position: fixed;
	width: 100%;
`;
const WrapperContent = styled.div`
	display: flex;
	flex-direction: column;
	@media(min-width: 700px) {
		margin: 0 auto;
		flex-direction: row;
	}
`;
const InfoLabel = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	font-weight: bold;
	margin-bottom: 5px;
	@media(min-width: 700px) {
		margin: 0 auto;
		margin-bottom: 5px;
		padding-top: 10px;
		font-size: 14px;
		margin-right: 20px;
	}
`;

const Input = styled.input`
	-webkit-appearance: none;
	appearance: none;
	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 25px;
	box-shadow: none;
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	font-weight: 600;
	height: 48px;
	outline: none;
	padding: 0 1.5rem;
	vertical-align: middle;
	@media(min-width: 700px) {
		width: 370px;
		height: 48px;
	}
	@media(min-width: 1000px) {
		width: 478px;
	}
	  &.error {
		background-color: ${({ theme }) => theme.colors.lightErrorOrange};
		border: 2px solid ${({ theme }) => theme.colors.errorOrange};
	  }
`;
const StyledBasicButton = styled(BasicButton)`
	margin-left: 20px;
	padding: 10px 20px;
`;

const SendWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
`;
interface IFilesFooter {
	files: FileDto[],
	setFiles: (files: FileDto[]) => void
}
const FilesFooter: FunctionComponent<IFilesFooter> =	({ files, setFiles }) => {
	const [email, setEmail] = useState<string>('');
	const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
	const [popupContent, setPopupContent] = useState<string | React.ReactNode>();
	const [isEmailInvalid, setEmailInvalid] = useState<boolean>(false);
	// eslint-disable-next-line max-len
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const invalid = !re.test(String(email).toLowerCase());

	const handlePopupOpen = (content: string | React.ReactNode) => {
		setPopupOpen(!isPopupOpen);
		setPopupContent(content);
	};
	const closeInvalidEmailModal = () => {
		setEmailInvalid(!isEmailInvalid);
	};

	const onSendEmail = () => {
		if (invalid) {
			setEmailInvalid(true);
			return;
		}
		const activeIds = files.map((file) => file.id);
		// eslint-disable-next-line max-len
		sendEmailWithFiles(email, activeIds).then(() => handlePopupOpen('Zaznaczone pliki zostały wysłane na podany przez Ciebie adres email'));
		setEmail('');
		setFiles([]);
	};

	return (
		<Wrapper>
			{(isPopupOpen && popupContent) && (
				<Popup
					content={popupContent}
					img={FilesIcon}
					setPopupStatus={setPopupOpen}
					title="Pliki Zostały wysłane"
				/>
			)}
			{ isEmailInvalid && (
				<Popup
					content="Wprowadzono błędny adres email"
					img={InvalidEmail}
					setPopupStatus={closeInvalidEmailModal}
					title="Błedny adres email"
				/>
			)}
			<WrapperContent>
				<InfoLabel>wyślij zaznaczone pliki na maila</InfoLabel>
				<SendWrapper>
					<Input
						className={isEmailInvalid ? 'error' : ''}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="podaj adres email"
						type={email}
						value={email}
					/>
					<StyledBasicButton
						disabled={files.length === 0 || email.length === 0}
						onClick={onSendEmail}
					>
						wyślij
					</StyledBasicButton>
				</SendWrapper>
			</WrapperContent>
		</Wrapper>
	);
};

export default FilesFooter;
