import React from 'react';
import styled from 'styled-components';
import Logo from '../../../../images/logo.svg';

const MenuHeaderWrapper = styled.div`
	margin-bottom: 90px;
	margin-top: 40px;
	padding: 24px 0 0 24px;
`;
const MenuBanerHolder = styled.img`
	height: 33px;
	width: 154px;
`;
const MenuHeader = () => (
	<MenuHeaderWrapper>
		<MenuBanerHolder src={Logo} />
	</MenuHeaderWrapper>
);

export default MenuHeader;
