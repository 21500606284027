import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import StatueIcon from '../../../../images/voting/voting-drawing.svg';
import { ITemplateGraphqlData } from '../../../../templates/@types/ITemplateGraphqlData.interface';
import TopWrapper from './components/TopWrapper';

const StatueWrapper = styled.div` 
	align-items: center;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	flex-direction: column;
	width: 100%;
  	@media(min-width: 1000px) {
	  	width: 1000px;
	  	margin: auto;
  	}
`;
const StatueBottomWrapper = styled.div`
	font-size: 12px;
	font-weight: 400;
	padding: 0 30px 70px 30px;
	width: 100%;
	@media(min-width: 1000px) {
		margin: 0 auto;
		padding: 40px 0 70px 0;
		width: 100%;
	}
`;
const PageContent = styled.div`
	& > p {
		text-align: center;
      	font-size: 0.813rem;
	}
	& > table  {
		font-size: 0.75rem;
	}
  	& > ul {
      font-size: 0.75rem;
	}
  	li {
    	font-size: 0.75rem;
  	}
  	a {
      color: #2BF5BA;
      cursor: pointer;
	  font-weight: 700;
	  text-decoration: none;
    }
`;

const MarkdownTemplate: FunctionComponent<ITemplateGraphqlData> = ({ data }) => {
	const { markdownRemark } = data;
	const { frontmatter, html } = markdownRemark;
	return (
		<StatueWrapper>
			<TopWrapper
				img={StatueIcon}
				text=""
				title={frontmatter.subtitle}
			/>
			<StatueBottomWrapper>
				<PageContent
					className="markdown-page-content"
					dangerouslySetInnerHTML={{ __html: html }}
				/>
			</StatueBottomWrapper>
		</StatueWrapper>
	);
};
export default MarkdownTemplate;
