import React from 'react';
import styled from 'styled-components';
import PreviewIcon from '../../../images/drawings/preview_wyborek.svg';

const PreviewWrapper = styled.div``;

const BorderPiece = styled.div`
  background-color: ${({ theme }) => theme.colors.lime};
  box-shadow: 2px 2px 26px 6px rgba(127, 229, 104, 0.70);
  height: 5px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999999999;

  &.bottom {
    bottom: 0;
    top: auto;
  }

  &.left {
    top: 0;
    left: 0;
    height: 100vh;
    width: 5px;
  }

  &.right {
    height: 100vh;
    left: auto;
    right: 0;
    top: 0;
    width: 5px;
  }
`;
const SidePictureWrapper = styled.div`
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: column;
  left: 5px;
  position: fixed;
  top: 50%;
  width: 90px;
  z-index: 99999999;
  @media(max-width: 1100px) {
    right: 0;
    top: 5px;
    left: auto;
    z-index: 9999999999;
  }
  
`;
const SideTopPart = styled.div`
  background-color: ${({ theme }) => theme.colors.lime};
  border-radius: 0 8px 0 0;
  height: 40px;
  position: relative;
  width: 100%;
  @media(max-width: 1100px) {
	display: none;
  }
`;
const SideBottomPart = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.fontBlue};
  border-radius: 0 0 8px 0;
  color: #FFF;
  display: flex;
  font-size: 11px;
  height: 22px;
  justify-content: center;
  width: 100%;
  @media(max-width: 1100px) {
    border-radius: 0 0 0 8px;
  }
`;
const SideIcon = styled.img`
	position: absolute;
	top: -46px;
	  @media(max-width: 1100px) {
		display: none;
	  }
`;

const PreviewBorder = () => (
	<PreviewWrapper>
		<SidePictureWrapper>
			<SideTopPart>
				<SideIcon src={PreviewIcon} />
			</SideTopPart>
			<SideBottomPart>
				<span>Tryb Podgladu</span>
			</SideBottomPart>
		</SidePictureWrapper>

		<BorderPiece />
		<BorderPiece
			className="bottom"
		/>
		<BorderPiece
			className="left"
		/>
		<BorderPiece
			className="right"
		/>
	</PreviewWrapper>
);

export default PreviewBorder;
