import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CheckIcon from '../../../../images/checkIcon.svg';
import { getAnswer } from '../../../../services/services';
import { AnswerDto } from '../../../../state/@types/question.interface';
import { GlobalState } from '../../../../state/@types/redux.interface';
import { fetchEventDetailsAction } from '../../../../state/event.reducer';
import {
	fetchVotingModuleAction,
	sendAnswerAction,
	sendTimeTrackingAnswerAction, setEditModeStatusAction, setQuestionAction,
	setQuestionIndexAction,
} from '../../../../state/votingModule.reducer';
import Loader from '../../../common/loader/Loader';
import NavigationButtons from '../../../common/navigation-buttons/NavigationButtons';
import PointsButton from '../../../voting-module/voting-buttons/points-button/PointsButton';
import YesOrNoButton from '../../../voting-module/voting-buttons/yes-or-no-button/YesOrNoButton';
import VotingHeader from '../../../voting-module/voting-header/VotingHeader';
import ChosenProxy from '../../../voting-module/voting-header/components/ChosenAttorney';
import BasicButton from '../../../common/basic-button/BasicButton';
import PageHeader from '../../../common/page-header/PageHeader';
import VotingIcon from '../../../../images/voting/voting-drawing.svg';
import VotingPopupMain from './voting-popup/VotingPopup';
import VotingPopupFewPoints from './voting-popup/VotingPopupFewPoints';

const StyledWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
  	z-index: 10;
	@media(max-width: 999px) {
		margin-top: 80px;
		z-index: 10;
	}
	@media(min-width: 1000px) {
		padding-bottom: 70px;
	}
`;

const StyledButtonsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	padding-bottom: .5rem;
	width: 100%;
`;

const StyledMiddleWrapper = styled.div`
	margin: 0 auto;
	max-width: 1000px;
	padding: 50px 30px 30px;
	position: relative;
  	&.attroney {
      padding: 85px 30px 30px;
	}
	@media(max-width: 600px) {
		padding: 35px 10px 30px;
		  &.attroney {
            padding: 65px 10px 30px;
		  }
	}
`;

const HeaderWrapper = styled.div`
	padding-left: 10px;
	padding-top: 1px;
	@media(min-width: 700px) {
		padding-top: 10px;
	}
`;

const ActionButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const SkipButton = styled(BasicButton)`
	background-color: ${({ theme }) => theme.colors.blueBackground};
	color: ${({ theme }) => theme.colors.aqua};
	font-size: 12px;
	margin-left: 20px;
`;
const StyledNavigationButton = styled(NavigationButtons)`
	font-size: 12px;
	font-weight: 700;
`;
const StyledVotingHeader = styled(VotingHeader)`
  	z-index: 99;
	&.attroney {
	  top: 123px;
	}
`;

const VotingModule = ({ votingModuleId, setIsThanks, eventType }) => {
	const {
		question, questionIndex, questionEndTime, votingModule, isEditMode, proxy,
	} = useSelector((state: GlobalState) => state.votingModule);
	const { isPreview } = useSelector((state: GlobalState) => state.event.details);
	const [oldAnswersIds, setOldAnswersIds] = useState<Array<AnswerDto>>([]);
	const [selectedAnswers, setSelectedAnswers] = useState<AnswerDto[]>([]);
	const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
	const questions = votingModule?.questions || [];
	const answers = question?.answers || [];
	const isSharesEvent = eventType === 'SharesEvent' || false;
	const [loaderStatus, setLoaderStatus] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [isPopupOpen, setpopupOpen] = useState<boolean>(false);
	const [isPopupOpenFew, setpopupOpenFew] = useState<boolean>(false);
	const usersList = useSelector((state: GlobalState) => state.chat.voters);
	const id = useSelector((state: GlobalState) => state.streaming.data.uid);
	const isUser = usersList.filter((item) => item.id === id);
	const hasAttorneyArray = isUser.map((member) => member.hasAttorney);
	let isSharesQuantity;
	let attorneyInfo;
	if (hasAttorneyArray.length > 0) {
		if (proxy.chosenIndex > -1) {
			const proxyMember = proxy.members[proxy.chosenIndex];
			attorneyInfo = hasAttorneyArray[0].filter((member) => member.id === proxyMember.id);
			isSharesQuantity = attorneyInfo[0].sharesQuantity;
		} else {
			isSharesQuantity = isUser.map((member) => member.sharesQuantity);
		}
	} else {
		isSharesQuantity = isUser.map((member) => member.sharesQuantity);
	}

	const isYesOrNo = votingModule?.type === 'yesOrNo' || false;
	const isSurvey = votingModule?.type === 'survey' || false;
	const voterAnswersCount = isSharesEvent ? Number(isSharesQuantity) : question?.voterAnswersCount || 1;
	const voterAnswersCountMin = question?.voterAnswersCountMin || 1;
	const [votesLeft, setVotesLeft] = useState<number>(voterAnswersCount);
	const isSingle = question?.type === 'single' || false;
	const isPointsOnly = question?.type === 'points' || false;
	const isPointsOrShares = isPointsOnly || isSharesEvent;

	const addPrincipalId = () => (proxy.chosenIndex > -1 ? { principalId: proxy?.members[proxy?.chosenIndex]?.id } : {});

	const getWeightLeft = () => {
		const counter = selectedAnswers.reduce((prev, curr) => {
			const weight = Number(curr.weight);
			return (Number.isNaN(weight) ? 0 : weight) + prev;
		}, 0);
		return voterAnswersCount - counter;
	};

	const getOldAnswers = () => {
		const principalId = proxy?.chosenIndex > -1 ? proxy.members[proxy.chosenIndex]?.id : null;
		getAnswer(votingModuleId, question.id, principalId).then(({ data }) => {
			setOldAnswersIds(data);
			if (isPointsOrShares) {
				setSelectedAnswers(data.map((answer) => ({
					...answer,
					...(addPrincipalId()),
				})));
			}
		});
	};

	const endVoting = () => {
		setIsThanks(true);
		dispatch(fetchEventDetailsAction());
	};

	useEffect(() => {
		dispatch(fetchVotingModuleAction(votingModuleId));
		dispatch(setQuestionIndexAction(0));
	}, []);

	useEffect(() => {
		if (proxy?.chosenIndex > -1) {
			const voterVoteStatus = votingModule?.didVoted?.find((voter) => voter.id === proxy?.members[proxy?.chosenIndex]?.id);
			if (voterVoteStatus) {
				dispatch(setEditModeStatusAction(voterVoteStatus.didVoted));
			}
		} else {
			dispatch(setEditModeStatusAction(votingModule?.didVoted[0].didVoted));
		}
	}, [votingModule, proxy.chosenIndex]);

	useEffect(() => {
		setSelectedAnswers([]);
		setOldAnswersIds([]);
		setIsNextDisabled(true);

		setIsThanks(false);

		if (questionIndex === votingModule?.questions?.length) {
			endVoting();
		} else if (question) {
			setLoaderStatus(true);
			setTimeout(() => {
				setVotesLeft(voterAnswersCount);
				if (isEditMode) {
					getOldAnswers();
				} else if (!isPreview) {
					dispatch(
						sendTimeTrackingAnswerAction(votingModuleId, question.id, false, [
							{
								weight: 0,
								questionId: question.id,
								...addPrincipalId(),
							},
						]),
					);
				}
				setLoaderStatus(false);
			}, 1000);
		}
	}, [votingModule, question, questionIndex, proxy.chosenIndex, isEditMode]);

	useEffect(() => {
		if (isSharesEvent) {
			setVotesLeft(getWeightLeft());
		} else if (isSurvey && isPointsOnly && selectedAnswers.length > 0) {
			setVotesLeft(getWeightLeft());
		} else {
			setVotesLeft(voterAnswersCount - selectedAnswers.length);
		}
	}, [selectedAnswers]);

	useEffect(() => {
		if (isPointsOrShares) {
			if (voterAnswersCount - votesLeft >= voterAnswersCount) {
				setIsNextDisabled(false);
			} else {
				setIsNextDisabled(true);
			}
		} else if (isSurvey) {
			if (selectedAnswers.length < voterAnswersCountMin) {
				setIsNextDisabled(true);
			} else {
				setIsNextDisabled(false);
			}
		} else if (votesLeft === 0) {
			setIsNextDisabled(false);
		} else {
			setIsNextDisabled(true);
		}
	}, [votesLeft]);

	useEffect(() => {
		const didEveryoneVoted = votingModule?.didVoted?.every((votingStatus) => votingStatus.didVoted);
		if (!isEditMode) {
			if (votingModule && votingModule.questions && !question && didEveryoneVoted) {
				endVoting();
			}
		}
	}, [votingModule, proxy]);

	const onClick = (answerId, weight) => { // YESORNO
		if (isSingle) {
			const alreadySelected = selectedAnswers.find((s) => s.answerId === answerId);
			if (alreadySelected) {
				setSelectedAnswers(selectedAnswers.filter((selectedAnswer) => selectedAnswer.answerId !== alreadySelected.answerId));
			} else {
				setSelectedAnswers([
					{
						answerId,
						weight,
						questionId: question.id,
						...(addPrincipalId()),
					},
				]);
			}
		}
		if (isSurvey) {
			const alreadySelected = selectedAnswers.find((s) => s.answerId === answerId);
			if (alreadySelected) {
				if (!isPointsOrShares) {
					setSelectedAnswers(selectedAnswers.filter((selectedAnswer) => selectedAnswer.answerId !== alreadySelected.answerId));
				}
			} else {
				setSelectedAnswers(
					[...selectedAnswers,
						{
							answerId,
							questionId: question.id,
							weight,
							...(addPrincipalId()),
						},
					].slice(-voterAnswersCount),
				);
			}
		}
	};

	const skipQuestion = () => {
		const newQuestionIndex = questionIndex + 1;
		dispatch(setQuestionIndexAction(newQuestionIndex));
		dispatch(setQuestionAction(questions[newQuestionIndex]));
	};

	const onSend = () => {
		if (!isNextDisabled) {
			if (isPreview) {
				skipQuestion();
			} else {
				dispatch(sendAnswerAction(votingModuleId, question.id, isEditMode, selectedAnswers));
			}
		}
	};

	const getHelpText = () => {
		if (!isSharesEvent) {
			if (voterAnswersCount === 1 || isYesOrNo) {
				return 'Możesz wybrać jedną odpowiedź';
			}

			if (voterAnswersCount && !isPointsOnly) {
				return `Wybierz od ${voterAnswersCountMin} do ${voterAnswersCount} odpowiedzi`;
			}
		}
		if (isPointsOrShares) {
			if (isSharesEvent) {
				return `Przyporządkuj od ${voterAnswersCountMin} do ${voterAnswersCount} udziałów`;
			}
			return `Przyporządkuj od ${voterAnswersCountMin} do ${voterAnswersCount} pkt`;
		}
	};

	const getAnswerWeight = (id) => {
		const selectedAnswer = selectedAnswers.find((s) => s.answerId === id);
		return !selectedAnswer ? 0 : selectedAnswer.weight;
	};

	const checkIfOldAnswer = (index: string): boolean => (
		isEditMode && !!oldAnswersIds.find((oldAnswerId) => index === oldAnswerId.answerId)
	);

	const getOldAnswerWeight = (index: string): number => (
		oldAnswersIds.find((oldAnswerId) => index === oldAnswerId.answerId).weight
	);

	const changePointAnswer = (answerId: string, weight: number) => {
		let tempSelectedAnswers = selectedAnswers;

		tempSelectedAnswers = tempSelectedAnswers.map((answer) => ({
			...answer,
			principalId: null,
		}));

		if (tempSelectedAnswers.find((selectedAnswer) => selectedAnswer.answerId === answerId)) {
			tempSelectedAnswers = tempSelectedAnswers
				.map((selectedAnswer) => {
					if (selectedAnswer.answerId === answerId) {
						return {
							...selectedAnswer,
							answerId,
							questionId: question.id,
							weight,
							...(addPrincipalId()),
						};
					}

					return selectedAnswer;
				});
		} else {
			tempSelectedAnswers.push({
				answerId,
				questionId: question.id,
				weight,
				...(addPrincipalId()),
			});
		}
		tempSelectedAnswers = tempSelectedAnswers.filter((selectedAnswer) => selectedAnswer.weight > 0);
		setSelectedAnswers(tempSelectedAnswers);
		setVotesLeft(getWeightLeft());
	};

	const handleCloseButton = () => {
		setpopupOpen(false);
	};
	const handleCloseButtonFew = () => {
		setpopupOpenFew(false);
	};
	const handleOpenPopup = () => {
		setpopupOpen(true);
		setTimeout(() => {
			handleCloseButton();
		}, 3000);
	};

	const handleOpenPopupFewPoints = () => {
		setpopupOpenFew(true);
		setTimeout(() => {
			handleCloseButtonFew();
		}, 3000);
	};

	return (
		<StyledWrapper>

			<VotingPopupMain
				isPopupOpen={isPopupOpen}
				onClick={handleCloseButton}
			/>

			<VotingPopupFewPoints
				isPopupOpen={isPopupOpenFew}
				onClick={handleOpenPopupFewPoints}
			/>

			{ loaderStatus && <Loader /> }
			{ proxy.chosenIndex > -1
				&& <ChosenProxy voter={proxy.members[proxy.chosenIndex]} /> }
			{question && (
				<>
					<StyledMiddleWrapper className={proxy.chosenIndex > -1 ? 'attroney' : ''}>
						<HeaderWrapper>
							<PageHeader
								img={VotingIcon}
								text={question.text}
								title="Głosowanie:"
							/>
						</HeaderWrapper>
						<StyledVotingHeader
							answers={answers}
							className={proxy.chosenIndex > -1 ? 'attroney' : ''}
							helpText={getHelpText()}
							isPointsOrShares={isPointsOrShares}
							isSharesEvent={isSharesEvent}
							isSurvey={isSurvey}
							isYesOrNo={isYesOrNo}
							questionIndex={questionIndex + 1}
							questionTotal={questions.length}
							time={questionEndTime}
							weight={voterAnswersCount}
							weightLeft={(isPointsOrShares) ? getWeightLeft() : voterAnswersCount - selectedAnswers.length}
						/>
						{!!answers.length && (
							<StyledButtonsWrapper>
								{((isYesOrNo || (isSurvey && !isPointsOnly))
									&& answers.map((answer) => (
										!isSharesEvent ? (
											<YesOrNoButton
												key={answer.id}
												active={!!getAnswerWeight(answer.id)}
												img={CheckIcon}
												isOldAnswer={checkIfOldAnswer(answer.id)}
												onClick={() => onClick(answer.id, 1)}
												text={answer.text}
											/>
										) : (
											<PointsButton
												key={answer.id}
												answerId={answer.id}
												changeAnswer={changePointAnswer}
												didWeightChange={votesLeft === voterAnswersCount}
												isSharesEvent={isSharesEvent}
												oldWeight={
													oldAnswersIds.length > 0
														&& checkIfOldAnswer(answer.id)
														&& getOldAnswerWeight(answer.id)
												}
												onClick={votesLeft === 0 ? handleOpenPopup : null}
												text={answer.text}
												weightLeft={votesLeft}
											/>

										)
									)))}
								{isSurvey && isPointsOnly && answers.map((answer) => (
									<PointsButton
										key={answer.id}
										answerId={answer.id}
										changeAnswer={changePointAnswer}
										didWeightChange={votesLeft === voterAnswersCount}
										isSharesEvent={isSharesEvent}
										oldWeight={
											oldAnswersIds.length > 0
												&& checkIfOldAnswer(answer.id)
												&& getOldAnswerWeight(answer.id)
										}
										onClick={votesLeft === 0 ? handleOpenPopup : null}
										text={answer.text}
										weightLeft={votesLeft}
									/>
								))}

							</StyledButtonsWrapper>
						)}
						<ActionButtonsWrapper>
							<StyledNavigationButton
								handleOpenPopupFewPoints={handleOpenPopupFewPoints}
								isEditMode={isEditMode}
								isPointsOrShares={isPointsOrShares}
								nextDisabled={isNextDisabled}
								nextOrange={isEditMode}
								nextText={isEditMode ? 'zmień głos' : 'zatwierdź głos'}
								onNext={onSend}
							/>
							{isEditMode && (
								<SkipButton onClick={skipQuestion}>pomiń</SkipButton>
							)}
						</ActionButtonsWrapper>
					</StyledMiddleWrapper>
				</>
			)}
		</StyledWrapper>
	);
};

export default VotingModule;
