import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import AvatarBox from '../chat/AvatarBox';
import { theme } from '../../../../contants/theme';
import { GlobalState } from '../../../../state/@types/redux.interface';
import MicrophoneStateIcon from '../../../../images/room/streaming/MicrophoneStateIcon';
import useGetNameByUid from '../../../../_utils/useGetNameByUid';
import HandUpIcon from '../../../../images/room/HandUpIcon';
import { VideoConferenceUserRoleEnum } from '../../../../_enums/videoConferenceUserRoleEnum';
import SpeakingIcon from '../../../../images/room/streaming/SpeakingIcon';
import { MemberItemProps } from './@types/memberItem.interface';

const StyledWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 20px;
  position: relative;
  width: 100%;
`;

const StyledNameWrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.fontBlue};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  user-select: none;
`;

const StyledMutedWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MemberName = styled.p`
	
	&.me {
	  color: ${({ theme }) => theme.colors.aqua};
	 
	}
`;

const MemberItem: FunctionComponent<MemberItemProps> = ({
	firstName,
	lastName,
	isMuted = true,
	isHandUp,
	userId,
	voterConferenceRole,
	isInCall,
	showMemberStateIcon = true,
	showRoleIcon = false,
	showAvatar = false,
}) => {
	const id = useSelector((state: GlobalState) => state.streaming.data.uid);
	const userName = useGetNameByUid(userId);
	const isMe = userId === id;
	const loudestUser = useSelector((state: GlobalState) => state.chat.loudestUser);

	const handleMemberStateIcon = () => {
		console.log(loudestUser.volume);

		if (!isHandUp) {
			if (loudestUser.uid === userId && loudestUser.volume >= 12) {
				return (
					<SpeakingIcon
						member
					/>
				);
			}
			if (!isMuted || (loudestUser.uid === userId && loudestUser.volume < 12)) {
				return (
					<MicrophoneStateIcon
						active
						size={20}
					/>
				);
			}
		}
		if (isHandUp) {
			return (
				<StyledMutedWrapper>
					<HandUpIcon fill={isHandUp ? theme.colors.aqua : theme.colors.blueBackground} />
				</StyledMutedWrapper>
			);
		}
		if (isInCall && isMuted) {
			return (
				<MicrophoneStateIcon
					active={false}
					notActiveFill="#07064E"
					size={20}
				/>
			);
		}
	};

	return (
		<StyledWrapper>
			<StyledNameWrapper isMe={isMe}>
				<AvatarBox
					className={showAvatar ? 'initials' : ''}
					isMe={isMe}
					name={userName[0]}
					size={30}
				/>
				<MemberName className={isMe ? 'me' : ''}>
					{`${firstName || 'Anonim'} ${lastName || ''}`}
					{voterConferenceRole === VideoConferenceUserRoleEnum.ADMIN && ' (Admin)'}
					{isMe && ' (Ty)'}
				</MemberName>
			</StyledNameWrapper>
			{showMemberStateIcon && handleMemberStateIcon()}
			{showRoleIcon && handleRoleIcon()}
		</StyledWrapper>
	);
};

export default MemberItem;
