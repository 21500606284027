import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { theme } from '../../../../../contants/theme';
import { VotingStatusEnum } from '../../../../../_enums/votingStatus.enum';
import { ScoreVotingButtonProps } from '../@types/scoreVotingButton.interface';
import ModifiedButton from './ModifiedButton';

const StyledModifiedButton = styled(ModifiedButton)`
	height: 34px;
	&.disabled {
		background-color: ${({ theme }) => theme.colors.lightGreyBackground};
		color: ${({ theme }) => theme.colors.fontLightGrey};
		cursor: pointer;
		:active {
			background-color: ${({ theme }) => theme.colors.mediumWhite};
			color: ${({ theme }) => theme.colors.lightPurple};
		}
	}
`;

const ScoreVotingButton: FunctionComponent<ScoreVotingButtonProps> = ({
	votingStatus, handleScrBoardPopup, handlePopupOpen,
}) => (
	<>
		<StyledModifiedButton
			className={votingStatus === VotingStatusEnum.DONE ? '' : 'disabled'}
			color={votingStatus === VotingStatusEnum.DONE ? theme.colors.aqua : 'disabled'}
			onClick={votingStatus === VotingStatusEnum.DONE ? handleScrBoardPopup : handlePopupOpen}
			textColor={votingStatus === VotingStatusEnum.DONE ? theme.colors.blueBackground : 'disabled'}
		>
			wyniki
		</StyledModifiedButton>
	</>

);
export default ScoreVotingButton;
