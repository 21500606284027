import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GlobalState } from '../../../state/@types/redux.interface';
import { timeExceededAction } from '../../../state/votingModule.reducer';
import { VotingCountersProps } from './@types/votingCounters.interface';

const StyledCounter = styled.div`
  	color: ${({ theme }) => theme.colors.darkYellow};
	font-weight: bold;
`;

let interval;

const VotingCounters = ({
	endTime, answers,
}: VotingCountersProps): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [time, setTime] = useState(null);
	const dispatch = useDispatch();
	const { question, isEditMode } = useSelector((state: GlobalState) => state.votingModule);

	useEffect(() => {
		if (!isEditMode) {
			interval = setInterval(() => {
				setTime(new Date().toString());
				if (moment(endTime).diff(new Date(), 'seconds') === 0 && question && !isEditMode) {
					dispatch(timeExceededAction(answers));
					clearInterval(interval);
				}
			}, 500);
		}
		return () => {
			clearInterval(interval);
			interval = null;
		};
	}, [question, answers]);

	const minutes = moment(endTime).diff(new Date(), 'minutes');
	const seconds = moment(endTime).diff(new Date(), 'seconds') - minutes * 60;
	const toEnd = moment(endTime).diff(new Date(), 'seconds');

	const minutesText = `00${minutes}`.slice(-2);
	const secondsText = `00${seconds}`.slice(-2);

	if (!isEditMode) {
		return (
			<StyledCounter className={toEnd > 0 ? 'visible' : ''}>
				{minutesText}
				min
				:&nbsp;
				{secondsText}
				sek
			</StyledCounter>
		);
	}
};

export default VotingCounters;
