import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import AvatarBox from '../videoChat/chat/AvatarBox';
import { GlobalState } from '../../../state/@types/redux.interface';
import ObserverTypeIcon from '../../../images/attendence-list/observerTypeIcon';
import ProxyTypeIcon from '../../../images/attendence-list/ProxyIconType';
import CommonUserIcon from '../../../images/attendence-list/commonUserIcon';
import { AttendanceMainProps } from './@types/attendanceuser.interface';

const StyledUser = styled.li`
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
    list-style: none;
    margin-top: 24px;
    padding: 0 20px;
`;

const MemberName = styled.div`
 @media (max-width: 1000px) {
		margin-top: -22px;
		margin-left: 40px;
	}
	
	
   
`;
const StyledAttorneys = styled.div`
    align-items: center;
	color: #B4B4B4;
    font-family: 'Open Sans', sans-serif;
  	font-size: 13px;
 	list-style: none;
 	margin-top: 24px;
    padding: 0 20px;
`;

const AddLineToAttorney = styled.div`
	 background: #EBEBEB;
	 height: 2px;
	 margin-right: 12px;
	 margin-top: 10px;
	 width: 18px;
`;
const LineAndNameAttorney = styled.li`
	display: flex;
	margin-left: -7px;
`;

const AddLineToAvatar = styled.div`
	 background: #EBEBEB;
	 height: 33px;
	 margin-top: -21px;
	 width: 2px;
`;

const PresentAbsentUser: FunctionComponent<AttendanceMainProps> = ({
	firstName, lastName, isPresent, role, userId, hasAttorney,
}) => {
	const id = useSelector((state: GlobalState) => state.streaming.data.uid);
	const isMe = userId === id;

	const renderedAttorney = hasAttorney.map((attorneys) => (
		<LineAndNameAttorney key="attorneys.id">
			<AddLineToAvatar style={{ background: isPresent ? '#FF9C28' : '#EBEBEB' }} />
			<AddLineToAttorney style={{ background: isPresent ? '#FF9C28' : '#EBEBEB' }} />
			{`${attorneys.voter.firstName} ${attorneys.voter.lastName}`}
		</LineAndNameAttorney>
	));

	const handleUserIcon = () => {
		if (role) {
			return (
				<CommonUserIcon
					size="14"
					type={isPresent ? 'present' : 'absent'}
				/>
			);
		}
		return (
			<ObserverTypeIcon
				size="16"
				type={isPresent ? 'present' : 'absent'}
			/>
		);
	};
	const checkIfHasAttorney = () => {
		if (hasAttorney.length > 0) {
			return (
				<ProxyTypeIcon
					size="16"
					type={isPresent ? 'present' : 'absent'}
				/>
			);
		}
	};

	return (
		<StyledUser
			isMe={isMe}
			style={{
				color: isPresent ? '#07064E' : '#B4B4B4',
			}}
		>
			<AvatarBox
				className={isPresent ? 'initials' : 'initials-absent'}
				isMe={isMe}
				name={`${firstName[0]}`}
				size={30}
			/>
			<MemberName>
				{`${firstName} ${lastName}`}
				{isMe && ' (Ty)'}
				{handleUserIcon()}
				{checkIfHasAttorney()}
			</MemberName>
			<StyledAttorneys style={{ color: isPresent ? '#07064E' : '#B4B4B4' }}>
				{renderedAttorney}
			</StyledAttorneys>
		</StyledUser>

	);
};
export default PresentAbsentUser;
