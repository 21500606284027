import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { theme } from '../../../../contants/theme';
import { GlobalState } from '../../../../state/@types/redux.interface';
import { PointsButtonProps } from '../@types/pointsButton.interface';

const StyledPointsWrapper = styled.div`
	-webkit-appearance: none;
	background-color: ${({ theme }) => theme.colors.white};
	border-radius: 18px;
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	flex-direction: column;
	font-size: .75rem;
	font-weight: 600;
	margin: 0 auto 1rem;
	width: 100%;
	
  &.greenStyle,
	& .greenStyle {
    background-color: ${({ theme }) => theme.colors.dirtyAqua};
    border: 2px solid ${({ theme }) => theme.colors.aqua};
  }

  &.editStyle,
	& .editStyle {
    background-color: ${({ theme }) => theme.colors.dirtyYellow};
    border: 2px solid ${({ theme }) => theme.colors.yellow};
  }
`;

const StyledPointsHeader = styled.span`
	border: 2px solid ${({ isActive }) => (isActive ? theme.colors.aqua : theme.colors.yesNoButtonBorder)};
	border-bottom: none;
	border-radius: 18px 18px 0px 0px;
	padding: 0.85rem 1.5rem;
	text-align: left;
	width: 100%;

	&.greenStyle {
    	border: none;
  	}
  	&.editStyle {
    	border: none;
  	}
`;

const StyledPointsBottom = styled.div`
	align-items: center;
	border: 2px solid ${({ isActive }) => (isActive ? theme.colors.aqua : theme.colors.yesNoButtonBorder)};
	border-radius: 0 0 18px 18px;
	display: flex;
	flex-direction: row-reverse;
	height: 3.125rem;

  	&.greenStyle {
		border: none;
		border-top: 2px solid ${({ theme }) => theme.colors.aqua};
  	}

  &.editStyle {
	border: none;
    border-top: 2px solid ${({ theme }) => theme.colors.yellow};
  }
	width: 100%;
`;

const StyledPointsInput = styled.input`
	-moz-appearance: textfield;
  	-webkit-appearance: none;
  	appearance: none;
	border: 2px solid ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 0 0 10px 0;
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	height: 2.188rem;
	margin: auto 6px auto 6px;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	input[type=number] {
		-moz-appearance:textfield;
	}
	padding-left: 10px;
	text-align: left;
	
	&.value-entered {
		background-color: ${({ theme }) => theme.colors.white};
		font-size: 1.05rem;
      	font-weight: bold;
	}
	
	&::placeholder {
    	color: ${({ theme }) => theme.colors.placeholderColor};
	}
	
	&:focus {
		background-color: ${({ theme }) => theme.colors.white};
		color: ${({ theme }) => theme.colors.fontBlue};
      	outline: none;
    	text-align: left;
	}
	&.editStyle,
	& .editStyle {
      	background-color: ${({ theme }) => theme.colors.white};
		border: 2px solid ${({ theme }) => theme.colors.yellow};
	}
  	width: ${({ isSharesEvent }) => (isSharesEvent ? '12rem' : '10.5rem')};
`;

const AssignLeftWeightButton = styled.div`
  color: ${({ theme }) => theme.colors.aqua};
	cursor: pointer;
	font-size: 0.65rem;
	font-weight: bold;
	margin-left: 10px;
	text-align: right;
`;

const PointsButton = ({
	text,
	weightLeft,
	changeAnswer,
	answerId,
	onClick,
	isSharesEvent,
	oldWeight, didWeightChange,
}: PointsButtonProps): JSX.Element => {
	const isEditMode = useSelector((state: GlobalState) => state.votingModule.isEditMode);
	const [inputValue, setInputValue] = useState<string | number>(0);
	const [isActive, setIsActive] = useState<boolean>(false);
	const [isFocused, setIsFocused] = useState<boolean>(false);

	function handleChange(event) {
		const { value } = event.target;
		const oldValue = inputValue;
		setInputValue(value);
		setIsActive(false);
		if (value === '') {
			changeAnswer(answerId, 0);
		} else {
			const weight = oldValue ? weightLeft + Number(oldValue) : weightLeft;
			if (value <= weight) {
				changeAnswer(answerId, parseInt(String(value), 10));
			} else if (value > weight) {
				changeAnswer(answerId, weight);
				setInputValue(weight);
			}
		}
	}

	const assignLeftWeight = () => {
		const weight = weightLeft + (Number(inputValue) || 0);
		setInputValue(weight);
		changeAnswer(answerId, weight);
		setIsActive(true);
	};

	useEffect(() => {
		if (oldWeight) {
			setIsActive(true);
			setInputValue(oldWeight);
		}
	}, [oldWeight]);

	useEffect(() => {
		if (isFocused) {
			setIsActive(false);
		} else if (inputValue) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [isFocused]);

	const getClassName = () => {
		if (isEditMode) {
			return 'editStyle';
		}
		return 'greenStyle';
	};

	return (
		<StyledPointsWrapper className={(isFocused || isActive) && getClassName()}>
			<StyledPointsHeader className={(isFocused || isActive) && getClassName()}>
				{text}
			</StyledPointsHeader>
			<StyledPointsBottom className={(isFocused || isActive) && getClassName()}>
				<StyledPointsInput
					className={`${(inputValue && !isFocused) && 'value-entered' && getClassName()}`}
					disabled={(inputValue === 0 || inputValue === '') && weightLeft === 0}
					isSharesEvent={isSharesEvent}
					min={0}
					onBlur={() => setIsFocused(false)}
					// eslint-disable-next-line
					onChange={handleChange}
					onClick={onClick}
					onFocus={() => setIsFocused(true)}
					placeholder={isSharesEvent ? `przydziel od 1 do ${weightLeft} udziałów` : `przydziel od 1 do ${weightLeft} pkt`}
					type="number"
					value={inputValue === 0 ? '' : inputValue}
				/>
				{didWeightChange
		&& <AssignLeftWeightButton onClick={assignLeftWeight}>przydziel 100%</AssignLeftWeightButton>}
			</StyledPointsBottom>
		</StyledPointsWrapper>
	);
};

export default PointsButton;
