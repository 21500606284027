import React from 'react';
import { VoteAsButtonProps } from '../../../../../voting-module/voting-header/@types/voteAsButton.interface';
import { theme } from '../../../../../../contants/theme';
import ModifiedButton from '../ModifiedButton';

const VoteAsConfirmButton = ({
	isActive, onClick, text, style, isDisabled,
}: VoteAsButtonProps) => {
	const getBackgroundColor = () => {
		if (isDisabled) {
			return theme.colors.greyBackground;
		}
		if (isActive) {
			return theme.colors.aqua;
		}
		return 'white';
	};

	return (
		<ModifiedButton
			onClick={onClick}
			style={{
				...style,
				marginRight: '10px',
				backgroundColor: getBackgroundColor(),
				border: `2px solid ${isDisabled ? theme.colors.greyBackground : theme.colors.aqua}`,
				color: isDisabled ? theme.colors.fontGrey : theme.colors.fontBlue,
			}}
		>
			{text}
		</ModifiedButton>
	);
};

export default VoteAsConfirmButton;
