import React from 'react';
import { pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import SubpageHeader from '../common/subpage-header/SubpageHeader';
import LogoSvg from '../../images/logo.svg';
import { GlobalState } from '../../state/@types/redux.interface';
import { isWindow } from '../../_utils/windowUndefined';
import { FilePopupProps } from './@types/filePopup.interface';
import ImageViewer from './components/ImageViewer';
import PdfViewer from './components/PdfViewer';

// eslint-disable-next-line no-unused-expressions
isWindow() ? pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js` : null;

const Wrapper = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
  	height: calc(100vh - 30px);
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
  	width: 100vw;
	z-index: 999;
	@media(max-width: 999px) {
    overflow: hidden;
		height: 100vh;
		position: fixed;
	}
`;
const StyledSubHeader = styled(SubpageHeader)`
	position: fixed;
	top: 57px;
	@media(max-width: 999px) {
		top: 40px;
	}
`;
const MobileLogo = styled.img`
	max-height: 100%;
	&.wyborekLogo {
		max-height: 26px;
	}
`;
const MobileLogoWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	height: 40px;
	justify-content: left;
	margin-left: 24px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 40;
	@media(min-width: 1000px) {
		display: none;
	}
`;

const FilePopup = ({
	title, fileUrl, mimeType, closePopup, isAgenda = false,
}: FilePopupProps) => {
	const { details } = useSelector((state: GlobalState) => state.event);

	return (
		<Wrapper>

			<MobileLogoWrapper>
				<MobileLogo
					className={details?.logo?.url ? '' : 'wyborekLogo'}
					src={details?.logo?.url || LogoSvg}
				/>
			</MobileLogoWrapper>
			<StyledSubHeader
				closePopup={closePopup}
				isAgenda={isAgenda}
				title={title}
			/>

			{mimeType.includes('image') && <ImageViewer src={fileUrl} />}
			{mimeType.includes('application/pdf') && <PdfViewer src={fileUrl} />}

		</Wrapper>
	);
};

export default FilePopup;
