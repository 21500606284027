import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { changeActiveMenuSubpage } from '../../../../state/menuTab.reducer';
import { MenuPopupsEnum } from '../../../../_enums/menuPopupsEnum';
import { VotersAccessTypeEnum } from '../../../../_enums/votersAccessTypeEnum';
import { GlobalState } from '../../../../state/@types/redux.interface';

const MenuContentWrapper = styled.div`
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	flex-direction: column;
	font-size: 13px;
	font-weight: 500;
	height: 50vh;
	padding-left: 24px;
`;

const OptionsButton = styled.button`
	background-color: ${({ theme }) => theme.colors.white};
	border-style: none;
	color: ${({ theme }) => theme.colors.fontBlue};
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 25px;
	outline: none;
`;

const ButtonsWrapper = styled.div`
	-webkit-tap-highlight-color: transparent;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: left; 
`;

const AutorizeWrapper = styled.div`
	margin-bottom: 70px;
`;

const WelcomeText = styled.h1`
	margin-bottom: 15px;
`;
const AutorizeType = styled.p`
	color: ${({ theme }) => theme.colors.agendaGrey};
`;

const MenuContent = ({
	contactText, regulaminText, faqText,
}) => {
	const dispatch = useDispatch();
	const eventDetails = useSelector((state: GlobalState) => state.event.details);

	const changeSubpage = (subpage: MenuPopupsEnum) => {
		dispatch(changeActiveMenuSubpage(subpage));
	};

	const getVotersAccessType = (accessType: VotersAccessTypeEnum) => {
		switch (accessType) {
			case VotersAccessTypeEnum.CODES:
				return 'Głosowanie przez unikalny kod';
			case VotersAccessTypeEnum.MAIL:
				return 'Głosowanie przez mail';
			case VotersAccessTypeEnum.PUBLIC:
				return 'Głosowanie publiczne';
			case VotersAccessTypeEnum.SMS:
				return 'Głosowanie przez SMS';
			default:
				return '';
		}
	};

	return (

		<MenuContentWrapper>
			<AutorizeWrapper>
				<WelcomeText>Witaj!</WelcomeText>
				Autoryzacja
				<AutorizeType>{getVotersAccessType(eventDetails?.votersAccess)}</AutorizeType>
			</AutorizeWrapper>
			<ButtonsWrapper>
				<OptionsButton
					onClick={() => changeSubpage(MenuPopupsEnum.CONTACT)}
				>
					{contactText}
				</OptionsButton>
				<OptionsButton
					onClick={() => changeSubpage(MenuPopupsEnum.TERM)}
				>
					{regulaminText}

				</OptionsButton>
				<OptionsButton
					onClick={() => changeSubpage(MenuPopupsEnum.FAQ)}
				>
					{faqText}
				</OptionsButton>
			</ButtonsWrapper>
		</MenuContentWrapper>
	);
};

export default MenuContent;
