import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { saveAs } from 'file-saver';
import { VotingStatusEnum } from '../../../../_enums/votingStatus.enum';
import DashedBorder from '../../../../images/dashedBorder.svg';
import FileSvg from '../../../../images/room/files/file.svg';
import MagnifierSvg from '../../../../images/room/files/magnifier-icon.svg';
import { AgendaDto, FileDto } from '../../../../services/@types/event.interface';
import { getStatus, getTimeFromDateString } from '../../../../_utils/date';
import Popup from '../../../common/popup/Popup';
import FilePopup from '../../../filePopup/FilePopup';
import BasicButton from '../../../common/basic-button/BasicButton';
import { setGlobalScrollHidden } from '../../../../_utils/globalScrollHidden';
import checkIfWindowExist from '../../../../_utils/windowUndefined';
import PopImg from '../../../../images/voting/voting-drawing.svg';
import VotingItem from './VotingItem';

interface Props {
    info: AgendaDto;
    index: number;
    id: string;
	openedAgendaPanel: string;
	setOpenedAgendaPanel: (idToOpen) => void;
	setOpenedAgendaPanelIndex: (index) => void;
}

const getFontByStatus = (status: VotingStatusEnum, theme): string => {
	if (status === VotingStatusEnum.IN_PROGRESS) {
		return theme.colors.fontBlue;
	}
	if (status === VotingStatusEnum.DONE) {
		return theme.colors.agendaGrey;
	}
	return theme.colors.fontBlue;
};

const getTimeDotByStatus = (status: VotingStatusEnum, theme): string => {
	if (status === VotingStatusEnum.IN_PROGRESS) {
		return theme.colors.aqua;
	}
	if (status === VotingStatusEnum.DONE) {
		return theme.colors.agendaGrey;
	}
	return theme.colors.aqua;
};

const Wrapper = styled.div`
  overflow: hidden;

  &:last-of-type {
    border-bottom: 2px solid ${({ theme }) => theme.colors.lightGreyBackground};
    border-radius: 0 0 20px 20px;
  }
`;

const FilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 20px;

  p {
    color: ${({ theme }) => theme.colors.aqua};
    font-weight: bold;
    margin-top: 10px;
  }
`;

const Info = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  min-width: 50px;
  width: 100%;
`;

const TimeDot = styled.div`
  background-color: ${({ status, theme }) => getTimeDotByStatus(status, theme)};
  border-radius: 50%;
  height: 0.3125rem;
  margin-right: 10px;
  width: 0.3125rem;
`;

const TimeWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 0.3125rem;
`;

const Expand = styled.div`
  margin-bottom: -20px;
  margin-top: 0.9375rem;
  padding: 0 10px;
`;

const LookButton = styled.button`
  //margin-bottom: 0.9375rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  color: ${({ theme }) => theme.colors.aqua};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  font-weight: bold;
  height: 100%;
  justify-content: center;
  margin-right: 20px;
  min-height: 35px;
  outline: none;
  text-align: left;
  width: auto;
  @media (max-width: 700px) {
    margin-right: 10px;
  }
`;

const LookIcon = styled.img`
  height: 18px;
  margin-right: 10px;
  width: 18px;
`;

const Time = styled.p`
  color: ${({ status, theme }) => getFontByStatus(status, theme)};
  font-size: 0.75rem;
`;
const DetailsWrapper = styled.div``;
const AgendaSubtitle = styled.div`
  color: ${({ status, theme }) => getFontByStatus(status, theme)};
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  width: 100%;

  p {
    width: 100%;
  }
`;
const VotingNumber = styled.span`
  color: ${({ status, theme }) => getFontByStatus(status, theme)};
  font-weight: 700;
  margin-right: 5px;
`;
const OverInfoWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ status, theme }) => (status === VotingStatusEnum.DONE ? theme.colors.fontLightGrey : 'inherited')};
  font-size: 12px;
  overflow: hidden;
  padding: 15px;
  position: relative;

  &.last-of-type {
    border-bottom: transparent;
	  border-bottom-left-radius: 20px;
	  border-bottom-right-radius: 20px;
  }

  &.done:after {
    background-image: url(${({ DashedBorder }) => DashedBorder});
    content: "";
    height: 100%;
    left: -2px;
    position: absolute;
    top: 0;
    width: 9px;
  }

  &.inProgress:after {
    background-color: #2bf5ba;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 7px;
  }
`;

// eslint-disable-next-line react/display-name
const DayPart = forwardRef(({
	info, index, id, openedAgendaPanel, setOpenedAgendaPanel, setOpenedAgendaPanelIndex,
}: Props, ref) => {
	const getPointStats = () => {
		if (info.endAt) {
			return getStatus(moment(info.startAt), moment(info.endAt));
		}
		return VotingStatusEnum.WAITING;
	};

	const [isExpanded, expand] = useState<boolean>(openedAgendaPanel === id);
	const status = getPointStats();
	const [isFullscreenPopupOpen, setFullscreenPopupOpen] = useState<boolean>(false);
	const [isPopupOpen, setPopupOpen] = useState<boolean>(false);
	const [isPopupDescriptionOpen, setPopupDescriptionOpen] = useState<boolean>(false);
	const [popupContent, setPopupContent] = useState<string | React.ReactNode>();
	const [popupDescriptionContent, setPopupDescriptionContent] = useState<string | React.ReactNode>();
	const [activeFile, setActiveFile] = useState<FileDto>();

	const handleFileClick = (file) => {
		setActiveFile(file);
		setFullscreenPopupOpen(true);
		checkIfWindowExist(() => window.scrollTo(0, 0));
	};
	const [ArrayOfFiles] = info.files;
	const extension = ArrayOfFiles?.extension;

	useEffect(() => {
		if (index === 1 && openedAgendaPanel === '') {
			setOpenedAgendaPanel(id);
			setOpenedAgendaPanelIndex(index - 1);
		}
	}, []);

	useEffect(() => {
		expand(openedAgendaPanel === id);
	}, [openedAgendaPanel]);
	const handlePopupOpen = (content: string | React.ReactNode) => {
		setPopupDescriptionOpen(true);
		setPopupDescriptionContent(content);
	};
	const handlePopupOpenExtension = (content: string | React.ReactNode, file) => {
		setActiveFile(file);
		setPopupOpen(true);
		setPopupContent(content);
	};

	const getFiles = () =>

		info.files.map((file) => (
			<>
				{ ((extension === 'pdf') || (extension === 'jpg')) && (
					<LookButton
						key={file.id}
						onClick={() => handleFileClick(file)}
						type="button"
					>
						<LookIcon
							alt="file"
							src={FileSvg}
						/>
						<span>załącznik</span>
					</LookButton>
				)}
				{
					((extension === 'csv') || (extension === 'txt')) && (
						<LookButton
							key={file.id}
							onClick={() => handlePopupOpenExtension(`Ten plik jest w nieobsługiwanym rozszerzeniu. 
							Podgląd pliku jest niemożliwy.
							Kliknij przycisk poniżej, aby go pobrać. `, file)}
							type="button"
						>
							<LookIcon
								alt="file"
								src={FileSvg}
							/>
							<span>załącznik</span>
						</LookButton>
					)
				}
			</>

		));

	useEffect(() => {
		setGlobalScrollHidden(isPopupOpen || isFullscreenPopupOpen);
	}, [isPopupOpen, isFullscreenPopupOpen]);
	useEffect(() => {
		setGlobalScrollHidden(isPopupDescriptionOpen || isFullscreenPopupOpen);
	}, [isPopupDescriptionOpen, isFullscreenPopupOpen]);

	const onDownload = () => {
		saveAs(activeFile.url, activeFile.name);
	};
	const wrapperClassNameStatus = (status?: VotingStatusEnum) => {
		if (status === VotingStatusEnum.DONE) return 'dayPartWrapper done';
		if (status === VotingStatusEnum.IN_PROGRESS) return 'dayPartWrapper inProgress';
		if (status === VotingStatusEnum.WAITING) return 'dayPartWrapper';
		return 'dayPartWrapper';
	};
	return (
		<Wrapper
			ref={ref}
			className="dayPart"
		>
			{(isPopupOpen && popupContent) && (
				<Popup
					content={popupContent}
					img={PopImg}
					secondButton={{
						secondButtonAction: onDownload,
						secondButtonText: 'pobierz',
					}}
					setPopupStatus={setPopupOpen}
					title=""
				/>
			)}
			{(isPopupDescriptionOpen && popupDescriptionContent) && (
				<Popup
					content={popupDescriptionContent}
					img={null}
					setPopupStatus={setPopupDescriptionOpen}
					title=""
				/>
			)}

			{isFullscreenPopupOpen && (
				<FilePopup
					closePopup={() => setFullscreenPopupOpen(false)}
					fileUrl={activeFile.url}
					isAgenda
					mimeType={activeFile.mimeType}
					title={activeFile.name}
				/>
			)}

			<OverInfoWrapper
				className={wrapperClassNameStatus(status)}
				DashedBorder={DashedBorder}
				status={status}
			>

				<Info
					onClick={() => (id !== openedAgendaPanel ? setOpenedAgendaPanel(id) : setOpenedAgendaPanel(''))}
					status={status}
				>
					<DetailsWrapper>
						{info.startAt && info.endAt && (
							<TimeWrapper>
								<TimeDot status={status} />
								<Time status={status}>
									{getTimeFromDateString(info.startAt)}
									{' '}
									-
									{getTimeFromDateString(info.endAt)}
								</Time>
							</TimeWrapper>
						)}
						<AgendaSubtitle status={status}>
							<VotingNumber status={status}>
								{index}
								.
							</VotingNumber>
							<p>
								{info.title}
							</p>
						</AgendaSubtitle>
					</DetailsWrapper>
					<div
						style={{
							display: 'flex',
							minWidth: 'fit-content',
							alignItems: 'center',
						}}
					>
						{!isExpanded ? (
							<BasicButton
								onClick={() => null}
								style={{
									fontSize: 12,
									height: 35,
									padding: '0 17px',
									color: '#0C0B52',
								}}
							>
								rozwiń
							</BasicButton>
						) : (
							<BasicButton
								onClick={() => null}
								style={{
									fontSize: 12,
									height: 35,
									padding: '0 17px',
									color: '#2BF5BA',
									backgroundColor: '#07064E',
								}}
							>
								zwiń
							</BasicButton>
						)}
					</div>
				</Info>

				{isExpanded && (
					<Expand>
						<FilesWrapper>
							{info.description && (
								<LookButton
									onClick={() => handlePopupOpen(info.description)}
									type="button"
								>
									<LookIcon
										alt="magnifier"
										src={MagnifierSvg}
									/>
									<span>zobacz opis</span>
								</LookButton>
							)}
							{getFiles()}
						</FilesWrapper>
						{info.votingModules.map((voting) => (
							<VotingItem
								key={voting.id}
								openPopup={handlePopupOpen}
								voting={voting}
							/>
						))}
					</Expand>
				)}
			</OverInfoWrapper>
		</Wrapper>
	);
});

export default DayPart;
