import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
import { RoomConfigState } from '../../../state/@types/roomConfig.interface';
import VideoChatNavigation from './VideoChatNavigation';

const StreamingVideoChatWrapper = loadable(() => import('./streaming/StreamingVideoChatWrapper'));

const StyledWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  height: 100%;
  justify-content: center;
	width: 100%;
  @media (max-width: 999px) {
		position: fixed;
		top: 0;
    padding-top: 0;
    justify-content: unset;
  }
`;

interface IVideoChat {
	streamingDataDefined: string;
	roomConfig: RoomConfigState;
	width: number;
	videoChatIndex: number;
	setVideoChatIndex: React.Dispatch<React.SetStateAction<number>>
}

const VideoChat: FunctionComponent<IVideoChat> = ({
	streamingDataDefined, roomConfig, width, videoChatIndex, setVideoChatIndex,
}) => (
	<StyledWrapper>
		{width > 1000 && roomConfig.video && streamingDataDefined && (
			<StreamingVideoChatWrapper />
		)}
		<VideoChatNavigation
			className={(roomConfig.chat && !roomConfig.video) ? 'onlyChat' : ''}
			setVideoChatIndex={setVideoChatIndex}
			videoChatIndex={videoChatIndex}
			width={width}
		/>
	</StyledWrapper>
);

export default VideoChat;
