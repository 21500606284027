import React, { FunctionComponent, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	height: calc(100% - ${({ theme }) => theme.sizes.subpageHeaderHeight});
	justify-content: center;
	pointer-events:none;
	position: relative;
	width: 100%;
	img {
		max-height: 80%;
		max-width: 80%;
	}
`;

const ImageViewer: FunctionComponent<{src: string}> = ({ src }) => {
	const wrapperRef = useRef(null);

	return (
		<Wrapper ref={wrapperRef}>
			<img
				alt="event"
				src={src}
			/>
		</Wrapper>
	);
};

export default ImageViewer;
