import { useSelector } from 'react-redux';
import { GlobalState } from '../state/@types/redux.interface';

export default function useGetNameByUid(uid: string | number): string {
	const userList = useSelector((state: GlobalState) => state.chat.users);
	try {
		const { firstName, lastName } = userList.find((user) => user.voterEventAccessId === uid);
		if (firstName && lastName) return `${firstName} ${lastName}`;
		if (firstName) return `${firstName}`;
		if (lastName) return `${lastName}`;
		return 'Anonim';
	} catch (e) {
		return 'Anonim';
	}
}
