import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { GlobalState } from '../../../state/@types/redux.interface';
import { fetchScoreboardAction } from '../../../state/scoreboard.reducer';
import SEO from '../../seo';
import SubpageHeader from '../../common/subpage-header/SubpageHeader';
import PageHeader from '../../common/page-header/PageHeader';
import PageIcon from '../../../images/menu-subpage/contactIcon.svg';
import ScoreboardQuestion from './components/ScoreboardQuestion';

const ScoreboardPopupWrapper = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: calc(100% - 55px);
  left: 0;
  overflow: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const Content = styled.div`
	margin-top: 50px;
	max-width: 1000px;
	width: 100%;
  @media(min-width: 1000px) {
    margin-top: 115px;
  }
`;

const StyledPageHeader = styled(PageHeader)`
	margin-left: 0;
	@media(min-width: 600px) {
    	margin-left: 40px;
  	}
`;

const StyledSubpageHeader = styled(SubpageHeader)`
	@media(max-width: 1000px) {
		top: 0;
	}
`;
const ScoreboardTop = styled.div`
	padding-left: 20px;
`;

const Scoreboard = ({ votingId, setScrBoardPopupOpen }) => {
	const scoreboardReducer = useSelector((state: GlobalState) => state.scoreboard);
	const dispatch = useDispatch();
	const { data } = scoreboardReducer;
	const { questions } = data;

	useEffect(() => {
		dispatch(fetchScoreboardAction(votingId));
	}, []);

	return (
		<ScoreboardPopupWrapper>
			<StyledSubpageHeader
				closePopup={() => setScrBoardPopupOpen(false)}
				title="Wyniki"
			/>
			<Content>
				<SEO title="Wyniki głosowania" />
				<ScoreboardTop>
					<StyledPageHeader
						img={PageIcon}
						text={`Ilość osób uczestniczących w głosowaniu: ${scoreboardReducer.data.numberOfVoters}`}
						title="Wyniki Głosowania"
					/>
				</ScoreboardTop>
				{questions.map((question, index) => (
					<ScoreboardQuestion
						key={question.id}
						index={index}
						question={question}
					/>
				))}
			</Content>
		</ScoreboardPopupWrapper>
	);
};

export default Scoreboard;
