import React, { FunctionComponent } from 'react';
import { theme } from '../../../../../contants/theme';
import { WaitingVotingButtonProps } from '../@types/waitingVotingButton.interface';
import ModifiedButton from './ModifiedButton';

const WaitingVotingButton: FunctionComponent<WaitingVotingButtonProps> = ({ handleWaitBtnClick, className }) => (
	<ModifiedButton
		className={className}
		color={theme.colors.blueBackground}
		onClick={handleWaitBtnClick}
		textColor={theme.colors.aqua}
	>
		oczekiwanie na uruchomienie
	</ModifiedButton>
);

export default WaitingVotingButton;
