import * as React from 'react';

/* tslint:disable */

const ProxyTypeIcon = ({
	size, type,
}) => (
	<svg
		height={size}
		style={{
			float: 'right',

		}}
		viewBox="0 0 328 328"
		width={size}
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			/* eslint-disable-next-line max-len */
			d="M15 286.75h125.596c19.246 24.348 49.031 40 82.404 40 57.897 0 105-47.103 105-105s-47.103-105-105-105c-34.488 0-65.145 16.716-84.298 42.47A115.407 115.407 0 00115 156.75c-63.411 0-115 51.589-115 115 0 8.284 6.716 15 15 15zm208-140c41.355 0 75 33.645 75 75s-33.645 75-75 75-75-33.645-75-75 33.645-75 75-75zM115 1.25c-34.602 0-62.751 28.15-62.751 62.751S80.398 126.75 115 126.75c34.601 0 62.75-28.148 62.75-62.749S149.601 1.25 115 1.25z"
			fill={(() => {
				if (type === 'present') {
					return (
						'#FF9C28'
					);
				}
				if (type === 'absent') {
					return (
						'#EBEBEB'
					);
				}
				return (
					'#C7C7D9'
				);
			})()}
		/>
		<path
			/* eslint-disable-next-line max-len */
			d="M193 236.75h15v15c0 8.284 6.716 15 15 15s15-6.716 15-15v-15h15c8.284 0 15-6.716 15-15s-6.716-15-15-15h-15v-15c0-8.284-6.716-15-15-15s-15 6.716-15 15v15h-15c-8.284 0-15 6.716-15 15s6.716 15 15 15z"
			fill={(() => {
				if (type === 'present') {
					return (
						'#FF9C28'
					);
				}
				if (type === 'absent') {
					return (
						'#EBEBEB'
					);
				}
				return (
					'#C7C7D9'
				);
			})()}
		/>
	</svg>
);

export default ProxyTypeIcon;
