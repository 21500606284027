import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { chooseAttorneyIndexAction, setEditModeStatusAction, setVotingIdAction } from '../../../../../../state/votingModule.reducer';
import { GlobalState } from '../../../../../../state/@types/redux.interface';
import ModifiedButton from '../ModifiedButton';
import { theme } from '../../../../../../contants/theme';
import Popup from '../../../../../common/popup/Popup';
import AbcLogo from '../../../../../../images/voting/abc-logo.svg';
import checkIfWindowExist from '../../../../../../_utils/windowUndefined';
import VoteAsConfirmButton from './VoteAsConfirmButton';
import ProxyMember from './ProxyMember';

const Wrapper = styled.div`
	width: 100%;
`;

const ProxyListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
`;

const List = styled.div`
	display: flex;
	flex-direction: column;
	margin: 5px 0 10px 0;
`;

const ActionButtonsWrapper = styled.div`
	display: flex;
`;

const ProxyMemberList = ({
	setIsExpanded, voting, didVoted,
}) => {
	const [activeProxyIndex, setActiveProxyIndex] = useState<number>(-1);
	const [isPopupBeforeVotingOpen, setIsPopupBeforeVotingOpen] = useState<boolean>(false);
	const { proxy: { members } } = useSelector((state: GlobalState) => state.votingModule);
	const dispatch = useDispatch();

	const onAttorneyChoose = (index: number) => {
		if (activeProxyIndex === -1) {
			setActiveProxyIndex(index);
		} else {
			setActiveProxyIndex(-1);
		}
	};

	const setActiveAttorney = () => {
		checkIfWindowExist(() => window.scrollTo(0, 0));
		dispatch(chooseAttorneyIndexAction(activeProxyIndex));
		dispatch(setVotingIdAction(voting.id));
		dispatch(setEditModeStatusAction(voting.answersEditable));
	};

	const setEditVotingForAttorney = (index: number) => {
		checkIfWindowExist(() => window.scrollTo(0, 0));
		onAttorneyChoose(index);
		dispatch(chooseAttorneyIndexAction(index));
		setEditModeStatusAction(true);
		dispatch(setVotingIdAction(voting.id));
	};

	const checkIfPrincipalAlreadyVoted = (principalId) => didVoted?.find((principalVoteInfo) => principalVoteInfo.id === principalId);
	const handleVoteAsIfChecked = () => {
		if (activeProxyIndex > -1) {
			setIsPopupBeforeVotingOpen(true);
		}
	};
	return (
		<Wrapper>
			{isPopupBeforeVotingOpen && (
				<Popup
					content={!voting.answersEditable ? 'Oznacza to oddanie przez Ciebie'
						+ 'przysługujących Ci głosów, które zostaną '
						+ 'zapisane w wynikach. \n'
						+ 'Nie będziesz miał możliwości powrotu '
						// eslint-disable-next-line max-len
						+ 'do głosowania' : 'Masz możliwość wielokrotnej edycji oddanych przez siebie głosów do czasu zakończenia głosowania.'}
					img={AbcLogo}
					secondButton={{
						secondButtonAction: setActiveAttorney,
						secondButtonText: 'głosuj',
					}}
					setPopupStatus={() => setIsPopupBeforeVotingOpen(false)}
					title="Przechodzisz do głosowania"
				/>
			)}
			<ProxyListWrapper>
				<p style={{ fontWeight: 'bold' }}>Głosuj za:</p>
				<List>
					{ members.map((member, index) => (
						<ProxyMember
							key={member.id}
							activeProxyIndex={activeProxyIndex}
							didVoted={checkIfPrincipalAlreadyVoted(member.id).didVoted}
							index={index}
							isActive={activeProxyIndex === index}
							isEditable={voting.answersEditable}
							member={member}
							onClick={() => onAttorneyChoose(index)}
							setEditVoting={() => setEditVotingForAttorney(index)}
						/>
					))}
				</List>
				<ActionButtonsWrapper>
					<VoteAsConfirmButton
						isActive
						isDisabled={activeProxyIndex < 0}
						onClick={handleVoteAsIfChecked}
						style={{
							alignSelf: 'flex-start',
						}}
						text="głosuj"
					/>
					<ModifiedButton
						color={theme.colors.fontBlue}
						onClick={() => setIsExpanded(false)}
						textColor={theme.colors.aqua}
					>
						anuluj
					</ModifiedButton>
				</ActionButtonsWrapper>
			</ProxyListWrapper>
		</Wrapper>
	);
};

export default ProxyMemberList;
