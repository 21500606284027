import React from 'react';
import styled from 'styled-components';
import { NavigationButtonsProps } from '../@types/navigationButtons.interface';

const StyledNavigationButtons = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	justify-content: center;
	@media(min-width: 1400px) {
		border-radius: 0 0 18px 18px;
	}
	button {
		background-color: ${({ theme, nextDisabled }) => (nextDisabled ? '#ebebeb' : theme.colors.aqua)};
		border: 2px solid ${({ theme, nextDisabled }) => (nextDisabled ? '#ebebeb' : theme.colors.aqua)};
		border-radius: 25px;
		color: ${({ theme, nextDisabled }) => (nextDisabled ? '#b1b1b1' : theme.colors.fontBlue)};
		cursor: pointer;
		font-size: 0.75rem;
		font-weight: 700;
		margin-left: 10px;
		outline: none;
		padding: 9px 24px;
		&.orange {
			background-color: ${({ theme, nextDisabled }) => (nextDisabled ? '#ebebeb' : theme.colors.orange)};
			border-color: ${({ theme, nextDisabled }) => (nextDisabled ? '#ebebeb' : theme.colors.orange)};
			font-size: 12px;
			font-weight: 700;
		}
		
		&.prev {
			background-color: ${({ theme }) => theme.colors.white};
			border: 2px solid ${({ theme }) => theme.colors.fontBlue};
		}
		&:first-child {
			margin-left: 0;
		}
		&:disabled {
			background-color: #ebebeb;
			border: 2px solid #ebebeb;
			color: #b1b1b1;
			cursor: auto;
			font-size: 12px;
			font-weight: 700;
		}
	}
`;

const NavigationButtons = ({
	nextText, onNext, nextOrange, nextDisabled, prevText, onPrev, handleOpenPopupFewPoints, isPointsOrShares,
}: NavigationButtonsProps) => {
	const onClick = () => {
		if (!nextDisabled) {
			return onNext();
		}
		if (isPointsOrShares) {
			return handleOpenPopupFewPoints();
		}
		onNext();
	};
	return (
		<StyledNavigationButtons nextDisabled={nextDisabled}>
			{!!prevText && !!onPrev && (
				<button
					className="prev"
					onClick={onPrev}
					type="button"
				>
					{prevText}
				</button>
			)}
			<button
				className={nextOrange ? 'orange' : ''}
				onClick={onClick}
				type="button"
			>
				{nextText}
			</button>
		</StyledNavigationButtons>
	);
};

export default NavigationButtons;
