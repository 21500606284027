import React, {
	FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import Send from '../../../../images/room/streaming/send.svg';
import { MessageDto } from '../../../../services/@types/chat.interface';
import { fetchChatHistory } from '../../../../services/services';
import { GlobalState } from '../../../../state/@types/redux.interface';
import { loadMessagesAction, resetUnreadAction, setMessageAction } from '../../../../state/chat.reducer';
import useGetNameByUid from '../../../../_utils/useGetNameByUid';
import { RoomConfigState } from '../../../../state/@types/roomConfig.interface';
import { theme } from '../../../../contants/theme';
import checkIfWindowExist from '../../../../_utils/windowUndefined';
import ReceivedMessage from './ReceivedMessage';

const Wrapper = styled.div`
	background-color: #F8F8F8;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
  	height: 100%;
  	justify-content: flex-end;
	max-height: calc(100vh - 190px);
	overflow: hidden;
	width: 100%;
	z-index: 100000;
	@media (max-width: 1000px) {
		height: calc(100% - 140px);
	}
`;

const InsideWrapper = styled.div`
	box-shadow: 0 -1px 0 #EEEEEE;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	scroll-behavior: smooth;
  	&::-webkit-scrollbar {
    	display: none;
  	}
  	@media(min-width: 1000px) {
	  padding-bottom: ${({ isVideo }) => (isVideo ? 0 : '50px')};
	}
`;

const Message = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${({ isMe }) => (isMe ? 'flex-end' : 'auto')};
	margin-top: 5px;
	width: 100%;
	@media(min-width: 1000px) {
		&.onlyChat {
			width: 60%;
		}
	}
	}
`;

const MessageInputWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.veryDarkGrey};
	bottom: ${({ isVideo }) => (isVideo ? 0 : '50px')};
	display: flex;
	height: 86px;
    position: fixed;
    width: ${({ isVideo }) => (isVideo ? '320px' : '100%')};
	@media (max-width: 1000px) {
		bottom: 58px;
		width: 100%;
		position: fixed;
	}
`;

const SendBtn = styled.img`
  	margin-left: 10px;
	margin-right: 20px;
`;

const MessageInput = styled.input`
	-webkit-appearance: none;
  	background-color: transparent;
  	border: none;
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 11px;
	font-weight: 500;
  	outline: none;
  	padding: 10px 10px 10px 20px;
	width: 100%;
	::placeholder {
		color: ${({ theme }) => theme.colors.darkestGrey};
	}
`;

const MessageInputContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.greyBackground};
  border-radius: 30px;
  color: ${({ theme }) => theme.colors.fontBlue};
  display: flex;
  font-size: 11px;
  font-weight: 500;
  height: 50px;
  margin: 5px;
  outline: none;
  position: relative;
  width: 100%;
`;

const MessageCounter = styled.span`
  color: ${({ theme }) => theme.colors.darkestGrey};
  font-size: 11px;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.anotherGrey};
  }
`;
interface ChatProps {
	streamingDataDefined: string;
	roomConfig: RoomConfigState;
	className: string;
	agenda: boolean;
	chat: boolean;
	video: boolean;
}

const ChatComponent: FunctionComponent<ChatProps> = () => {
	const voterEventAccessId = useSelector((state: GlobalState) => state.streaming.data.uid);
	const roomConfig = useSelector((state: GlobalState) => state.roomConfig);
	const { messages, message } = useSelector((state: GlobalState) => state.chat);
	const socket = useSelector((state: GlobalState) => state.chat.socket);
	const dispatch = useDispatch();
	const chatWrapper = useRef(null);
	const chatInput = useRef(null);
	const [scrollY, setScrollY] = useState(0);
	const [scrollDirection, setScrollDirection] = useState('down');

	const userName = useGetNameByUid(voterEventAccessId);

	const scrollDownMobile = () => {
		setScrollY(chatWrapper?.current?.scrollHeight);
	};

	const onChangeInput = (value) => {
		if (value.length <= 500) {
			dispatch(setMessageAction(value));
		}
	};

	const scrollToBottom = () => {
		if (chatWrapper.current) {
			chatWrapper.current.scrollTop = chatWrapper?.current?.scrollHeight ? chatWrapper?.current?.scrollHeight : 0;
		}
	};

	useEffect(() => {
		dispatch(resetUnreadAction());
		return () => {
			dispatch(resetUnreadAction());
		};
	}, []);

	useEffect(() => {
		setScrollY(chatWrapper?.current?.scrollHeight);
		scrollToBottom();
	}, []);

	useEffect(() => {
		if (!socket) {
			return;
		}

		socket.on('generalChatMessage', () => {
			if (scrollDirection === 'down') {
				scrollToBottom();
				if (typeof window !== 'undefined') {
					window.scrollTo(0, chatWrapper?.current?.scrollHeight);
				}
			}
		});
	}, [socket]);

	const sendMsg = async () => {
		if (message) {
			scrollToBottom();
			socket.emit('generalChatMessage', message);
			onChangeInput('');
		}
		chatInput.current.focus();
		setScrollY(chatWrapper.current.scrollHeight);
		checkIfWindowExist(() => window.scrollTo(0, chatWrapper.current.scrollHeight));
	};

	const handleMessageKeyPress = (e) => {
		if (e.key === 'Enter') {
			sendMsg();
		}
		setScrollY(chatWrapper.current.scrollHeight);
		checkIfWindowExist(() => window.scrollTo(0, chatWrapper.current.scrollHeight));
	};

	const loadOldMessages = () => {
		fetchChatHistory(messages[0].message.id, 20).then(({ data }) => {
			dispatch(loadMessagesAction(data));
		});
	};

	const handleScroll = (e) => {
		if (e.currentTarget.scrollTop < scrollY) {
			setScrollDirection('up');
		} else {
			setScrollDirection('down');
		}
		setScrollY(e.currentTarget.scrollTop);
		if (e.currentTarget.scrollTop === 0) {
			loadOldMessages();
		}
	};

	const showUsername = (firstName, lastName) => {
		if (firstName && lastName) return `${firstName} ${lastName}`;
		if (firstName) return `${firstName}`;
		if (lastName) return `${lastName}`;
		return 'Anonim';
	};
	return (
		<Wrapper>
			<GlobalStyle theme={theme} />
			<InsideWrapper
				ref={chatWrapper}
				isVideo={roomConfig?.video}
				onScroll={handleScroll}
			>
				{messages.map((msg: MessageDto) => {
					const isMe = msg.voterEventAccessId === voterEventAccessId;
					const date = new Date(msg.message.createdAt);
					const timeString = `${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
					const remoteUserName = showUsername(msg?.firstName || msg?.sender, msg?.lastName);
					return (
						<Message
							key={msg.message.id}
							className={(roomConfig.chat && !roomConfig.video) ? 'onlyChat' : ''}
						>
							{isMe ? (
								<ReceivedMessage
									isHost={false}
									isMe
									name={userName}
									text={msg.message.text}
									time={timeString}
								/>
							) : (
								<ReceivedMessage
									isHost={false}
									name={remoteUserName}
									text={msg.message.text}
									time={timeString}
								/>
							)}
						</Message>
					);
				})}
			</InsideWrapper>
			<MessageInputWrapper
				isVideo={roomConfig?.video}
				onKeyPress={handleMessageKeyPress}
			>
				<MessageInputContainer>
					<MessageInput
						ref={chatInput}
						blurOnSubmit={false}
						keyboardShouldPersistTaps
						onChange={(e) => onChangeInput(e.target.value)}
						onClick={scrollDownMobile}
						placeholder="Wyślij wiadomość"
						type="text"
						value={message}
					/>
					<MessageCounter>
						{message.length}
						/500
					</MessageCounter>
					<SendBtn
						onClick={sendMsg}
						src={Send}
					/>
				</MessageInputContainer>
			</MessageInputWrapper>
		</Wrapper>
	);
};

export default ChatComponent;
