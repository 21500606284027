import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FaqProps } from '../../@types/faq.interface';

const FaqQuestionButton = styled.button`
	background-color: ${({ theme }) => theme.colors.white};
	border: solid 2px ${({ theme }) => theme.colors.yesNoButtonBorder};
	border-radius: 20px;
	color: ${({ theme }) => theme.colors.fontBlue};
	cursor: pointer;
	font-size: 12px;
	font-weight: 700;
	min-height: 65px;
	outline: none;
	padding: 12px 20px 16px 20px;
	text-align: left;
	width: 100%;
	&.expanded {
		background-color: ${({ theme }) => theme.colors.dirtyAqua};
		border: solid 2px ${({ theme }) => theme.colors.aqua};
		border-radius: 20px 20px 0 0;
	}
`;
const FaqQuestionWrapper = styled.div`
	margin-bottom: 0.875rem;
	transition: height ease 1s;
	@media(min-width: 1000px) {
		width: 100%;
		margin: 0 auto 0.875rem;
		font-size: 14px;
	}
`;
const FaqAnswer = styled.div`
	&.expanded {
		background-color: ${({ theme }) => theme.colors.dirtyAqua};
		border: solid 2px ${({ theme }) => theme.colors.aqua};
		border-radius: 0 0 20px 20px;
		border-top: 0;
		font-size: 12px;
		font-weight: 500;
		min-height: 65px;
		padding: 20px;
  		width: 100%;
	}
`;

const FaqQuestion: FunctionComponent<FaqProps> = ({
	question, answer, isActive, onClick,
}) => (

	<FaqQuestionWrapper>
		<FaqQuestionButton
			className={isActive ? 'expanded' : ''}
			onClick={onClick}
		>
			{question}
		</FaqQuestionButton>
		{isActive && (
			<FaqAnswer className={isActive ? 'expanded' : ''}>{answer}</FaqAnswer>
		)}
	</FaqQuestionWrapper>
);
export default FaqQuestion;
