import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PopupProps } from '../@types/popup.interface';
import BasicButton from '../basic-button/BasicButton';

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.fontBlue};
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin: 0 auto;
  max-height: 80%;
  max-width: 420px;
  padding: 50px 60px;
  position: relative;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 90%;
  z-index: 1;
  @media (max-width: 500px) {
    padding: 40px 30px 50px 30px;
	margin: auto 20px;
  }
	&.description {
		max-height: 88%;
		top: 46%;
		@media(min-width: 1000px) {
			top: 50%;
			max-height: 550px;
			width: 660px;
			max-width: 660px;
		}
	}
`;

const Content = styled.div`
  font-size: 14px;
  margin-bottom: 37px;
  margin-top: 20px;
  overflow-y: scroll;
  text-align: left;
  white-space: pre-line;
  width: 100%;
  word-wrap: break-word;
  ::-webkit-scrollbar {
		width: 4px;
	}
  ::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.colors.aqua};
		border-radius: 10px;
  }
  @media (min-width: 700px) {
    padding: 0 20px;
  }
	&.description {
		max-height: 300px;
		overflow-y: auto;
		text-align: left;
		::-webkit-scrollbar {
			width: 4px;
		}
		::-webkit-scrollbar-thumb {
			background-color: ${({ theme }) => theme.colors.aqua};
			border-radius: 10px;
		}
	}
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-top: 50px;
`;

const Image = styled.img`
  height: 107px;
  min-width: 155px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const StyledBasicButton = styled(BasicButton)`
	font-size: 12px;
  	padding: 9px 30px;
`;

const Popup: FunctionComponent<PopupProps> = ({
	title, description, content, setPopupStatus, img, secondButton,
}) => (
	<Wrapper>
		<Container className={description ? 'description' : ''}>
			{img && (
				<Image
					alt="popup-icon"
					src={img}
				/>
			)}
			{
				title && <Title>{title}</Title>
			}
			<Content className={description ? 'description' : ''}>{content}</Content>
			<ButtonsWrapper secondButton={secondButton}>
				{secondButton ? (
					<>
						<StyledBasicButton
							onClick={secondButton.secondButtonAction}
						>
							{secondButton.secondButtonText}
						</StyledBasicButton>
						<StyledBasicButton
							onClick={() => setPopupStatus(false)}
							style={{
								color: '#2BF5BA',
								backgroundColor: '#07064E',
								marginLeft: 14,
							}}
						>
							anuluj
						</StyledBasicButton>
					</>
				) : <StyledBasicButton onClick={() => setPopupStatus(false)}>zamknij</StyledBasicButton>}
			</ButtonsWrapper>
		</Container>
	</Wrapper>
);

export default Popup;
