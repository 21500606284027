import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ArrowBackSvg from '../../../images/arrow-back.svg';
import { SubpageHeaderProps } from '../@types/subpageHeader.interface';

const Header = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.lightGreyBackground};
	color: ${({ theme }) => theme.colors.fontBlue};
	display: flex;
	font-size: 14px;
	font-weight: 700;
	height: 35px;
	justify-content: center;
	min-height: 35px;
	padding: 0 2.5rem;
	position: sticky;
	top: 0;
  	width: 102%;
  	z-index: 10000;
	@media(min-width: 1000px) {
		top: 52px;
		position: fixed;
	}
`;

const ArrowBack = styled.img`
	cursor: pointer;
	height: 100%;
	left: 1.875rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 1rem;
`;
const HeaderText = styled.span`
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubpageHeader: FunctionComponent<SubpageHeaderProps> = ({
	title, closePopup, className, disableBackAction = false,
}) => (
	<Header className={className}>
		{!disableBackAction && (
			<ArrowBack
				alt="back"
				onClick={closePopup}
				src={ArrowBackSvg}
			/>
		)}
		<HeaderText>
			{title}
		</HeaderText>
	</Header>
);

export default SubpageHeader;
