export enum VideoConferenceAlertsEnum {
	USER_JOINED= 'USER_JOINED',
	USER_LEFT = 'USER_LEFT',
	REMOTE_USER_JOINED= 'REMOTE_USER_JOINED',
	REMOTE_USER_LEFT = 'REMOTE_USER_LEFT',
	CAMERA_ON = 'CAMERA_ON',
	CAMERA_OFF = 'CAMERA_OFF',
	REMOTE_CAMERA_ON = 'REMOTE_CAMERA_ON',
	REMOTE_CAMERA_OFF = 'REMOTE_CAMERA_OFF',
	MIC_ON = 'MIC_ON',
	MIC_OFF = 'MIC_OFF',
	MIC_ON_AUDIENCE_FAIL = 'MIC_ON_AUDIENCE_FAIL',
	CAMERA_ON_AUDIENCE_FAIL = 'CAMERA_ON_AUDIENCE_FAIL',
	USER_MUTED = 'USER_MUTED',
	USER_UNMUTED = 'USER_UNMUTED',
	USER_RAISE_HAND = 'USER_RAISE_HAND',
	USER_LOWER_HAND = 'USER_LOWER_HAND',
}
