import React, { useEffect, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';

const Wrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.lightGreyBackground};
	height: calc(100% - ${({ theme }) => theme.sizes.subpageHeaderHeight});
	overflow-y: scroll;
	padding: 20px;
	width: 100%;
	@media(max-width: 700px) {
		margin-top: 60px;
	}

	.pdf {
		overflow-y: scroll;

		.page {
			&:not(:last-of-type) {
				margin-bottom: 20px;
			}
		}
		canvas {
			height: 100%;
			width: 100%;
		}
	}
`;

const PdfViewer = ({ src }: { src: string }) => {
	const [pageNumber, setPageNumber] = useState<number>();
	const [width, setWidth] = useState<number>();
	const wrapperRef = useRef(null);

	useEffect(() => {
		setWidth(wrapperRef.current.offsetWidth);
	}, [wrapperRef]);

	const getPage = (page: number) => (
		<Page
			className="page"
			pageNumber={page}
			width={width - 40}
		/>
	);

	const handlePdfLoad = ({ numPages }) => {
		setPageNumber(numPages);
	};

	return (
		<Wrapper ref={wrapperRef}>
			<Document
				className="pdf"
				file={src}
				onLoadSuccess={handlePdfLoad}
			>
				{width && [...Array(pageNumber)].map((e, i) => getPage(i + 1))}
			</Document>
		</Wrapper>
	);
};

export default PdfViewer;
